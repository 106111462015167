import React from 'react';
import './brandGrade.css';
import { useState } from 'react';
import EngineWarrantyIcon from '../../assets/gradeIcons/Engine Warranty.png';
import TransmissionIcon from '../../assets/gradeIcons/Transmission Warranty.png';
import ElectricalWiringIcon from '../../assets/gradeIcons/Electrical Wiring.png';
import BatteryIcon from '../../assets/gradeIcons/Battery Warranty.png';
import TurboWarrantyIcon from '../../assets/gradeIcons/Turbo Warranty.png';
import AcCompressorIcon from '../../assets/gradeIcons/AC Compressor.png';
import InsuranceClaimSuportIcon from '../../assets/gradeIcons/Insurance Claim support.png';
import RoadSideAssistanceIcon from '../../assets/gradeIcons/RoadSideAssistanceIcon.png';
import DedicatedSupportIcon from '../../assets/gradeIcons/24_7 Customer Care.png';

import MechanicalWarranty from '../../assets/gradeIcons/Mechanical.png';
import Warranty from '../../assets/gradeIcons/warranty.png';

function BrandGrade() {
  return (
    <section className="brand">
      <div id="garde-warranty" className="container">
        <div className="commonHeading">
          <h2>Showroom-Grade Warranty for your Car</h2>
          <p className="grade-description">
            Wisedrive partners with Authorised service centres of all car brands
            sold in India and provides extensive coverage of warranty across the
            country with it 98% claim approval rate within 24 hours of claim
            request. We provide roadside assistance and pan India service and
            warranty support to our warranty customers.
          </p>
        </div>

        {/* <div className="brandCardsNew brandCards">
          {gradeCardsData?.map((item) => {
            return (
              <div className="card">
                <img src={item?.src} alt="rupee" width="40px" height="40px" />
                <h6>{item?.header}</h6>
                <p>{item?.content}</p>
              </div>
            );
          })}
        </div> */}
        <div className="brandCardsNew brandCards">
          <div className="brand-cards-left">
            <div
              className="card1"
              style={{ margin: '10px', backgroundColor: '#eff7f0' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ color: '#25632D' }}>Electrical Parts</h6>
                <img src={BatteryIcon} alt="rupee" width="40px" height="40px" />
              </div>
              <p
                style={{
                  color: '#25632D',
                  fontSize: '18px',
                }}
              >
                Electrical Wiring Warranty, Battery Warranty, Infotainment
                Warranty, Lights and bulbs warranty.
              </p>
            </div>
            <div
              className="card1"
              style={{ margin: '10px', backgroundColor: '#ecf7f9' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ color: '#003cc4' }}>Mechanical parts</h6>
                <img
                  src={MechanicalWarranty}
                  alt="rupee"
                  width="40px"
                  height="40px"
                />
              </div>
              <p
                style={{
                  color: '#003cc4',
                  fontSize: '18px',
                }}
              >
                Suspension., Shock absorbers, lower arms.
              </p>
            </div>
          </div>
          <div
            className="card1 brand-cards-center"
            style={{ backgroundColor: '#0c286a' }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h6 style={{ color: 'white' }}>
                Engine & <br />
                Transmission Parts
              </h6>
              <img src={Warranty} alt="rupee" width="70px" height="70px" />
            </div>
            <p
              style={{
                color: 'white',
                fontSize: '18px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              Al parts of Engine and Transmission are covered under Wisedrive
              Comprehensive Warranty program. Automatic transmission is also
              covered under this program.
            </p>
            <p
              style={{
                color: 'white',
                fontSize: '18px',
                margintop: '20px',
              }}
            >
              We back our Inspection with FREE Warranty for your car.
            </p>
          </div>
          <div className="brand-cards-left">
            <div
              className="card1"
              style={{ margin: '10px', backgroundColor: '#fff7ee' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ color: '#97653c' }}> Roadside Assistance</h6>
                <img
                  src={RoadSideAssistanceIcon}
                  alt="rupee"
                  width="40px"
                  height="40px"
                />
              </div>
              <p
                style={{
                  color: '#97653c',
                  fontSize: '18px',
                }}
              >
                RSA is included in Comprehensive Warranty program
              </p>
            </div>
            <div
              className="card1"
              style={{ margin: '10px', backgroundColor: '#eff3fb' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h6 style={{ color: '#576892' }}>AC Warranty</h6>
                <img
                  src={AcCompressorIcon}
                  alt="rupee"
                  width="40px"
                  height="40px"
                />
              </div>
              <p
                style={{
                  color: '#576892',
                  fontSize: '18px',
                }}
              >
                AC Compressor, AC Cooler, Wiring, AC conductor Wiring.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandGrade;
