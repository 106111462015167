import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";

const DentDetailsModal = ({ isOpen, onClose, onSave, dentsData }) => {
  const [dents, setDents] = useState({
    Front: { Dents: "2", Scratches: "2" },
    Rear: { Dents: "", Scratches: "" },
    Left: { Dents: "", Scratches: "" },
    Right: { Dents: "", Scratches: "" },
  });

  useEffect(() => {
    setDents(dentsData);
  }, [dentsData]);

  const handleDentChange = (panel, type, value) => {
    setDents((prev) => ({
      ...prev,
      [panel]: { ...prev[panel], [type]: value },
    }));
  };

  const handleSave = () => {
    const hasErrors = Object.entries(dents).some(([panel, details]) =>
      Object.entries(details).some(
        ([type, value]) => value === "" || value < 0 || value > 10
      )
    );

    if (hasErrors) {
      alert("Please ensure all values are between 0 and 10.");
      return;
    }
    onSave(dents); // Call the onSave callback with updated dents data
    onClose(); // Close the modal
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: { borderRadius: "12px" },
      }}
    >
      <DialogTitle sx={{ color: "#003cc5" }}>Dent Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          {/* Add headers only once at the top */}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Dents
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1" gutterBottom>
                  Scratches
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Map through dents details for each panel */}
          {Object.entries(dents).map(([panel, details]) => (
            <React.Fragment key={panel}>
              <Grid item xs={6}>
                <TextField
                  label={`${panel} - Dents`}
                  value={details.Dents}
                  type="number"
                  inputProps={{ min: 0, max: 10 }}
                  placeholder="Example (2 Dents)"
                  onChange={(e) =>
                    handleDentChange(panel, "Dents", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={`${panel} - Scratches`}
                  value={details.Scratches}
                  type="number"
                  inputProps={{ min: 0, max: 10 }}
                  onChange={(e) =>
                    handleDentChange(panel, "Scratches", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        <Box display="flex" justifyContent="flex-end" gap="8px" mt={4}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ backgroundColor: "#003cc5" }}
          >
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DentDetailsModal;
