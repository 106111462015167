import axios from 'axios';
import { BASE_URL } from '../httpHelpers/urlContainer';
import { basicAuth } from './crmPortal';

// export const getCityListApi = () => axios.get(`${process.env.REACT_APP_OLD_BASE_URL}Home/getCityList`);
// export const contactUsApi = (data) => axios.post(`${process.env.REACT_APP_OLD_BASE_URL}Home/addContactUsDetails`, data);

// export const generateOrderApi = (data) => axios.post(`${process.env.REACT_APP_BASE_URL}cashfree/generateOrder`, data);
// export const InspectionPlanApi = () => axios.get(`${process.env.REACT_APP_BASE_URL}InspectionPlan/getpackList`);

// export const getInspectionPlanOtpApi = (number, name, sourceId, ip) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionPlan/send/otptolead?mobileNo=${number}&name=${name}&sourceId=${sourceId}&ip=${ip}`,
//   );
// export const verifyOtpInspectionPlanApi = (number, otp) =>
//   axios.get(`${process.env.REACT_APP_BASE_URL}InspectionPlan/verify/leadotp?mobileNo=${number}&otp=${otp}`);
// export const getPackageDetailsApi = () =>
//   axios.get(`${process.env.REACT_APP_BASE_URL}InspectionPlan/getPackageDetails`);

// export const completePaymentApi = (data) =>
//   axios.post(`${process.env.REACT_APP_BASE_URL}InspectionPlan/completePayment`, data);

// export const completePaymentApiFree = (data) => {
//   return axios.post(`${process.env.REACT_APP_BASE_URL}InspectionPlan/completePaymentFree`, data);
// };

// export const subPackageListApi = (packId) =>
//   axios.get(`${process.env.REACT_APP_BASE_URL}InspectionPlan/getPackageDescription?mainPackId=${packId}`);

// /*---- warranty package plan started---*/

// export const WarrantyPlanApi = () => axios.get(`${process.env.REACT_APP_BASE_URL}WarrantyPlan/getpackList`);
// export const getVehicleDetailsApi = (data) =>
//   axios.post(`${process.env.REACT_APP_BASE_URL}WarrantyPlan/getDetails`, data);

// export const getWarrantyPlanOtpApi = (Phone, name, wvid, vehicleId, leadVehicleId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}WarrantyPlan/send/otptolead?mobileNo=${Phone}&name=${name}&wvid=${wvid}&vehicleId=${vehicleId}&leadVehicleId=${leadVehicleId}`,
//   );

// export const verifyOtpWarrantyPlanApi = (phone, otp, vehicleId, leadVehicleId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}WarrantyPlan/verify/leadotp?mobileNo=${phone}&otp=${otp}&wvid&vehicleId=${vehicleId}&leadVehicleId=${leadVehicleId}`,
//   );

// export const WarrantyPlanPackageApi = (leadId, customerId, typeId, categoryId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}WarrantyPlan/getpackListNew?leadId=${leadId}&customerId=${customerId === "null" || customerId === null ? "" : customerId
//     }&typeId=${typeId}&categoryId=${categoryId}`,
//   );

// export const packageBasedonPlanApi = (leadId, customerId, typeId, categoryId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}WarrantyPlan/getPackageBasedOnPlan?leadId=${leadId}&customerId=${customerId === "null" || customerId === null ? "" : customerId
//     }&typeId=${typeId}&categoryId=${categoryId}`,
//   );
// export const completeWarrantyPackagePaymentApi = (data) =>
//   axios.post(`${process.env.REACT_APP_BASE_URL}WarrantyPlan/sell/Package`, data);

// export const verifyCoupanApi = (leadId, customerId, packId, categoryId, mainPackId, amount, coupanCode) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}WarrantyPlan/getCouponDetails?leadId=${leadId}&customerId=${customerId}&packId=${packId}&categoryId=${categoryId}&mainPackId=${mainPackId}&amount=${amount}&couponCode=${coupanCode}`,
//   );

// export const paymentConfirmationApi = (dppId) =>
//   axios.get(`${process.env.REACT_APP_BASE_URL}WarrantyPlan/paymentConfirmation?dppId=${dppId}`);
// /*----warranty package plan started----*/

// export const GetInspectionReportOtpApi = (mobileNo, vehicleNo, ip) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}/InspectionReport/send/otptolead?mobileNo=${mobileNo}&vehicleNo=${vehicleNo}&ip=${ip}`,
//   );
// export const VerifyInspectionReportOtpApi = (mobileNo, otp) =>
//   axios.get(`${process.env.REACT_APP_BASE_URL}/InspectionReport/verify/leadotp?mobileNo=${mobileNo}&otp=${otp}`);
// export const VehicleDetailsApi = (vehicleId, leadVehicleId, inspReqId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/GetVehicleDetails?vehicleId=${vehicleId}&leadVehId=${leadVehicleId}&inspReqId=${inspReqId}`,
//   );
// export const VersionHistoryApi = (vehicleId, leadVehicleId, inspReqId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}/InspectionReport/GetversionList?vehicleId=${vehicleId}&leadVehId=${leadVehicleId}&inspReqId=${inspReqId}`,
//   );

// export const ReportDataApi = (vehicleId, leadVehicleId, inspReqId, reportTypeId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/GetImageDetails?vehicleId=${vehicleId}&leadVehId=${leadVehicleId}&inspReqId=${inspReqId}&date&reportTypeId=${reportTypeId}`,
//   );

// export const QuestionApi = (vehicleId, leadVehId, inspReqId, reportTypeId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/GetquesAnsDetails?vehicleId=${vehicleId}&leadVehId=${leadVehId}&inspReqId=${inspReqId}&date&reportTypeId=${reportTypeId}`,
//   );
// export const getObdReportApi = (vehicleId, leadVehId, inspReqId, reportTypeId, headers) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/GetObdDetails?vehicleId=${vehicleId}&leadVehId=${leadVehId}&inspReqId=${inspReqId}&date&reportTypeId=${reportTypeId}`,
//   );
// export const ReportListApi = () => axios.get(`${process.env.REACT_APP_BASE_URL}/InspectionReport/GetReportList`);
// export const FinedetilsApi = (vehicleId, leadvehicleId, insReqVehId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/getFineDetails?vehicleId=${vehicleId}&leadvehicleId=${leadvehicleId}&insReqVehId=${insReqVehId}`,
//   );
// export const observationListApi = (vehicleId, leadVehId, inspVehId) =>
//   axios.get(
//     `${process.env.REACT_APP_OLD_BASE_URL}Inspection/getObservationList?vehicleId=${vehicleId}&leadVehId=${leadVehId}&inspVehId=${inspVehId}`,
//   );
// export const RecommendationListApi = (vehicleId, leadVehId, inspVehId) =>
//   axios.get(
//     `${process.env.REACT_APP_OLD_BASE_URL}Inspection/getPreventionList?vehicleId=${vehicleId}&leadVehId=${leadVehId}&inspVehId=${inspVehId}`,
//   );

// export const GetPageList = () => {
//   return axios.get(`${process.env.REACT_APP_BASE_URL}InspectionPlan/getpageList`);
// };

// export const SendTrackingCount = (data) => {
//   return axios.post(`${process.env.REACT_APP_BASE_URL}InspectionPlan/addCounter`, data);
// };

// export const FinanceDetailsApi = (vehicleId, leadVehId, inspReqId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/GetFinanceInsuranceDetails?vehicleId=${vehicleId}&leadVehId=${leadVehId}&inspReqId=${inspReqId}`,
//   );

// export const RtoDetailsApi = (vehicleId, leadId, inspReqId) =>
//   axios.get(
//     `${process.env.REACT_APP_BASE_URL}InspectionReport/getRTOPollution?vehicleId=${vehicleId}&leadVehId=${leadId}&inspVehId=${inspReqId}&id=7`,
//   );

// export const vehicleListApi = () => axios.get(`${process.env.REACT_APP_BASE_URL}getMakeModel?search=`)
// export const AddServiceLeadApi = (data) => axios.post(`${process.env.REACT_APP_BASE_URL}addServiceLead`, data)

export const GetAllReviewsInfo = () => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}WarrantyPlan/getReviewList`
  );
};

// export const CarDetailsApi = `${base_url_CRM}/utility/scheduleinspection`;

export const getCarDetailsByCarNo = async (carNumber, phonNum) => {
  try {
    const formData = {
      vehicleNumber: carNumber,
      blacklistCheck: true,
      phoneNumber: phonNum,
    };
    const response = await axios.post(
      `${process.env.REACT_APP_CRM_URL}/utility/scheduleinspection`,
      formData,
      basicAuth
    );

    return response;
  } catch (error) {
    console.log('error', error);
  }
};
const CreatePendingInspectionApi = `${process.env.REACT_APP_CRM_URL}/carinspecitonschedule/createnewpendingpayment/`;

export const createNewPendingInspection = async (inspectionData) => {
  try {
    let formData = {
      address: inspectionData?.address,
      city: inspectionData?.city,
      date: inspectionData?.date,
      time: inspectionData?.time,
    };
    // /{customerno}/{make}/{model}
    const inspectionApi =
      CreatePendingInspectionApi +
      `${inspectionData?.customermobileno}/${inspectionData?.make}/${inspectionData?.model}`;
    const response = await axios.post(inspectionApi, formData, basicAuth);
    return response;
  } catch (error) {
    console.log('error', error);
  }
};
