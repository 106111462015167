import React from 'react';
import ReactDOM from 'react-dom/client';
import Context from './context';
import PortalContextProvider from './context/carPortalContext';
import AppRoutes from './routes';
import './styles/common.css';

import { HelmetProvider } from 'react-helmet-async';

// import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Context>
    <PortalContextProvider>
      <HelmetProvider>
        <AppRoutes />
      </HelmetProvider>
    </PortalContextProvider>
  </Context>
);
