// import * as React from 'react';
// import PropTypes from 'prop-types';
// import { styled } from '@mui/material/styles';
// import Stack from '@mui/material/Stack';
// import Stepper from '@mui/material/Stepper';
// import Step from '@mui/material/Step';
// import StepLabel from '@mui/material/StepLabel';
// import Check from '@mui/icons-material/Check';
// import SettingsIcon from '@mui/icons-material/Settings';
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import VideoLabelIcon from '@mui/icons-material/VideoLabel';
// import StepConnector, {
//   stepConnectorClasses,
// } from '@mui/material/StepConnector';

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: '#784af4',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor: '#eaeaf0',
//     borderTopWidth: 3,
//     borderRadius: 1,
//     ...theme.applyStyles('dark', {
//       borderColor: theme.palette.grey[800],
//     }),
//   },
// }));

// const QontoStepIconRoot = styled('div')(({ theme }) => ({
//   color: '#eaeaf0',
//   display: 'flex',
//   height: 22,
//   alignItems: 'center',
//   '& .QontoStepIcon-completedIcon': {
//     color: '#784af4',
//     zIndex: 1,
//     fontSize: 18,
//   },
//   '& .QontoStepIcon-circle': {
//     width: 8,
//     height: 8,
//     borderRadius: '50%',
//     backgroundColor: 'currentColor',
//   },
//   ...theme.applyStyles('dark', {
//     color: theme.palette.grey[700],
//   }),
//   variants: [
//     {
//       props: ({ ownerState }) => ownerState.active,
//       style: {
//         color: '#784af4',
//       },
//     },
//   ],
// }));

// function QontoStepIcon(props) {
//   const { active, completed, className } = props;

//   return (
//     <QontoStepIconRoot ownerState={{ active }} className={className}>
//       {completed ? (
//         <Check className="QontoStepIcon-completedIcon" />
//       ) : (
//         <div className="QontoStepIcon-circle" />
//       )}
//     </QontoStepIconRoot>
//   );
// }

// QontoStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
// };

// const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 22,
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       backgroundImage:
//         'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     border: 0,
//     backgroundColor: '#eaeaf0',
//     borderRadius: 1,
//     ...theme.applyStyles('dark', {
//       backgroundColor: theme.palette.grey[800],
//     }),
//   },
// }));

// const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
//   backgroundColor: '#ccc',
//   zIndex: 1,
//   color: '#fff',
//   width: 50,
//   height: 50,
//   display: 'flex',
//   borderRadius: '50%',
//   justifyContent: 'center',
//   alignItems: 'center',
//   ...theme.applyStyles('dark', {
//     backgroundColor: theme.palette.grey[700],
//   }),
//   variants: [
//     {
//       props: ({ ownerState }) => ownerState.active,
//       style: {
//         backgroundImage:
//           'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//         boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
//       },
//     },
//     {
//       props: ({ ownerState }) => ownerState.completed,
//       style: {
//         backgroundImage:
//           'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
//       },
//     },
//   ],
// }));

// function ColorlibStepIcon(props) {
//   const { active, completed, className } = props;

//   const icons = {
//     1: <SettingsIcon />,
//     2: <GroupAddIcon />,
//     3: <VideoLabelIcon />,
//   };

//   return (
//     <ColorlibStepIconRoot
//       ownerState={{ completed, active }}
//       className={className}
//     >
//       {icons[String(props.icon)]}
//     </ColorlibStepIconRoot>
//   );
// }

// ColorlibStepIcon.propTypes = {
//   /**
//    * Whether this step is active.
//    * @default false
//    */
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   /**
//    * Mark the step as completed. Is passed to child components.
//    * @default false
//    */
//   completed: PropTypes.bool,
//   /**
//    * The label displayed in the step icon.
//    */
//   icon: PropTypes.node,
// };

// const steps = [
//   'Select campaign settings',
//   'Create an ad group',
//   'Create an ad',
// ];

// export default function CustomizedSteppers() {
//   return (
//     <Stack sx={{ width: '100%' }} spacing={4}>
//       <Stepper
//         alternativeLabel
//         activeStep={1}
//         connector={<ColorlibConnector />}
//       >
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Stack>
//   );
// }

import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import '../../styles/muiStyles.css';

const steps = [
  'Register With Mobile no.',
  'Enter Car Details',
  'Select City For Inspection',
  'Verify Payment Details',
];

const CustomizedSteppers = ({ activeStep }) => {
  return (
    <Box sx={{ width: '75%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          '& .MuiStepIcon-root': {
            color: '#ddd', // Default color
            width: '1.5em',
            height: '1.5em',
          },
          '& .MuiStepIcon-root.Mui-active': {
            color: '#3498db', // Active step color
          },
          '& .MuiStepIcon-root.Mui-completed': {
            color: '#2ecc71', // Completed step color
          },
          '& .MuiStepConnector-root': {
            top: '16px',
            color: 'red',
          },
          '& .MuiStepConnector-line': {
            borderColor: 'blue',
          },
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{''}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="stepper-labels">
        <h6>{steps[activeStep]}</h6>
      </div>
    </Box>
  );
};
export default CustomizedSteppers;
