import React from 'react';
import './compare.css';
import DoneIcon from '@mui/icons-material/Done';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function Compare() {
  return (
    <section className="compare-Wrap">
      <div className="compare-Wrap container">
        <div>
          <h2 className="compare-wrap-header">
            Wisedrive Outperforms Normal Used Car Inspections
          </h2>
          <p className="compare-wrap-desc">
            Experience unparalleled peace of mind with WiseDrive’s comprehensive
            inspections, detailed reports, and unmatched customer assurance for
            used car buyers.
          </p>
        </div>
        <div className="compare-wrap-table">
          <div className="compare-left-section">
            <h6> Wisedrive Way</h6>

            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Inspects engine, transmission, electrical, and mechanical
                aspects thoroughly,{' '}
                <span className="highlet">
                  ensuring all critical part is in top condition
                </span>
              </p>
            </span>

            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Provides detailed inspection reports with{' '}
                <span className="highlet">
                  high-quality photos and diagnostics,
                </span>
                giving clear insights into the car’s condition
              </p>
            </span>
            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Utilizes{' '}
                <span className="highlet">OBD-2, ECM, and BCM checks </span>for
                system health, ensuring al electronic systems are functioning
                correctly.
              </p>
            </span>
            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Delivers <span className="highlet">personalized advice</span>{' '}
                and <span className="highlet">accurate repair estimates</span>{' '}
                based on the inspection results,{' '}
                <span className="highlet">
                  helping you understand potential costs
                </span>
              </p>
            </span>
            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Offers a <span className="highlet">6-month warranty</span> and{' '}
                <span className="highlet">buyback guarantee</span>, giving you
                peace of mind and financial security.
              </p>
            </span>
            <span className="compare-span-css">
              <DoneIcon className="doneIconCompare" />
              <p>
                Verifies{' '}
                <span className="highlet">
                  finance validity, insurance status, traffic challans
                </span>
                and <span className="highlet">vehicle crime records</span>,
                ensuring no hidden financial or legal issues.
              </p>
            </span>
          </div>
          <div className="compare-right-section">
            <h6> Normal Inspections</h6>

            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Offers a generaly comprehensive inspection, though{' '}
                <span className="highlet">it lacks depth in some areas.</span>
              </p>
            </span>

            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Provides reports with diagnostics but{' '}
                <span className="highlet">
                  might not consistently include visual evidence.
                </span>
              </p>
            </span>
            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Uses diagnostic tools, though the{' '}
                <span className="highlet">
                  checks might not be as thorough and consistent.
                </span>
              </p>
            </span>
            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Gives advice and estimates, which might{' '}
                <span className="highlet">
                  {' '}
                  not always be tailored to customer needs
                </span>{' '}
                but inclines towards financial benefit for garages after
                purchase.
              </p>
            </span>
            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Provides warranty and satisfaction guarantees, typicaly{' '}
                <span className="highlet">
                  {' '}
                  shorter and less comprehensive.
                </span>
              </p>
            </span>
            <span className="compare-span-css">
              <RemoveCircleOutlineIcon className="removeIconCompare" />
              <p>
                Verifies finance and insurance, and offers some additional
                checks and services, though{' '}
                <span className="highlet">these may not be as detailed</span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Compare;
