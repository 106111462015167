// import React, { useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { Box, Typography, TextField, Button, Grid, Link } from "@mui/material";
// import WiseDriveLogoNew from "../../assets/images/header/wiseDrivelogoNew.png";

// const Header = () => {
//     return (
//       <div className="header">
//         <div className="header__container">
//           <div className="header__logo">
//             <img
//               src={WiseDriveLogoNew}
//               alt="Orange Health Labs"
//               className="header__logo-img"
//             />
//           </div>
//           {/* <div className="header__number">
//             <p>KA04JQ5657</p>
//           </div> */}
//         </div>
//       </div>
//     );
//   };

// const OTPVerification = () => {
//     const location = useLocation();
//     console.log(location, 'param');
//   const [otp, setOtp] = useState(Array(6).fill(""));
//   const [error, setError] = useState("");
//   const navigate = useNavigate();

//   const handleChange = (value, index) => {
//     const updatedOtp = [...otp];
//     updatedOtp[index] = value;
//     setOtp(updatedOtp);

//     // Move to the next input field automatically
//     if (value && index < otp.length - 1) {
//       document.getElementById(`otp-input-${index + 1}`).focus();
//     }
//   };

//   const handleSubmit = async () => {
//     const otpCode = otp.join("");
//     console.log("Entered OTP:", otpCode);
// if (otpCode.length !== 6) {
//       setError("Please enter the complete 6-digit OTP.");
//       return;
//     }
//     // Add OTP verification logic here
//     try {
//         const token = "your-token"; // Replace with your actual token logic
//         const response = await fetch(
//           `http://216.48.190.203:8080/apiv2/report/customer/${token}?action=VALIDATE_OTP&otp=${otpCode}`,
//           {
//             method: "GET",
//             headers: {
//               Accept: "*/*",
//             },
//           }
//         );

//         if (response.ok) {
//           console.log("OTP validated successfully.");
//           navigate("/sample-new-report"); // Redirect to the new page
//         } else {
//           setError("Invalid OTP. Please try again.");
//         }
//       } catch (error) {
//         console.error("Error validating OTP:", error);
//         setError("An error occurred while validating OTP. Please try again.");
//       }
//   };

//   return (
//     <>
//     <Header/>
//     <Box
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       justifyContent="center"
//       minHeight="100vh"
//       padding="24px"
//     >
//       <Box
//         width="100%"
//         maxWidth="400px"
//         // textAlign="center"
//         // border="1px solid #ddd"
//         borderRadius="16px"
//         padding="24px"
//         // boxShadow="0px 4px 10px rgba(0,0,0,0.1)"
//       >
//         <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
//           Enter the 6-digit code we texted it to your mobile number
//         </Typography>
//         <Typography variant="body2" sx={{ color: "#757575" }}>
//           If you forgot the registered mobile number, please call us on{" "}
//           <Link href="tel:+917353343536" sx={{ color: "#003cc5" }}>
//             +91-7353343536
//           </Link>
//         </Typography>
//         <Box marginTop="24px" marginBottom="16px">
//           <Grid container spacing={1} justifyContent="center">
//             {otp.map((digit, index) => (
//               <Grid item key={index}>
//                 <TextField
//                   id={`otp-input-${index}`}
//                   inputProps={{
//                     maxLength: 1,
//                     style: {
//                       textAlign: "center",
//                       fontSize: "24px",
//                       width: "20px",
//                       height: "20px",
//                     },
//                   }}
//                   value={digit}
//                   onChange={(e) => handleChange(e.target.value, index)}
//                   sx={{
//                     "& .MuiOutlinedInput-root": {
//                       borderRadius: "12px", // Ensures the input itself has rounded corners
//                     },
//                   }}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//         {error && (
//             <Typography
//               variant="body2"
//               sx={{ color: "red", marginBottom: "16px", textAlign: "center" }}
//             >
//               {error}
//             </Typography>
//           )}
//         <Button
//           variant="contained"
//           fullWidth
//           sx={{
//             backgroundColor: "#003cc5",
//             color: "#fff",
//             fontWeight: "bold",
//             textTransform: "none",
//             fontSize: "16px",
//             "&:hover": {
//                 backgroundColor: "#003cc5",
//             }
//           }}
//           onClick={handleSubmit}
//         >
//           VERIFY
//         </Button>
//       </Box>
//     </Box>
//     </>
//   );
// };

// export default OTPVerification;

import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Button, Grid, Link } from "@mui/material";
import WiseDriveLogoNew from "../../assets/images/header/wiseDrivelogoNew.png";
import { PortalContext } from "../../context/carPortalContext";

const Header = () => (
  <div className="header">
    <div className="header__container" style={{ justifyContent: "center" }}>
      <div className="header__logo">
        <img
          src={WiseDriveLogoNew}
          alt="Orange Health Labs"
          className="header__logo-img"
        />
      </div>
    </div>
  </div>
);

const OTPVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setReportViewData } = useContext(PortalContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [error, setError] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    // Extract the token from the query string
    const queryParams = new URLSearchParams(location.search);
    const extractedToken = queryParams.get("token");

    if (extractedToken) {
      setToken(extractedToken);

      // Generate OTP on page load
      generateOTP(extractedToken);
    } else {
      setError("Token is missing.");
    }
  }, [location]);

  const generateOTP = async (extractedToken) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CRM_PAY_URL}/report/customer/${extractedToken}?action=GENERATE_OTP`,
        {
          method: "GET",
          headers: {
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate OTP.");
      }
    } catch (error) {
      console.error("Error generating OTP:", error);
      setError("Failed to generate OTP. Please try again.");
    }
  };

  const handleChange = (value, index) => {
    const updatedOtp = [...otp];
    updatedOtp[index] = value;
    setOtp(updatedOtp);

    // Move to the next input field automatically
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };
  const handleSubmit = async () => {
    const otpCode = otp.join("");

    if (otpCode.length !== 6) {
      setError("Please enter the complete 6-digit OTP.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_CRM_PAY_URL}/report/customer/${token}?action=VALIDATE_OTP&otp=${otpCode}`,
        {
          method: "GET",
          headers: {
            Accept: "*/*",
          },
        }
      );

      if (response.ok) {
        const data = await response.json(); // Ensure we process the response JSON

        // Ensure navigation only happens after validation
        if (data) {
          // Replace `success` with the actual key in your response indicating success
          console.log("OTP validated successfully.");
          setReportViewData(data);
          navigate("/inspection-report");
        } else {
          setError("Invalid OTP. Please try again.");
        }
      } else {
        const errorData = await response.json();
        console.error("Error from API:", errorData);
        setError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error validating OTP:", error);
      setError("An error occurred while validating OTP. Please try again.");
    }
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        padding="24px"
      >
        <Box width="100%" maxWidth="400px" borderRadius="16px" padding="4px">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            Enter the 6-digit code sent to your mobile number
          </Typography>
          <Box marginTop="24px" marginBottom="16px">
            <Grid container spacing={0.5} justifyContent="center">
              {otp.map((digit, index) => (
                <Grid item key={index}>
                  <TextField
                    type="tel"
                    id={`otp-input-${index}`}
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        fontSize: "24px",
                        width: "20px",
                        height: "20px",
                      },
                    }}
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "12px",
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Typography variant="body2" sx={{ color: "#757575" }}>
              If you forgot the registered mobile number, please call us on{" "}
              <Link href="tel:+917353343536" sx={{ color: "#003cc5" }}>
                +91-7353343536
              </Link>
            </Typography>
          </Box>
          {error && (
            <Typography
              variant="body2"
              sx={{ color: "red", marginBottom: "16px", textAlign: "center" }}
            >
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#003cc5",
              color: "#fff",
              fontWeight: "bold",
              textTransform: "none",
              padding: "10px 0",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#003cc5",
              },
            }}
            onClick={handleSubmit}
          >
            VERIFY
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default OTPVerification;
