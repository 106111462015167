export const IndonesiaContent = {
  heroPage: {
    header:
      'Inspeksi mobil kami menjamin mobil bekas yang bagus dengan harga yang wajar',
    subPoints: [
      'Pemeriksaan Mobil Bekas',
      'Layanan Pra-Pengiriman',
      'Garansi Perpanjangan hingga 2 Tahun',
    ],
    btnText: 'Pesan Pemeriksaan',
  },
};
