import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import track from 'react-tracking';
import Home from '../containers/home';
import Privacy from '../containers/privacy';
import TermsAndConditions from '../containers/terms-and-conditions';
import Scrooltotop from '../components/scrollToTop/index';
import Faq from '../containers/faq';
import ReportNotAvailable from '../containers/report-not-available';
import BuyNow from '../containers/buy-now';
import SampleReport from '../components/sampleReport/SampleReport';
import PlanDetailsBuyNow from '../components/plan-details-buy-now';
import AboutWiseDrive from '../containers/about-wisedrive/index';
import ServiceAndMaintenance from '../containers/ServiceAndMainentanancePolicy';
import Cancellation from '../containers/CancellationAndReturnPolicy/index';
import BuyBackPolicy from '../containers/buybackPolicy';
import WarrantyPolicy from '../containers/warranty-policy';
import PlanBuyStatus from '../components/payment-status';
import SampleReportNew from '../components/sampleReport/SampleReportNew';
import EditReport from '../components/sampleReport/EditReport/EditReport';
import OTPVerification from '../components/sampleReport/OtpVerification';

const AppRoutes = () => (
  <Router>
    <Scrooltotop />
    <Routes>
      {/* <Route exact path="/" element={<Landing />} /> */}
      <Route exact path="/" element={<Home />} />

      {/* New routs Start */}
      <Route exact path="/sample-report" element={<SampleReport />} />
      <Route exact path="/inspection-report" element={<SampleReportNew />} />
      <Route exact path='/edit-report/:id' element={<EditReport/>}/>
      <Route exact path="/report/verify" element={<OTPVerification />} />
      <Route
        exact
        path="/plan-details-buy-now"
        element={<PlanDetailsBuyNow />}
      />
      <Route exact path="/buy-now" element={<BuyNow />} />
      <Route exact path="/about-wisedrive" element={<AboutWiseDrive />} />
      <Route exact path="/payment-status" element={<PlanBuyStatus />} />

      {/* New routes end */}
      {/* reusing routs */}
      <Route exact path="/privacy" element={<Privacy />} />
      <Route
        exact
        path="/inspection-terms-and-conditions"
        element={<TermsAndConditions />}
      />
      <Route exact path="/warrantyPolicy" element={<WarrantyPolicy />}></Route>

      <Route
        exact
        path="/service-and-maintenance"
        element={<ServiceAndMaintenance />}
      ></Route>
      <Route
        exact
        path="/cancellation_refund_policy"
        element={<Cancellation />}
      ></Route>
      {/* end reusing routes */}
      {/* <Route exact path="/wiseassist" element={<FacebookLandingPage />} /> */}

      {/* <Route
        exact
        path="/payment-success-warranty-package/:id"
        element={<PaymentSuccessWarrantyPackage />}
      /> */}

      <Route exact path="/faq" element={<Faq />} />

      <Route exact path="/buybackPolicy" element={<BuyBackPolicy />}></Route>

      <Route
        exact
        path="/privacy-policy"
        element={<Navigate to="/privacy" />}
        status={301}
      />

      {/* <Route
        exact
        path="/asda12/:customerId/:insprequvehiId/:leadId/:leadvehicleId/:vehicleId"
        element={<Report />}
      /> */}

      {/* <Route exact path="/checkout-plans/:id" element={<CheckoutPlans />} /> */}

      <Route
        exact
        path="/report-not-available/:id"
        element={<ReportNotAvailable />}
      />
    </Routes>
  </Router>
);

const TrackedAppRoutes = track(
  { app: 'wisedrive website' },

  // top level options
  {
    // dispatch to console.log in addition to dataLayer
    dispatch: (data) => {
      // print data to developer console
      console.log(data);
      // console.log(window.dataLayer);
      // (window.dataLayer = window.dataLayer || []).push(data);
      // api call here to save the data
    },
  }
)(AppRoutes);

export default TrackedAppRoutes;
