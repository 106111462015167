import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const EditRatingModal = ({ open, onClose, category, currentScore, onSave }) => {
    const [score, setScore] = useState(currentScore);
  
    useEffect(() => {
      setScore(currentScore);
    }, [currentScore]);
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          Edit {category.replace(/_/g, ' ').toUpperCase()}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            type="number"
            label="Rating (0-10)"
            value={score}
            onChange={(e) => {
              const value = Math.min(10, Math.max(0, e.target.value));
              setScore(value);
            }}
            inputProps={{ min: 0, max: 10 }}
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button 
              variant="contained" 
              onClick={() => onSave(score)}
              sx={{ bgcolor: '#003cc5' }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default EditRatingModal;