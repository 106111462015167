import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import WiseDriveLogoNew from '../../../assets/images/header/wiseDrivelogoNew.png';
import './EditReport.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  MobileStepper,
  TextField,
  Typography,
} from '@mui/material';
import {
  AddBoxOutlined,
  Check,
  Close,
  Commit,
  Delete,
  Edit,
  ExpandMore,
  FileCopy,
  Launch,
  LocalPoliceOutlined,
  NavigateBefore,
  NavigateNext,
  Share,
} from '@mui/icons-material';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import chennai from '../../../assets/icons/chennai.png';
import hyderabad from '../../../assets/icons/hyderabad.png';
import mumbai from '../../../assets/icons/mumbai.png';
import delhi from '../../../assets/icons/delhi.png';
import pune from '../../../assets/icons/Pune.webp';
import noida from '../../../assets/icons/Noida.png';
import bangalore from '../../../assets/icons/bangalore.png';
import gurguaon from '../../../assets/icons/gurguaon.png';
import EditKeyAndAdditional from './EditComponents/EditKeyandAdditional';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import axios from 'axios';
import { getItem, setItem } from '../../../httpHelpers/storageHelper';
import DentDetailsModal from './EditComponents/DentsDetailModal';
import SummaryCard from './EditComponents/SummaryCard';
import InspectionSummary from './EditComponents/InspectionSummary';
import KeyInformation from './EditComponents/KeyInformation';
import { PortalContext } from '../../../context/carPortalContext';
import { useParams } from 'react-router-dom';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditRatingModal from './EditComponents/EditRatingModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Header = ({ carNo }) => {
  return (
    <div className="header">
      <div className="header__container">
        <div className="header__logo">
          <img
            src={WiseDriveLogoNew}
            alt="Orange Health Labs"
            className="header__logo-img"
          />
        </div>
        <div className="header__number">
          <p>{(carNo ? carNo : '-').toUpperCase()}</p>
        </div>
      </div>
    </div>
  );
};

export const InsuranceDetailsModal = ({ open, onClose, insuranceDetails }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: '16px',
        },
      }}
    >
      <DialogTitle sx={{ color: '#003cc5' }}>
        Insurance Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6} className="insurance-model">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontFamily: 'Manrope, sans-serif',
                color: '#4b5563',
                fontSize: '0.9rem',
              }}
            >
              Insurer:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontFamily: 'Manrope, sans-serif',
                color: '#000',
                fontSize: '15px',
              }}
            >
              {insuranceDetails.insurer}
            </Typography>
          </Grid>
          <Grid item xs={6} className="insurance-model">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontFamily: 'Manrope, sans-serif',
                color: '#4b5563',
                fontSize: '0.9rem',
              }}
            >
              Policy Number:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontFamily: 'Manrope, sans-serif',
                color: '#000',
                fontSize: '15px',
              }}
            >
              {insuranceDetails.number}
            </Typography>
          </Grid>
          <Grid item xs={6} className="insurance-model">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontFamily: 'Manrope, sans-serif',
                color: '#4b5563',
                fontSize: '0.9rem',
              }}
            >
              Expiry Date:
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontFamily: 'Manrope, sans-serif',
                color: '#000',
                fontSize: '15px',
              }}
            >
              {insuranceDetails.expiryDate}
            </Typography>
          </Grid>
          <Grid item xs={6} className="insurance-model">
            <Typography
              variant="body1"
              sx={{
                fontWeight: 500,
                fontFamily: 'Manrope, sans-serif',
                color: '#4b5563',
                fontSize: '0.9rem',
              }}
            >
              Expires In (days):
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontFamily: 'Manrope, sans-serif',
                // color: 'red',
                fontSize: '15px',
              }}
            >
              {insuranceDetails.expiresIn}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const AdditionalInformation = ({
  rtoInfo,
  additionalChallans,
  isPublished,
}) => {
  const {
    challans,
    setInitialChallans,
    calculateTotalChallans,
    removeChallan,
    loadInitialChallans,
  } = useContext(PortalContext);
  const [openModal, setOpenModal] = useState(false);
  const [openHypothecationModal, setOpenHypothecationModal] = useState(false);

  useEffect(() => {
    setInitialChallans(rtoInfo?.challans || []);
    const effectiveChallans =
      additionalChallans?.length > 0
        ? additionalChallans
        : rtoInfo?.challans || [];
    loadInitialChallans(effectiveChallans || []);
  }, [rtoInfo, additionalChallans]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenhypothecationModal = () => {
    setOpenHypothecationModal(true);
  };

  const handlehypothecationCloseModal = () => {
    setOpenHypothecationModal(false);
  };

  return (
    <>
      <div className="key-information-container">
        <h3 className="key-information__title">RTO Verification</h3>
        <div className="key-information__grid">
          <div className="key-info-item">
            <span className="key-info-label">Traffic Challans</span>
            <span
              className="key-info-value"
              style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}
            >
              Rs.{calculateTotalChallans()}{' '}
              <span
                style={{
                  fontSize: '12px',
                  color: '#003cc5',
                  textDecoration: 'underline',
                }}
                onClick={handleOpenModal}
              >
                {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label"> Hypothecation</span>
            <span className="key-info-value">
              {rtoInfo?.hypothecation === 'false' ? 'No' : 'Yes'}
              {rtoInfo?.hypothecation === 'true' && <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                }}
                onClick={handleOpenhypothecationModal}
              >
                {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Bank NOC</span>
            <span className="key-info-value highlighted-red">
              {rtoInfo?.bankNOC ? rtoInfo?.bankNOC : '-'}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Blacklist status</span>
            <span
              className="key-info-value highlighted-yellow"
              style={{
                color:
                  rtoInfo?.blacklistStatus === 'false' ? '#1a1a1a' : '#d32f2f',
              }}
            >
              {rtoInfo?.blacklistStatus === 'false' ? 'No' : 'Yes'}
            </span>
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle sx={{ color: '#003cc5' }}>
          Traffic Challans
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {challans.length > 0 ? (
            challans.map((challan, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                py={2}
                borderBottom={
                  index === challans.length - 1 ? 'none' : '1px solid #e0e0e0'
                }
              >
                {/* Police Icon */}
                <Box
                  sx={{
                    // width: "52px",
                    // height: "52px",
                    backgroundColor: '#f5f5f5',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '16px',
                    padding: '15px',
                  }}
                >
                  <LocalPoliceOutlined />
                </Box>

                {/* Details: Description, Amount, and Date */}
                <Box flexGrow={1}>
                  {/* Offense Description */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: '#333333',
                      fontFamily: 'Manrope, sans-serif',
                      padding: 0,
                      fontSize: '1rem',
                    }}
                  >
                    {challan.offenseDetails}
                  </Typography>

                  {/* Amount and Date */}
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#000',
                        fontWeight: 600,
                        marginRight: '8px',
                        fontFamily: 'Manrope, sans-serif',
                        fontSize: '16px',
                        padding: 0,
                      }}
                    >
                      {challan.amount}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#757575',
                        fontFamily: 'Manrope, sans-serif',
                        padding: 0,
                      }}
                    >
                      {challan.challanDate}
                    </Typography>
                  </Box>
                </Box>
                {challan.deletable && !isPublished && (
                  <IconButton
                    onClick={() => removeChallan(index)}
                    sx={{ color: 'red' }}
                  >
                    <Delete />
                  </IconButton>
                )}
              </Box>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ textAlign: 'center', padding: '20px' }}
            >
              No challans available.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
      {rtoInfo?.financier !== "" && <Dialog
        open={openHypothecationModal}
        onClose={handlehypothecationCloseModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle sx={{ color: "#003cc5" }}>
          Financiar Details
        </DialogTitle>
        <DialogContent>
            <Typography variant="subtitle1">{rtoInfo?.financier !== "" ? rtoInfo?.financier : 'No Data Available'}</Typography>
        </DialogContent>
      </Dialog>}
    </>
  );
};

const sampleSteps = [
  {
    text: 'This is a text-only step. This is a text-only step, This is a text-only step. This is a text-only step,This is a text-only step',
  },
  {
    text: 'This step contains text with images.',
    images: [
      'https://via.placeholder.com/600x300',
      'https://via.placeholder.com/400x200',
    ],
  },
  {
    text: 'This step contains a video.',
    video: 'https://www.youtube.com/embed/lB0ZTQcws_4?si=OgremihHWhdjFGbB',
  },
];

const CollapsibleCard = ({ title, categoryKey, points, isPublished }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [hiddenSteps, setHiddenSteps] = useState({}); // Track hidden states for steps
  const { categoryRatings, updateCategoryRating } = useContext(PortalContext);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const score = categoryRatings?.[categoryKey] || 0;
  // Generate steps dynamically based on the score
  const steps = Array.from(
    { length: score },
    (_, i) => sampleSteps[i % sampleSteps.length]
  );

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setActiveStep(0); // Reset to the first step when expanding
    }
  };

  const handleToggleVisibility = (stepIndex) => {
    const isHidden = hiddenSteps[stepIndex];
    const updatedHiddenSteps = { ...hiddenSteps, [stepIndex]: !isHidden };
    setHiddenSteps(updatedHiddenSteps);

    // Trigger API call to update visibility
    const stepId = steps[stepIndex]?.id;
    const visibility = !isHidden;
    updateStepVisibilityAPI(stepId, visibility);
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const updateStepVisibilityAPI = async (stepId, visibility) => {
    try {
      await fetch(`/api/step-visibility/${stepId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isVisible: visibility }),
      });
      console.log(`Step ${stepId} visibility updated to ${visibility}`);
    } catch (error) {
      console.error('Error updating step visibility:', error);
    }
  };

  const currentStep = steps[activeStep]; // Get the active step data

  return (
    <Box className="collapsible-card">
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // onClick={toggleExpand}
        sx={{ cursor: 'pointer' }}
        className="collapsible-card-header"
      >
        <Typography
          className="title"
          sx={{
            color: '#333',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '16px',
          }}
        >
          {title}
          <span style={{ color: '#787575' }}>{points} Check Points</span>
        </Typography>
        <Typography className="score" style={{ lineHeight: '22px' }}>
          <span style={{ fontSize: '12px', color: '#000' }}>Rating</span> <br />
          <span
            style={{
              fontSize: '20px',
              fontWeight: 600,
              color: score <= 4 ? 'red' : score <= 7 ? 'orange' : 'green',
            }}
          >
            {score}
          </span>{' '}
          / 10
        </Typography>
        {!isPublished && (
            <IconButton 
              size="small" 
              onClick={() => setEditModalOpen(true)}
              sx={{ p: 0.5 }}
            >
              <Edit fontSize="small" />
            </IconButton>
          )}
      </Box>

      <EditRatingModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        category={categoryKey}
        currentScore={score}
        onSave={(newScore) => {
          updateCategoryRating(categoryKey, newScore);
          setEditModalOpen(false);
        }}
      />

      {/* Collapsible Content */}
      {isExpanded && (
        <Box
          sx={{
            marginTop: '16px',
            padding: '16px',
            backgroundColor: '#f9f9f9',
            borderRadius: '8px',
          }}
        >
          {/* Step Content */}
          <Box
            sx={{
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '16px',
              backgroundColor: hiddenSteps[activeStep] ? '#f9f9f9' : '#fff',
            }}
          >
            {/* Step Header with Toggle */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body1" fontWeight="bold">
                {`Step ${activeStep + 1}`}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                color={hiddenSteps[activeStep] ? 'success' : 'error'}
                onClick={() => handleToggleVisibility(activeStep)}
                sx={{
                  textTransform: 'none',
                  fontSize: '12px',
                  padding: '4px 8px',
                }}
              >
                {hiddenSteps[activeStep] ? 'Show' : 'Hide'}
              </Button>
            </Box>

            {/* Step Content */}
            {!hiddenSteps[activeStep] && (
              <Box mt={2}>
                {/* Step Description */}
                <Typography variant="body2" sx={{ mb: 2 }}>
                  {currentStep.text}
                </Typography>

                {/* Images Carousel */}
                {currentStep.images && (
                  <ImageGallery
                    items={currentStep.images.map((image) => ({
                      original: image,
                      thumbnail: image,
                    }))}
                    showThumbnails={false}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    thumbnailPosition="bottom"
                    showNav={true}
                    slideInterval={2000}
                  />
                )}

                {/* Video */}
                {currentStep.video && (
                  <Box className="video-container" mt={2}>
                    <iframe
                      width="100%"
                      height="300px"
                      src={currentStep.video}
                      title={`Video for Step ${activeStep + 1}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>

          {/* Navigation */}
          <MobileStepper
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNextStep}
                disabled={activeStep === steps.length - 1}
              >
                Next
                <NavigateNext />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handlePreviousStep}
                disabled={activeStep === 0}
              >
                <NavigateBefore />
                Back
              </Button>
            }
          />
        </Box>
      )}

      {/* Show More / Show Less Button */}
      {/* <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          onClick={toggleExpand}
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "none",
            padding: "4px 12px",
            backgroundColor: "#398070",
            fontFamily: "Manrope, sans-serif",
            color: "#fff",
            borderRadius: "16px",
            "&:hover": {
              backgroundColor: "#2f6657",
            },
          }}
          className="showButton"
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Button>
      </Box> */}
    </Box>
  );
};


const HealthReport = ({ isPublished }) => {
  const [showFullReport, setShowFullReport] = useState(false);
  const { categoryRatings } = useContext(PortalContext);
  const handleViewFullReport = () => {
    setShowFullReport(true);
  };

  // Utility function to map keys to meaningful titles
  const getCategoryTitle = (key) => {
    const categoryTitles = {
      exterior_evaluation: 'Exterior Evaluation',
      head_lights_and_indicators: 'Headlights and Indicators',
      interior_evaluation: 'Interior Evaluation',
      infotainment_electrical_systems_accessories_analysis: 'Infotainment, Electrical Systems, and Accessories',
      engine_health_and_diagnostics: 'Engine Health and Diagnostics',
      transmission_assessment: 'Transmission Assessment',
      suspension_brakes_and_battery: 'Suspension, Brakes, and Battery',
      tires_and_tools: 'Tires and Tools',
    };
    return categoryTitles[key] || 'Unknown Category';
  };

  const categoriesArray = Object.entries(categoryRatings || {}).map(([key, value]) => ({
    key,
    score: value, // The rating score
    title: getCategoryTitle(key), // Map keys to user-friendly titles
  }));

  return (
    <section className="health-report">
      <div className="report-header">
        {categoriesArray.map((category, index) => (
          <div className="parameter-card" key={category.title}>
            <CollapsibleCard
             key={category.key}
             title={category.title}
             categoryKey={category.key}
             points={category.points}
             isPublished={isPublished}
            />
          </div>
        ))}
        {/* {!showFullReport && (
          <Button
            onClick={handleViewFullReport}
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              textTransform: "none",
              margin: "20px auto",
              display: "inline-block",
              padding: "8px 24px",
              backgroundColor: "#003cc5",
              fontFamily: "Manrope, sans-serif",
              color: "#fff",
              borderRadius: "12px",
              "&:hover": {
                backgroundColor: "#003cc5",
              },
            }}
          >
            View Full Report
          </Button>
        )} */}
      </div>
    </section>
  );
};

const FaultCodeCard = ({ title, initialErrorCodes, isPublished }) => {
  console.log(initialErrorCodes, 'initialErrorCodes');
  const { errorCodes, addErrorCode } = useContext(PortalContext);
  const [isAdding, setIsAdding] = useState(false);
  const [newPortNumber, setNewPortNumber] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    if (Array.isArray(initialErrorCodes)) {
      initialErrorCodes.forEach((code) => {
        if (
          !errorCodes.some(
            (ec) =>
              ec.description === code.description &&
              ec.portNumber === code.portNumber
          )
        ) {
          addErrorCode(code);
        }
      });
    }
  }, [initialErrorCodes, addErrorCode, errorCodes]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenAdd = () => {
    setIsAdding(true);
  };

  const handleCloseAdd = () => {
    setIsAdding(false);
    setNewPortNumber('');
    setNewDescription('');
  };

  // const handleSaveNewCode = () => {
  //   if (newPortNumber.trim() && newDescription.trim()) {
  //     const newErrorCode = {
  //       portNumber: newPortNumber,
  //       description: newDescription,
  //       module: "Unknown", // Assign to "Unknown" module
  //       possibleCauses: [],
  //       symptoms: [],
  //       solutions: [],
  //       status: "",
  //     };
  
  //     // Check if the error already exists
  //     const flattenedErrorCodes = Object.values(initialErrorCodes).flat();
  //     const isDuplicate = flattenedErrorCodes.some(
  //       (code) =>
  //         code.portNumber === newErrorCode.portNumber &&
  //         code.description === newErrorCode.description
  //     );
  
  //     if (!isDuplicate) {
  //       // Add the new error to the "Unknown" module
  //       if (!initialErrorCodes["Unknown"]) {
  //         initialErrorCodes["Unknown"] = [];
  //       }
  //       initialErrorCodes["Unknown"].push(newErrorCode);
  
  //       setNewPortNumber("");
  //       setNewDescription("");
  //       setIsAdding(false);
  //     } else {
  //       alert("Error code already exists.");
  //     }
  //   }
  // };
  

  const hasCategoryData = (error) => {
    return (
      (error.possibleCauses && error.possibleCauses.length > 0) ||
      (error.symptoms && error.symptoms.length > 0) ||
      (error.solutions && error.solutions.length > 0)
    );
  };

  const totalErrors = Object.values(initialErrorCodes)
  .flat()
  .length;

  return (
    <Box className="collapsible-card-obg">
      {/* Card Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={toggleExpand}
        sx={{ cursor: 'pointer' }}
        className="collapsible-card-header_OBD"
      >
        <Typography
          className="title"
          sx={{
            fontWeight: 500,
            color: '#333',
            fontFamily: 'Manrope, sans-serif',
          }}
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          className="collapsible-card-header_OBD_errors"
        >
         
            <Typography
              sx={{
                fontWeight: 500,
                color: 'red',
                marginRight: '8px',
                fontFamily: 'Manrope, sans-serif',
              }}
            >
              {totalErrors} errors found
            </Typography>

          
        
            {/* <Button
              onClick={toggleExpand}
              sx={{
                fontSize: "12px",
                textTransform: "none",
                padding: "4px 8px",
                borderRadius: "16px",
                backgroundColor: "#d32f2f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              className="view-fault-button"
            >
              {isExpanded ? "Hide Fault Codes" : "View Fault Codes"}
            </Button>
           */}
        </Box>
      </Box>
      {/* <IconButton
          color="primary"
          sx={{ position: 'absolute', top: '65px', right: '30px', backgroundColor: '#fee2e2', borderRadius: '8px 2px 8px 8px', fontSize: '12px', fontWeight: 700, textTransform: 'none', padding: '4px 12px', fontFamily: 'Manrope, sans-serif', color: '#ff0000' }}
        >
          View Fault Codes
        </IconButton> */}
      {/* {!isPublished && (
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: '20px', right: '30px' }}
          onClick={handleOpenAdd}
        >
          <AddBoxOutlined color="action" fontSize="large" />
        </IconButton>
      )} */}
      {/* Expandable Content */}
      {isExpanded && (
        <Box
          sx={{
            marginTop: '16px',
            // padding: '16px',
            // backgroundColor: '#f9f9f9',
            borderRadius: '8px',
            transition: 'max-height 0.3s ease-in-out',
          }}
        >
          {Object.entries(initialErrorCodes).map(([module, errors]) => (
            <Accordion
              key={module}
              sx={{
                mb: 1,
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Faults in <span style={{ color: "#d32f2f" }}>{module}</span>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ flexDirection: "column" }}>
                {/* {error.possibleCauses && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>
                      Possible Causes:
                    </Typography>
                    <ol>
                      {error.possibleCauses.map((cause, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}> <span className="mr-2 font-bold text-black">•</span> {`${cause}`}</li>
                      ))}
                    </ol>
                  </>
                )}
                {error.symptoms && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>Symptoms:</Typography>
                    <ol type="1">
                      {error.symptoms.map((symptom, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}><span className="mr-2 font-bold text-black">•</span> {symptom}</li>
                      ))}
                    </ol>
                  </>
                )}
                {error.solutions && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>Solutions:</Typography>
                    <ol type="1">
                      {error.solutions.map((solution, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}><span className="mr-2 font-bold text-black">•</span> {solution}</li>
                      ))}
                    </ol>
                  </>
                )} */}
                {errors.map((error, index) => (
              <Accordion
                key={index}
                sx={{ mb: 2, border: "none", borderRadius: "8px", boxShadow: "none" }}
              >
                {/* Port Number Accordion Summary */}
                <AccordionSummary expandIcon={hasCategoryData(error) ? <ExpandMore /> : null}>
                  <Box>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      color="primary"
                      gutterBottom
                    >
                      {error.portNumber}
                    </Typography>
                    {error.status && (
                      <Typography variant="body2" style={{color:"#b71c1c", fontSize: '12px'}} className="obdDescE">
                        {error.status}
                      </Typography>
                    )}
                    </div>
                    
                    <Typography variant="body2" className="obdDescE">{error.description || "No description available."}</Typography>
                    
                  </Box>
                </AccordionSummary>

                {/* Port Number Accordion Details */}
                {hasCategoryData(error) && (
                  <AccordionDetails>
                    {error.possibleCauses?.length > 0 && (
                      <Box mt={1}>
                        <Typography variant="body2" fontWeight="bold">
                          Possible Causes:
                        </Typography>
                        <ul>
                          {error.possibleCauses.map((cause, idx) => (
                            <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0', fontSize: '14px' }}><span className="mr-2 font-bold text-black">•</span> {cause}</li>
                          ))}
                        </ul>
                      </Box>
                    )}
                    {error.symptoms?.length > 0 && (
                      <Box mt={1}>
                        <Typography variant="body2" fontWeight="bold">
                          Symptoms:
                        </Typography>
                        <ul>
                          {error.symptoms.map((symptom, idx) => (
                            <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0', fontSize: '14px' }}><span className="mr-2 font-bold text-black">•</span> {symptom}</li>
                          ))}
                        </ul>
                      </Box>
                    )}
                    {error.solutions?.length > 0 && (
                      <Box mt={1}>
                        <Typography variant="body2" fontWeight="bold">
                          Solutions:
                        </Typography>
                        <ul>
                          {error.solutions.map((solution, idx) => (
                            <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0', fontSize: '14px' }}><span className="mr-2 font-bold text-black">•</span> {solution}</li>
                          ))}
                        </ul>
                      </Box>
                    )}
                  </AccordionDetails>
                )}
              </Accordion>
            ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
      
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            onClick={toggleExpand}
            sx={{
              fontSize: '12px',
              fontWeight: 700,
              textTransform: 'none',
              padding: '4px 12px',
              backgroundColor: '#eb6d474f',
              position: 'absolute',
              fontFamily: 'Manrope, sans-serif',
              color: '#ff0000',
              borderRadius: '8px 0',
              bottom: 0,
              right: 0,
              '&:hover': {
                backgroundColor: '#eb6d474f',
              },
            }}
            className="showButton"
          >
            {isExpanded ? 'Hide Fault Codes' : 'View Fault Codes'}
          </Button>
        </Box>
      
      {/* <Dialog
        open={isAdding}
        onClose={handleCloseAdd}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '12px', // Add rounded corners
          },
        }}
      >
        <DialogTitle>Add New OBD-2 Error</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="16px" mt={2}>
            <TextField
              label="Port Number"
              value={newPortNumber}
              onChange={(e) => setNewPortNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              fullWidth
            />
            <TextField
              label="Possible Causes"
              value={newPossibleCauses}
              onChange={(e) => setNewPossibleCauses(e.target.value)}
              fullWidth
            />
            <TextField
              label="Symptoms"
              value={newSymptoms}
              onChange={(e) => setNewSymptoms(e.target.value)}
              fullWidth
            />
            <TextField
              label="Solutions"
              value={newSolutions}
              onChange={(e) => setNewSolutions(e.target.value)}
              fullWidth
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="8px" mt={4}>
            <Button onClick={handleCloseAdd}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveNewCode}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};

const EstimateCard = ({ title, price, handleOpenModal }) => (
  <Card
    sx={{
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'flex-start',
      padding: '16px 8px',
      margin: '16px 8px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
      minWidth: '150px',
      borderRadius: '8px',
      position: 'relative',
    }}
    onClick={handleOpenModal}
    className="estimate-card"
  >
    <CardContent
      sx={{
        display: 'flex',
        padding: '2px 12px 24px',
      }}
      className="estimate-card-content"
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
          fontSize: '16px',
          fontFamily: 'Manrope, sans-serif',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 800,
          color: parseInt(price.replace('Rs ', '')) >= 5000 ? 'red' : '#000',
          fontFamily: 'Manrope, sans-serif',
        }}
      >
        {price}
      </Typography>
    </CardContent>
    <Button
      variant="contained"
      onClick={handleOpenModal}
      sx={{
        textTransform: 'none',
        backgroundColor: '#003cc5',
        alignSelf: 'end',
        color: '#fff',
        fontWeight: 700,
        fontSize: '12px',
        padding: '4px 6px',
        borderRadius: '8px 0px',
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        marginTop: '16px',
        fontFamily: 'Manrope, sans-serif',
        '&:hover': {
          backgroundColor: '#003cc5',
        },
      }}
      className="view-estimate-button"
    >
      View Estimate
    </Button>
  </Card>
);

export const EstimateModal = ({
  open,
  handleClose,
  estimates,
  title,
  handleDelete,
  isPublished,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px', // Set border radius for consistency
          padding: '16px',
        },
      }}
    >
      <DialogTitle sx={{ color: '#003cc5' }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="estimate_model_content" sx={{ padding: 0 }}>
        {estimates.length > 0 ? (
          estimates.map((estimate, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              py={2}
              borderBottom={
                index === estimates.length - 1 ? 'none' : '1px solid #e0e0e0'
              }
            >
              {/* Icon: Labour or Spare Parts */}
              <Box
                sx={{
                  width: '52px', // Fixed width
                  height: '52px', // Fixed height
                  minWidth: '52px', // Ensures the width doesn't shrink
                  backgroundColor: '#f5f5f5',
                  borderRadius: '50%',
                  fontFamily: 'Manrope, sans-serif',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '16px',
                  flexShrink: 0, // Prevent shrinking in flex layout
                  '@media (max-width: 468px)': {
                    width: '52px !important', // Adjusted width for mobile
                    height: '52px', // Adjusted height for mobile
                    minWidth: '52px', // Ensure minimum width doesn't shrink
                  },
                }}
                className="estimate_icon"
              >
                {estimate.type === 'Labour' ? (
                  <img
                    src="https://img.icons8.com/?size=100&id=BDXhhTXkmeSR&format=png&color=000000"
                    height={'30px'}
                  />
                ) : (
                  <img
                    src="https://img.icons8.com/?size=100&id=zrMKeqZpbojb&format=png&color=000000"
                    height={'30px'}
                  />
                )}
              </Box>

              {/* Details: Description, Amount, and Type */}
              <Box>
                {/* Description */}
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: '#333333',
                    fontFamily: 'Manrope, sans-serif',
                    padding: 0,
                  }}
                >
                  {estimate.title}
                </Typography>

                {/* Cost and Charge Type */}
                <Box display="flex" alignItems="center">
                  {/* Cost in Green */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#000',
                      fontWeight: 600,
                      marginRight: '8px',
                      paddingTop: '2px',
                      fontFamily: 'Manrope, sans-serif',
                      fontSize: '16px',
                    }}
                  >
                    {`${estimate.price} INR`}
                  </Typography>
                  {/* Labour or Spare Parts Label in Grey */}
                  <Typography
                    variant="body2"
                    sx={{
                      color: '#757575',
                      fontFamily: 'Manrope, sans-serif',
                      paddingTop: '2px',
                    }}
                  >
                    {estimate.category === 'Labour'
                      ? 'Labour Charge'
                      : 'Spare Parts'}
                  </Typography>
                  {!isPublished && (
                    <IconButton
                      onClick={() =>
                        handleDelete(
                          index,
                          title.includes('Major') ? 'Major' : 'Minor'
                        )
                      }
                      sx={{ color: 'red' }}
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Typography
            variant="body2"
            sx={{ textAlign: 'center', padding: '20px' }}
          >
            No estimates available.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

const RepairsEstimate = ({ setEditData, isPublished }) => {
  const { addChallan, addRepair, updateTyreLifeData, dentsData, setDentsData } =
    useContext(PortalContext);
  const [openModal, setOpenModal] = useState(false); // For Add Repairs Modal
  const [openChallanModal, setOpenChallanModal] = useState(false);
  const [openEstimateModal, setOpenEstimateModal] = useState(false); // For Viewing Estimate Modal
  const [selectedEstimate, setSelectedEstimate] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [openDentsModal, setOpenDentsModal] = useState(false);
  const [tyreLifeModalOpen, setTyreLifeModalOpen] = useState(false);
  const [tyreLifes, setTyreLifes] = useState({
    frontLeft: '',
    frontRight: '',
    rearLeft: '',
    rearRight: '',
  });
  const [majorRepairs, setMajorRepairs] = useState(() => {
    const savedRepairs = localStorage.getItem('majorRepairs');
    return savedRepairs ? JSON.parse(savedRepairs) : [];
  });
  const [minorRepairs, setMinorRepairs] = useState(() => {
    const savedRepairs = localStorage.getItem('minorRepairs');
    return savedRepairs ? JSON.parse(savedRepairs) : [];
  });
  const [newRepair, setNewRepair] = useState({
    title: '',
    price: '',
    category: 'Labour',
    type: 'Major',
  });
  const [challanData, setChallanData] = useState({
    offenseDetails: '',
    amount: '',
    challanDate: '',
  });
  const handleOpenAddModal = () => {
    setOpenModal(true);
  };

  const handleOpenChallanModal = () => {
    setOpenChallanModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenModal(false);
    setNewRepair({ title: '', price: '', category: 'Labour', type: 'Major' });
  };

  const handleCloseChallanModal = () => {
    setOpenChallanModal(false);
    setChallanData({ offenseDetails: '', amount: '', challanDate: '' });
  };

  const handleSaveRepair = () => {
    const repair = {
      title: newRepair.title,
      category: newRepair.category,
      price: newRepair.price,
      type: newRepair.type,
    };

    addRepair(repair, newRepair.type);
    // setEditData(newRepair.category === "Major" ? "majorRepairs" : "minorRepairs", repair);
    handleCloseAddModal();
  };

  const handleSaveChallan = () => {
    const today = new Date();
    const selectedDate = new Date(challanData.challanDate);

    // Validation checks
    if (!challanData.offenseDetails.trim()) {
      alert("Challan description cannot be empty.");
      return;
    }

    if (!challanData.amount || isNaN(Number(challanData.amount))) {
      alert("Amount must be a valid number.");
      return;
    }

    if (challanData.amount <= 0) {
      alert("Amount must be greater than zero.");
      return;
    }

    if (!challanData.challanDate) {
      alert("Date cannot be empty.");
      return;
    }

    if (selectedDate > today) {
      alert("Future dates are not allowed.");
      return;
    }

    addChallan({
      ...challanData,
      amount: `Rs. ${challanData.amount}`,
    });
    handleCloseChallanModal();
  };

  const handleOpenEstimateModal = (type) => {
    if (type === 'Major') {
      setSelectedEstimate(majorRepairs);
      setModalTitle('Major Repairs Estimate');
    } else {
      setSelectedEstimate(minorRepairs);
      setModalTitle('Minor Repairs Estimate');
    }
    setOpenEstimateModal(true);
  };

  const handleCloseEstimateModal = () => {
    setOpenEstimateModal(false);
  };
  const handleOpenDentsModal = () => {
    setDentsData({
      'Front-Left': { Dents: '', Scratches: '' },
      'Front-Right': { Dents: '', Scratches: '' },
      'Rear-Right': { Dents: '', Scratches: '' },
      'Rear-Left': { Dents: '', Scratches: '' },
    });
    setOpenDentsModal(true);
  };
  const handleCloseDentsModal = () => {
    setOpenDentsModal(false);
  };

  const handleSaveDents = (dents) => {
    setDentsData(dents);
    handleCloseDentsModal();
  };

  const handleOpenTyreLifeModal = () => setTyreLifeModalOpen(true);
  const handleCloseTyreLifeModal = () => setTyreLifeModalOpen(false);
  const handleTyreLifeChange = (name, value) => {
    setTyreLifes((prev) => ({ ...prev, [name]: value }));
  };
  const handleSaveTyreLife = () => {
    const averageLife =
      (
        (parseInt(tyreLifes.frontLeft.replace('%', '')) +
          parseInt(tyreLifes.frontRight.replace('%', '')) +
          parseInt(tyreLifes.rearLeft.replace('%', '')) +
          parseInt(tyreLifes.rearRight.replace('%', ''))) /
        4
      ).toFixed(0) + '%';

    const formattedTyreLifeData = {
      'Front-Left': tyreLifes.frontLeft,
      'Front-Right': tyreLifes.frontRight,
      'Rear-Left': tyreLifes.rearLeft,
      'Rear-Right': tyreLifes.rearRight,
      tyreLife: averageLife,
    };

    updateTyreLifeData(formattedTyreLifeData);
    handleCloseTyreLifeModal();
    alert('Tyre life data saved successfully!');
  };

  return (
    <Box sx={{ padding: '16px', position: 'relative' }}>
      {/* Add Repairs Button */}

      {!isPublished && (
        <Box
          display="flex"
          justifyContent="center"
          gap="12px"
          mb={majorRepairs.length === 0 && minorRepairs.length === 0 ? 3 : 1}
          // position={
          //   majorRepairs.length === 0 && minorRepairs.length === 0
          //     ? "relative"
          //     : "absolute"
          // }
          // top={
          //   majorRepairs.length === 0 && minorRepairs.length === 0
          //     ? "auto"
          //     : "16px"
          // }
          // right={
          //   majorRepairs.length === 0 && minorRepairs.length === 0
          //     ? "auto"
          //     : "16px"
          // }
          zIndex={1}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#003cc5',
              '&:hover': {
                backgroundColor: '#002a9b', // Slightly darker shade for hover effect
              },
            }}
            onClick={handleOpenAddModal}
          >
            Add Repairs
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#003cc5',
              '&:hover': {
                backgroundColor: '#002a9b', // Slightly darker shade for hover effect
              },
            }}
            onClick={handleOpenChallanModal}
          >
            Add Challans
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#003cc5',
              '&:hover': {
                backgroundColor: '#002a9b', // Slightly darker shade for hover effect
              },
            }}
            onClick={handleOpenDentsModal}
          >
            Add Dents
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#003cc5',
              '&:hover': {
                backgroundColor: '#002a9b', // Slightly darker shade for hover effect
              },
            }}
            onClick={handleOpenTyreLifeModal}
          >
            Add Tyre Life
          </Button>
        </Box>
      )}

      {/* Major and Minor Repairs Cards */}
      {/* <Box
          sx={{ display: "flex", justifyContent: "space-between", gap: "16px" }}
        >
          {majorRepairs.length > 0 && (
            <EstimateCard
              title="Major Repairs"
              price={`Rs ${calculateTotal(majorRepairs)}`}
              handleOpenModal={() => handleOpenEstimateModal("Major")}
            />
          )}
          {minorRepairs.length > 0 && (
            <EstimateCard
              title="Minor Repairs"
              price={`Rs ${calculateTotal(minorRepairs)}`}
              handleOpenModal={() => handleOpenEstimateModal("Minor")}
            />
          )}
        </Box> */}

      {/* Add Repairs Modal */}
      <Dialog
        open={openModal}
        onClose={handleCloseAddModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: '12px' },
        }}
      >
        <DialogTitle sx={{ color: '#003cc5' }}>Add Repairs</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="16px" mt={2}>
            <TextField
              label="Title"
              value={newRepair.title}
              onChange={(e) =>
                setNewRepair({ ...newRepair, title: e.target.value })
              }
              fullWidth
            />
            <TextField
              label="Price"
              type="number"
              value={newRepair.price}
              onChange={(e) =>
                setNewRepair({ ...newRepair, price: e.target.value })
              }
              fullWidth
            />
            <TextField
              select
              label="Repair Type"
              value={newRepair.category}
              onChange={(e) =>
                setNewRepair({ ...newRepair, category: e.target.value })
              }
              fullWidth
            >
              <MenuItem value="Labour">Labour Charge</MenuItem>
              <MenuItem value="Spare">Spare Parts</MenuItem>
            </TextField>
            <TextField
              select
              label="Major or Minor"
              value={newRepair.type}
              onChange={(e) =>
                setNewRepair({ ...newRepair, type: e.target.value })
              }
              fullWidth
            >
              <MenuItem value="Major">Major</MenuItem>
              <MenuItem value="Minor">Minor</MenuItem>
            </TextField>
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="8px" mt={4}>
            <Button onClick={handleCloseAddModal}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveRepair}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Add Challans Modal */}
      <Dialog
        open={openChallanModal}
        onClose={handleCloseChallanModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: { borderRadius: '12px' },
        }}
      >
        <DialogTitle sx={{ color: '#003cc5' }}>Add Challan</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="16px" mt={2}>
            <TextField
              label="Challan Description"
              value={challanData.offenseDetails}
              onChange={(e) =>
                setChallanData({
                  ...challanData,
                  offenseDetails: e.target.value,
                })
              }
              fullWidth
            />
            <TextField
              label="Amount"
              type="number"
              value={challanData.amount}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only numeric values
                if (/^\d*$/.test(value)) {
                  setChallanData({ ...challanData, amount: value });
                }
              }}
              fullWidth
              error={challanData.amount === ""}
              helperText={challanData.amount === "" ? "Amount is required" : ""}
            />
            <TextField
              type="date"
              label="Date"
              value={challanData.date}
              onChange={(e) => {
                const selectedDate = new Date(e.target.value);
                const today = new Date();

                if (selectedDate <= today) {
                  setChallanData({
                    ...challanData,
                    challanDate: e.target.value,
                  });
                } else {
                  alert('Future dates are not allowed.');
                }
              }}
              fullWidth
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="8px" mt={4}>
            <Button onClick={handleCloseChallanModal}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveChallan}
              sx={{ backgroundColor: '#003cc5' }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {/* Estimate Modal */}
      <EstimateModal
        open={openEstimateModal}
        handleClose={handleCloseEstimateModal}
        estimates={selectedEstimate}
        title={modalTitle}
      />
      <DentDetailsModal
        isOpen={openDentsModal}
        onClose={handleCloseDentsModal}
        onSave={handleSaveDents}
        dentsData={dentsData}
      />
      <Dialog
        open={tyreLifeModalOpen}
        onClose={handleCloseTyreLifeModal}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: '16px',
          },
        }}
      >
        <DialogTitle sx={{ color: '#003cc5' }}>
          Tyre Life Details
          {/* <IconButton
            aria-label="close"
            onClick={handleCloseTyreLifeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: '30px' }}>
          <Grid container spacing={2}>
            {Object.keys(tyreLifes).map((key) => (
              <Grid item xs={6} key={key}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: '#4b5563',
                    fontSize: '0.9rem',
                    fontFamily: 'Manrope, sans-serif',
                  }}
                >
                  {key.replace(/([A-Z])/g, ' $1').trim()}:{' '}
                  {/* Converts camelCase to normal text */}
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={tyreLifes[key]}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numbers
                    const numericValue = Math.min(
                      100,
                      Math.max(0, parseInt(value || 0, 10))
                    ); // Clamp between 0 and 100
                    handleTyreLifeChange(key, `${numericValue}%`);
                  }}
                  sx={{ marginTop: '8px' }}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              marginTop: '16px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={handleCloseTyreLifeModal}>Cancel</Button>
            <Button
              onClick={handleSaveTyreLife}
              variant="contained"
              sx={{ backgroundColor: '#003cc5' }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const NeoInfo = () => {
  return (
    <article className="neo-info">
      <section className="neo-grid">
        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="28"
              fill="none"
            >
              <path
                fill="url(#phone_svg__a)"
                d="M21.083 3.5c0-1.93-1.644-3.5-3.666-3.5H4.583C2.561 0 .917 1.57.917 3.5v21c0 1.93 1.644 3.5 3.666 3.5h12.834c2.022 0 3.666-1.57 3.666-3.5zm-7.333 21c0 .481-.413.875-.917.875H9.167c-.505 0-.917-.394-.917-.875s.412-.875.917-.875h3.666c.504 0 .917.394.917.875m-9.167-21h12.834V21H4.583z"
              />
              <defs>
                <linearGradient
                  id="phone_svg__a"
                  x1="3.208"
                  x2="22.987"
                  y1="-16.625"
                  y2="24.054"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Easy to Read
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            Made for easy understanding of every detail of your inspection
            report on mobile.
          </span>
        </div>

        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              fill="none"
            >
              <path
                fill="url(#track_svg__a)"
                d="M3 2C3 1.17 2.33.5 1.5.5S0 1.17 0 2v15.75a3.75 3.75 0 0 0 3.75 3.75H22.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H3.75a.75.75 0 0 1-.75-.75zm19.06 4.06a1.502 1.502 0 0 0-2.124-2.124L15 8.876l-2.69-2.69a1.5 1.5 0 0 0-2.124 0l-5.25 5.25a1.502 1.502 0 0 0 2.123 2.123l4.191-4.186 2.69 2.691a1.5 1.5 0 0 0 2.124 0l6-6z"
              />
              <defs>
                <linearGradient
                  id="track_svg__a"
                  x1="21.273"
                  x2="10.707"
                  y1="-11.969"
                  y2="22.515"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Easy to Track
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            Get complete details of post purchase expenses before buying the
            used car.
          </span>
        </div>

        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="url(#search_svg__a)"
                fillRule="evenodd"
                d="M10.736 2.512c-4.436 0-8.03 3.657-8.03 8.166s3.594 8.166 8.03 8.166c4.435 0 8.029-3.657 8.029-8.166s-3.594-8.166-8.03-8.166M.75 10.678C.75 5.067 5.222.52 10.736.52s9.985 4.547 9.985 10.158-4.471 10.158-9.985 10.158S.75 16.29.75 10.678m17.682 7.44a.966.966 0 0 1 1.384 0l3.147 3.2a1.01 1.01 0 0 1 0 1.41.966.966 0 0 1-1.383 0l-3.147-3.201a1.01 1.01 0 0 1 0-1.409"
                clipRule="evenodd"
              />
              <defs>
                <linearGradient
                  id="search_svg__a"
                  x1="20.693"
                  x2="8.086"
                  y1="-12.84"
                  y2="23.161"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Comprehensive Report
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            This report is generated using Physical Inspection, AI, OBD2
            Scanning using the latest scanning tools.
          </span>
        </div>
      </section>
    </article>
  );
};

const Availability = () => {
  return (
    <section className="availability">
      <div
        className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-100"
        style={{ color: '#fff' }}
      >
        We are available in
      </div>

      <div className="cities">
        <div className="city">
          <img src={delhi} alt="delhi" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Delhi NCR
          </span>
        </div>

        <div className="city">
          <img src={mumbai} alt="mumbai" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Mumbai
          </span>
        </div>

        <div className="city">
          <img src={hyderabad} alt="hyderabad" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Hyderabad
          </span>
        </div>

        <div className="city">
          <img
            src={bangalore}
            alt="bangalore"
            loading="lazy"
            height={48}
            className="icon-white"
          />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Bangalore
          </span>
        </div>
        <div className="city">
          <img src={chennai} alt="chennai" loading="lazy" height={48} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Chennai
          </span>
        </div>
        <div className="city">
          <img
            src={pune}
            alt="pune"
            loading="lazy"
            height={48}
            className="icon-white"
          />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Pune
          </span>
        </div>
        <div className="city">
          <img src={gurguaon} alt="pune" loading="lazy" height={58} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Gurgaon
          </span>
        </div>
        <div className="city">
          <img src={noida} alt="noida" loading="lazy" height={58} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Noida
          </span>
        </div>
      </div>
    </section>
  );
};

const BrandInfo = () => {
  return (
    <section className="brand-info">
      <div className="trust-markers">
        <div className="header__logo">
          <img
            src={WiseDriveLogoNew}
            alt="Orange Health Labs"
            className="header__logo-img"
            style={{ height: "80px", width: "230px" }}
          />
        </div>
      </div>

      <div className="download-app-buttons">
        <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-600 oui-typography--decoration-none oui-text-white">
          K No-661/3-1114/3,4,5, 3rd Floor, No.46/4, Novel Tech Park, G B Palya,
          Bangalore, Karnataka - 560068
        </span>
      </div>
    </section>
  );
};

const EditReport = () => {
  const params = useParams();
  const [isPublished, setIsPublished] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [editData, setEditData] = useState(null);
  const [lastSavedTime, setLastSavedTime] = useState('Not saved yet');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [fileURL, setFileURL] = useState(null); // URL for preview
  const [uploadedFile, setUploadedFile] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [renderTrigger, setRenderTrigger] = useState(false); // New state to trigger re-render
  const {
    summaryData,
    inspectionSummary,
    challans,
    errorCodes,
    keyInfo,
    majorRepairs,
    minorRepairs,
    insuranceData,
    dentsData,
    tyreLifeData,
    setOwnerCount,
    ownerCount,
    setCategoryRatings,
    categoryRatings
  } = useContext(PortalContext);
  
  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setUploadedFileName(file.name); // Store the file's name (string) instead of the file object
      setUploadedFile(file);
      const filePreviewURL = URL.createObjectURL(file);
      setFileURL(filePreviewURL);
    }
  };
  console.log(majorRepairs, 'major', minorRepairs);
  const handleFetchReport = async () => {
    if (!uploadedFile) {
      alert('Please upload a file first!');
      return;
    }
    const summaryDataFinal = summaryData;
    const inspectionSummaryData = inspectionSummary || reportData.summary || '';
    const dentsOnBody =
      dentsData || reportData.keyInformation?.dentsOnBody || {};
    const challansFinalData = challans || reportData.additionalChallans || [];
    const majorRepairsData =
      majorRepairs || reportData.keyInformation?.majorRepair || null;
    const minorRepairsData =
      minorRepairs || reportData.keyInformation?.minorRepair || null;
    const keyInfoFields = keyInfo || reportData.keyInformation || {};
    const keyInformation = {
      kmsDriven:
        keyInfoFields.kmsDriven || reportData.keyInformation?.kmsDriven || '',
      majorAccident:
        keyInfoFields.majorAccident ||
        reportData.keyInformation?.majorAccident ||
        '',
      floodDamage:
        keyInfoFields.floodDamage ||
        reportData.keyInformation?.floodDamage ||
        '',
      engineCondition:
        keyInfoFields.engineCondition ||
        reportData.keyInformation?.engineCondition ||
        '',
      transmission:
        keyInfoFields.transmission ||
        reportData.keyInformation?.transmission ||
        '',
      exteriorCondition:
        keyInfoFields.exteriorCondition ||
        reportData.keyInformation?.exteriorCondition ||
        '',
      interiorCondition:
        keyInfoFields.interiorCondition ||
        reportData.keyInformation?.interiorCondition ||
        '',
      dentsOnBody: dentsOnBody,
      tyreLife: tyreLifeData || reportData.keyInformation?.tyreLife || {},
      insurance:
        keyInfoFields.insurance ||
        reportData.keyInformation?.insurance ||
        'Not Available',
      majorRepair: majorRepairsData,
      minorRepair: minorRepairsData,
    };
    const formData = new FormData();
    formData.append('file', uploadedFile);
    const payload = {
      marketValue:
        summaryDataFinal?.marketValue || reportData?.marketValue || '',
      overallRating:
        summaryDataFinal?.overallRating || reportData?.overallRating || '',
      recommendedToBuy:
        summaryDataFinal?.recommended || reportData?.recommendedToBuy || '',
      summary: inspectionSummaryData,
      keyInformation: keyInformation,
      additionalChallans: challansFinalData,
      additionalOBDScanningErrors:
        errorCodes || reportData.obdScanningErrors || [],
      additionalData: {
          ownerCount: ownerCount.toString(),
          categoryWiseRating: {
            exterior_evaluation: categoryRatings?.exterior_evaluation,
            head_lights_and_indicators: categoryRatings?.head_lights_and_indicators,
            interior_evaluation: categoryRatings?.interior_evaluation,
            infotainment_electrical_systems_accessories_analysis: 
            categoryRatings?.infotainment_electrical_systems_accessories_analysis,
            engine_health_and_diagnostics: categoryRatings?.engine_health_and_diagnostics,
            transmission_assessment: categoryRatings?.transmission_assessment,
            suspension_brakes_and_battery: categoryRatings?.suspension_brakes_and_battery,
            tires_and_tools: categoryRatings?.tires_and_tools,
          }
        }
    };
    const reportRequestBlob = new Blob([JSON.stringify(payload)], {
      type: 'application/json',
    });
    formData.append("reportRequest", reportRequestBlob);
    setLoading(true);
    console.log('Payload:', payload, 'formdata', formData);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CRM_PAY_URL}/report/generate/ai/pdf/${params?.id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Cookie: 'JSESSIONID=C29C37FCF4B736C863C73EC721BEF2F5',
          },
        }
      );
      setLoading(false);
      console.log('File uploaded and report fetched:', response.data);
      setReportData(response.data);
      setLastSavedTime(new Date().toLocaleString());
    } catch (error) {
      console.error('Error fetching report:', error);
      alert('Error fetching report.');
    }
  };

  const SaveFetchReport = async () => {
    const summaryDataFinal = summaryData;
    const inspectionSummaryData = inspectionSummary || reportData.summary || '';
    const dentsOnBody =
      dentsData || reportData.keyInformation?.dentsOnBody || {};
    const challansFinalData = challans || reportData.additionalChallans || [];
    const majorRepairsData =
      majorRepairs || reportData.keyInformation?.majorRepair || null;
    const minorRepairsData =
      minorRepairs || reportData.keyInformation?.minorRepair || null;
    const keyInfoFields = keyInfo || reportData.keyInformation || {};
    const keyInformation = {
      kmsDriven:
        keyInfoFields.kmsDriven || reportData.keyInformation?.kmsDriven || '',
      majorAccident:
        keyInfoFields.majorAccident ||
        reportData.keyInformation?.majorAccident ||
        '',
      floodDamage:
        keyInfoFields.floodDamage ||
        reportData.keyInformation?.floodDamage ||
        '',
      engineCondition:
        keyInfoFields.engineCondition ||
        reportData.keyInformation?.engineCondition ||
        '',
      transmission:
        keyInfoFields.transmission ||
        reportData.keyInformation?.transmission ||
        '',
      exteriorCondition:
        keyInfoFields.exteriorCondition ||
        reportData.keyInformation?.exteriorCondition ||
        '',
      interiorCondition:
        keyInfoFields.interiorCondition ||
        reportData.keyInformation?.interiorCondition ||
        '',
      dentsOnBody: dentsOnBody,
      tyreLife: tyreLifeData || reportData.keyInformation?.tyreLife || {},
      insurance:
        keyInfoFields.insurance ||
        reportData.keyInformation?.insurance ||
        'Not Available',
      majorRepair: majorRepairsData,
      minorRepair: minorRepairsData,
    };
    const payload = {
      marketValue:
        summaryDataFinal?.marketValue || reportData?.marketValue || '',
      overallRating:
        summaryDataFinal?.overallRating || reportData?.overallRating || '',
      recommendedToBuy:
        summaryDataFinal?.recommended || reportData?.recommendedToBuy || '',
      summary: inspectionSummaryData,
      keyInformation: keyInformation,
      additionalChallans: challansFinalData,
      additionalOBDScanningErrors:
        errorCodes || reportData.obdScanningErrors || [],
      additionalData: {
          ownerCount: ownerCount.toString(),
          categoryWiseRating: {
            exterior_evaluation: categoryRatings?.exterior_evaluation,
            head_lights_and_indicators: categoryRatings?.head_lights_and_indicators,
            interior_evaluation: categoryRatings?.interior_evaluation,
            infotainment_electrical_systems_accessories_analysis: 
            categoryRatings?.infotainment_electrical_systems_accessories_analysis,
            engine_health_and_diagnostics: categoryRatings?.engine_health_and_diagnostics,
            transmission_assessment: categoryRatings?.transmission_assessment,
            suspension_brakes_and_battery: categoryRatings?.suspension_brakes_and_battery,
            tires_and_tools: categoryRatings?.tires_and_tools,
          }
        }
    };
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_CRM_PAY_URL}/report/save/${params?.id}`;
    console.log('Payload:', payload);
    try {
      const response = await axios.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      toast.success("Report saved successfully!");
      setReportData(response.data);
      setLastSavedTime(new Date().toLocaleString());
    } catch (error) {
      console.error('Error saving report:', error);
      toast.error('Failed to save the report.');
    } finally {
      setLoading(false);
    }
  };

  const handlePublish = async () => {
    setIsPublishing(true); // Disable the button while processing
    const apiUrl = `${process.env.REACT_APP_CRM_PAY_URL}/report/publish/${params?.id}`;
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          Accept: "*/*",
        },
      });

      if (response.status === 200) {
        // If the publish API call is successful
        toast.success('Report published successfully!');
        setIsPublished(true); // Mark as published
        setLastSavedTime(new Date().toLocaleString()); // Update last saved time
        setUploadedFileName(''); // Reset uploaded file name
        setUploadedFile(null); // Reset uploaded file state
        setFileURL(null); // Reset file URL state
        setRenderTrigger(!renderTrigger); // Trigger re-render
        console.log('Report published successfully.');
      } else {
        toast.error('Failed to publish the report.');
      }
    } catch (error) {
      console.error('Error publishing report:', error);
      toast.error('Publishing failed.');
    } finally {
      setIsPublishing(false);
    }
  };

  useEffect(() => {
    // Removed local storage handling for file data
    const fetchReportData = async () => {
      setLoading(true);
      const apiUrl = `${process.env.REACT_APP_CRM_PAY_URL}/report/${params?.id}`;
      try {
        const response = await axios.get(apiUrl);
        console.log(response, "response");
        setReportData(response.data);
        if (response.data.pdfReportAttachment) {
          setUploadedFileName(response.data.pdfAttachmentName);
          setFileURL(
            `data:application/pdf;base64,${response.data.pdfReportAttachment}`
          );
        }
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        toast.error('Failed to load report data.');
      } finally {
        setLoading(false);
      }
    };
    fetchReportData();
  }, [params.id, renderTrigger]); // Add renderTrigger to dependency array

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePreviewPDF = () => {
    if (!fileURL) {
      alert('No file uploaded for preview!');
      return;
    }
    setOpenPreview(true);
  };

  const handleUploadReport = () => {
    // Add logic to upload the report
    alert('Uploading report...');
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const reportPublished = reportData?.isPublished === true;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(reportData?.reportPublishedURL).then(() => {
      alert('URL copied to clipboard!');
    });
  };

  const handleShareClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Report Verification Link",
          text: "Check out this verification report:",
          url: reportData?.reportPublishedURL,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      alert("Share not supported on this browser.");
    }
  };

  useEffect(() => {
    const ratingsFromAdditionalData = reportData?.additionalData?.categoryWiseRating;
    const ratingsFromAiInsights = reportData?.aiInsights?.categoryWiseRating;
  console.log(ratingsFromAdditionalData, 'ratingsFromAdditionalData');
    if (ratingsFromAdditionalData && Object.keys(ratingsFromAdditionalData).length > 0) {
      // Set category ratings from additionalData if available
      setCategoryRatings(ratingsFromAdditionalData);
    } else if (ratingsFromAiInsights && Object.keys(ratingsFromAiInsights).length > 0) {
      // Otherwise, fallback to aiInsights if available
      setCategoryRatings(ratingsFromAiInsights);
    } else {
      // Fallback to an empty array to avoid errors
      setCategoryRatings([]);
    }
  }, [reportData]);
  

  useEffect(() => {
    if (reportData) {
      // Handle owner count
      const initialOwnerCount = reportData?.additionalData?.ownerCount || 
                               reportData?.vehicleInformation?.owners || 
                               '1'; // Default value
      setOwnerCount(initialOwnerCount);
    }
  }, [reportData]);

  const mergeAndFilterErrors = (additionalErrors, baseErrors) => {
    const allErrors = [...(additionalErrors || []), ...(baseErrors || [])];
    // const uniqueErrors = allErrors.filter(
    //   (error, index, self) =>
    //     index ===
    //     self.findIndex(
    //       (e) =>
    //         e.portNumber === error.portNumber && e.description === error.description
    //     )
    // );
    return allErrors;
  };

  const processedErrorCodes = mergeAndFilterErrors(
    reportData?.additionalOBDScanningErrors,
    reportData?.obdScanningErrors
  );
console.log(processedErrorCodes)
  const groupErrorsByModule = (errors) => {
    return errors.reduce((grouped, error) => {
      const moduleName = error.module || "Unknown";
      if (!grouped[moduleName]) {
        grouped[moduleName] = [];
      }
      grouped[moduleName].push(error);
      return grouped;
    }, {});
  };

  const groupedErrorCodes = groupErrorsByModule(processedErrorCodes);
  console.log(groupedErrorCodes, 'groupedErrorCodes');
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-wrapper">
        <Header carNo={reportData?.carRegistrationNumber} />
        {!reportPublished && (
          <Box
            sx={{
              border: '1px solid #ccc',
              padding: '16px',
              borderRadius: '8px',
              width: '320px',
              margin: '16px 5px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              position: 'fixed',
              right: 0,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Inspection Report
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
                gap: '8px',
              }}
            >
              <Button
                variant="outlined"
                component="label"
                size="small"
                sx={{ fontSize: '0.8rem', padding: '4px 8px' }}
              >
                Upload Report
                <input
                  type="file"
                  accept="application/pdf"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              {uploadedFileName && (
                <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                  {uploadedFileName}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={handlePreviewPDF}
                sx={{ fontSize: '0.8rem', padding: '4px 8px' }}
                disabled={!fileURL}
              >
                Preview PDF
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={handleFetchReport}
                sx={{ fontSize: '0.8rem', padding: '4px 8px' }}
              >
                Save & Fetch Report
              </Button>
            </Box>
            <Typography variant="body2" sx={{ marginLeft: '8px' }}>
              Last Updated: {lastSavedTime}
            </Typography>
          </Box>
        )}
        {!reportPublished && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'fixed',
              right: 180,
              bottom: 40,
            }}
          >
            <Button
              variant="contained"
              size="small"
              onClick={SaveFetchReport}
              sx={{
                fontSize: '0.8rem',
                padding: '7px 10px',
                backgroundColor: '#003cc5',
                '&:hover': { backgroundColor: '#002a9f' },
              }}
              disabled={!reportData?.pdfAttachmentName}
            >
              Save Report
            </Button>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'fixed',
            right: 40,
            bottom: 40,
          }}
        >
          {!reportPublished ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePublish}
              disabled={isPublishing}
              sx={{
                backgroundColor: isPublishing ? '#aaa' : '#003cc5',
                '&:hover': {
                  backgroundColor: isPublishing ? '#aaa' : '#002a9f',
                },
              }}
            >
              {isPublishing ? 'Publishing...' : 'Publish'}
            </Button>
          ) : (
            <>
              <TextField
                value={reportData?.reportPublishedURL}
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton onClick={handleCopyClick}>
                        <FileCopy />
                      </IconButton>
                      <IconButton onClick={handleShareClick}>
                        <Share />
                      </IconButton>
                    </>
                  ),
                }}
                label="Share with customer"
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                disabled
                sx={{
                  backgroundColor: '#ccc',
                  cursor: 'not-allowed',
                }}
              >
                Published
              </Button>
            </>
          )}
          {reportPublished && (
            <p
              style={{ fontSize: '16px' }}
            >{`Last published on ${reportData?.reportPublishedDate}`}</p>
          )}
        </Box>
        <Dialog
          open={openPreview}
          onClose={handleClosePreview}
          fullWidth
          // maxWidth="lg"
        >
          <DialogContent sx={{ p: 0 }}>
            {fileURL && (
              <Document file={fileURL} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClosePreview}>Close</Button>
          </DialogActions>
        </Dialog>
        <SummaryCard summary={reportData} isPublished={reportPublished} />
        <InspectionSummary
          initialSummary={reportData}
          isPublished={reportPublished}
        />
        {/* <VehicleInformationModal
          open={openModal}
          handleClose={handleCloseModal}
        /> */}
        <KeyInformation
          initialKeyInfo={reportData?.keyInformation}
          insuranceData={reportData?.rtoInformation?.insurance}
          dentsData={reportData?.keyInformation?.dentsOnBody}
          tyreLifeData={reportData?.keyInformation?.tyreLife}
          majorRepairData={reportData?.keyInformation?.majorRepair}
          minorRepairData={reportData?.keyInformation?.minorRepair}
          isPublished={reportPublished}
        />
        <AdditionalInformation
          rtoInfo={reportData?.rtoInformation}
          additionalChallans={reportData?.additionalChallans}
          isPublished={reportPublished}
        />
        <FaultCodeCard
          title="OBD-2 Scanning Report"
          initialErrorCodes={groupedErrorCodes}
          isPublished={reportPublished}
        />
        <RepairsEstimate
          setEditData={setEditData}
          isPublished={reportPublished}
        />
        <HealthReport isPublished={reportPublished}/>
        <NeoInfo />
        <Availability />
        <BrandInfo />
      </div>
    </>
  );
};

export default EditReport;
