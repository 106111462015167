import React from 'react';
import Slider from 'react-slick';
import { Button, Rating } from '@mui/material';
import './customers.css';
import { GetAllReviewsInfo } from '../../Api/PortalApis';
import { useState, useEffect } from 'react';
import googleIcon from '../../assets/images/customer/googleLogo.png';
import ratingIcon from '../../assets/images/customer/rating.png';
import gIcon from '../../assets/images/customer/GIcon.png';
import StarIcon from '@mui/icons-material/Star';
import CallMadeIcon from '@mui/icons-material/CallMade';

function getRandomObjectsFromArray(array, count) {
  if (array.length > count) {
    const shuffled = array.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  } else {
    return array;
  }
}

function formatDate(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return date.toLocaleDateString('en-IN', options);
}

const reviewsData = [
  {
    reviewer_name: 'Mallikarjun',
    car: 'Hyundai i10',
    description:
      'Wisedrive’s inspection service was a game-changer for me! I was looking at a used car that seemed perfect, but their thorough inspection revealed some hidden issues. I ended up negotiating a better price thanks to their detailed report. Highly recommend it!',
  },
  {
    reviewer_name: 'Nikita Sharma',
    car: 'Toyota Glanza',
    description:
      'As a first-time car buyer, I was nervous about buying used. Wisedrive made the process so much easier. Their inspector was punctual, professional, and explained everything in simple terms. The report was comprehensive, and I felt confident in my purchase.',
  },
  {
    reviewer_name: 'Dilip Patel',
    car: 'Maruti Swift',
    description:
      'Wisedrive saved me from buying a bad car! The car looked great on the outside, but the inspector found several mechanical issues. Their service is worth every penny if you want to save yourself from fraud when buying a used car.',
  },
  {
    reviewer_name: 'Dharmesh Soni',
    car: 'MG Hector',
    description:
      'I’ve used Wisedrive twice now, and both times they’ve delivered exceptional service. The inspectors are knowledgeable and don’t rush through the process. The detailed reports helped me make informed decisions. Definitely a must-use service for used car buyers.',
  },
  {
    reviewer_name: 'Swati Gorakh',
    car: 'Skoda Octavia',
    description:
      'Impressed with Wisedrive! The inspector was very thorough and caught a few minor issues that I wouldn’t have noticed. The seller even admitted that there were things but small so they hadn’t disclosed. I felt much more confident in my purchase after using this service.',
  },
  {
    reviewer_name: 'Rudra Gowda',
    car: 'Hyundai Venue',
    description:
      'Wisedrive is a must-have service if you’re buying a used car. Their inspection report was detailed and easy to understand. The inspector went above and beyond to check every detail. I’m so glad I used them before making my purchase.',
  },
  {
    reviewer_name: 'Chetana',
    car: 'Volkswagen Polo',
    description:
      'I was on the fence about getting an inspection, but I’m so glad I did! Wisedrive found issues with the transmission that could have cost me thousands. Their service is top-notch and definitely worth the investment.',
  },
  {
    reviewer_name: 'Gomti',
    car: 'Toyota Fortuner',
    description:
      'I’ve always been skeptical of buying used cars, but Wisedrive made the process stress-free. Their inspection was incredibly thorough, and the report gave me confidence that I was making a good purchase. Highly recommend to anyone buying a used car!',
  },
  {
    reviewer_name: 'Naduvinahalli Kariyappa',
    car: 'Ford Endeavour',
    description:
      'The inspector from Wisedrive was friendly, professional, and very detailed. The report covered everything I needed to know, and the customer service was excellent. I felt reassured knowing I had all the information before buying.',
  },
  {
    reviewer_name: 'Rajat Chaudhry',
    car: 'Mahindra Scorpio',
    description:
      'Wisedrive’s inspection service is fantastic! They provided a detailed report that helped me negotiate a better price. The inspector was knowledgeable and answered all my questions. I’ll definitely use them again for future car purchases.',
  },
];

function Customers() {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [activate, setActivate] = useState(0);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 0,
    variableWidth: true,
    cssEase: 'linear',
    afterChange: (index) => setActiveSlideIndex(index),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          sliderToShow: 0.8,
          variableWidth: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          sliderToShow: 1.3,
        },
      },
    ],
  };
  // let settings = {
  //   arrows: false,
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   responsive: [
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         sliderToShow: 0.8,
  //         variableWidth: false,
  //       },
  //     },
  //     {
  //       breakpoint: 550,
  //       settings: {
  //         sliderToShow: 1.3,
  //       },
  //     },
  //   ],
  // };

  const [userReviews, setUserReviews] = useState([]);
  useEffect(() => {
    // Use static review data here
    const reviews = getRandomObjectsFromArray(reviewsData, 5);
    setUserReviews(reviews);
  }, []);

  // async function getAllReviews() {
  //   try {
  //     const resp = await GetAllReviewsInfo();
  //     if (resp.status === 200) {
  //       const reviews = getRandomObjectsFromArray(
  //         resp.data.response.ReviewMaster,
  //         5
  //       );
  //       setUserReviews(reviews);
  //     }
  //   } catch (error) {
  //     console.error('error getting all reviews', error);
  //   }
  // }

  // useEffect(() => {
  //   getAllReviews();
  // }, []);

  useEffect(() => {
    if (activeSlideIndex === userReviews?.length - 1) setActivate(0);
    else setActivate(activeSlideIndex + 1);
  }, [activeSlideIndex]);
  return (
    <section className="customers">
      <div className="container" width="70% !important">
        <div className="customer-review-header">
          <div>
            <h2>Customer Experiences and Reviews</h2>
            <p style={{ paddingTop: '0px', marginBottom: '15px' }}>
              See How Our Expertise Ensures Confidence in Every Pre-Owned
              Vehicle Purchase
            </p>
          </div>
          <div className="customer-review-header-right">
            <img src={googleIcon} width="100px" alt="google-icon" />
            <img src={ratingIcon} className="img-rating" alt="rating-icon" />
          </div>
        </div>
        <Slider {...settings}>
          {userReviews.map((review, index) => (
            <div className="customer-card-wraper" key={index}>
              <div
                className={
                  index === activate
                    ? 'customer-card customer-card-active'
                    : 'customer-card'
                }
              >
                <div className="customer-card-profile">
                  <p>{review?.description}</p>
                  <span
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '2rem',
                    }}
                  >
                    <div>
                      <h5>{review?.reviewer_name}</h5>
                      {/* <label>Maruti swift</label> */}
                      {/* <label>{formatDate(review.date_of_review)}</label> */}
                    </div>
                    <Rating
                      name="half-rating-read"
                      defaultValue={5}
                      precision={0.5}
                      style={{ color: '#0c286a' }}
                      readOnly
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <div className="customer-review-footer">
          <div>
            <Button
              variant="contained"
              endIcon={<img src={gIcon} width="50px"></img>}
              sx={{
                width: '100%',
                height: '60px',
                fontSize: '24px',
                backgroundColor: '#eff2ff',
                textTransform: 'none',
                fontFamily: 'matterThin !important',
                color: '#0c286a',
                boxShadow: 'none',
              }}
              className="reviews-on-Google"
            >
              1500+ reviews on Google
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Customers;
