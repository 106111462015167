import { useContext, useEffect, useMemo, useState } from "react";
import WiseDriveLogoNew from "../../assets/images/header/wiseDrivelogoNew.png";
import "./SampleReportNew.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MobileStepper,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Check,
  Close,
  Commit,
  DescriptionOutlined,
  ExpandMore,
  InfoOutlined,
  Launch,
  LocalPoliceOutlined,
  NavigateBefore,
  NavigateNext,
  TireRepairOutlined,
} from "@mui/icons-material";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import chennai from "../../assets/icons/chennai.png";
import hyderabad from "../../assets/icons/hyderabad.png";
import mumbai from "../../assets/icons/mumbai.png";
import delhi from "../../assets/icons/delhi.png";
import pune from "../../assets/icons/Pune.webp";
import bangalore from "../../assets/icons/bangalore.png";
import noida from "../../assets/icons/Noida.png";
import gurguaon from "../../assets/icons/gurguaon.png";
import { PortalContext } from "../../context/carPortalContext";

const Header = ({ regNo }) => {
  return (
    <div className="header">
      <div className="header__container">
        <div className="header__logo">
          <img
            src={WiseDriveLogoNew}
            alt="Orange Health Labs"
            className="header__logo-img"
          />
        </div>
        <div className="header__number">
          <p>{regNo?.toUpperCase()}</p>
        </div>
      </div>
    </div>
  );
};

const SummaryCard = ({ handleOpenModal, summaryData, inspectionDetails }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const rating = 5;

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="summary-card-container">
      {/* Blue Background Container */}
      <div className="summary-card__background">
        {/* Summary Card */}
        <div className="summary-card">
          <div className="summary-card__top-bulge"></div>
          <div className="summary-card__content">
            {/* Customer Information */}
            <h2 className="summary-card__customer-name">
              {summaryData?.customerName ?? "-"}
            </h2>
            <p className="summary-card__customer-info">
              {summaryData?.inspectionDetails?.inspectionDate ?? "-"} |{" "}
              {summaryData?.inspectionDetails?.location ?? "-"}
            </p>

            <p
              className="summary-card__customer-contact"
              style={{ fontWeight: 600 }}
            >
              {summaryData?.carRegistrationNumber?.toUpperCase() ?? "-"}
              {/* <button
                className="summary-card__full-details"
                onClick={handleOpenModal}
              >
                <Launch fontSize="small" />
              </button> */}
            </p>

            {/* Divider */}
            <div className="summary-card__divider"></div>

            {/* Report Details */}
            <div className="summary-card__details">
              <div className="detail-row-report">
                <div className="detail-row">
                  <span className="detail-label">Report for</span>
                  <span className="detail-value">
                    {summaryData?.inspectionDetails?.packageName ?? "-"}
                  </span>
                </div>

                <div
                  className="detail-row side-by-side"
                  onClick={handleOpenDialog}
                  style={{ cursor: "pointer", color: "#003cc5" }}
                >
                  <span className="detail-label">Inspected by</span>
                  <span className="detail-value" style={{ color: "#003cc5" }}>
                    Wisedrive
                  </span>
                </div>
              </div>
              <div className="detail-row-market full-width">
                <span className="detail-label">Market Value</span>
                <span
                  className="detail-value badge-highlight"
                  style={{ fontWeight: 800, fontSize: "16px" }}
                >
                  {summaryData?.marketValue}
                </span>
              </div>
              <div className="detail-row-overall">
                <div className="detail-row">
                  <span className="detail-label">Overall Rating</span>
                  <span
                    className="detail-value overall-rating"
                    style={{
                      color:
                        summaryData?.overallRating <= 4
                          ? "red"
                          : summaryData?.overallRating <= 7
                          ? "orange"
                          : "green", // Dynamic color based on rating
                      fontWeight: "bold",
                    }}
                  >
                    <strong>{summaryData?.overallRating}</strong> / 10
                  </span>
                </div>

                <div className="detail-row">
                  <span className="detail-label">Recommended to buy</span>
                  <span
                    className="detail-value badge-no"
                    style={{
                      backgroundColor:
                        summaryData?.recommendedToBuy === "Yes"
                          ? "#ccf0e1"
                          : "#fee2e2",
                      color:
                        summaryData?.recommendedToBuy === "Yes"
                          ? "#065f46"
                          : "#b91c1c",
                    }}
                  >
                    {summaryData?.recommendedToBuy}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          fullWidth
          maxWidth="xs"
          PaperProps={{
            sx: {
              borderRadius: "16px",
            },
          }}
        >
          <DialogTitle sx={{ color: "#003cc5" }}>
            Inspection Details
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ paddingBottom: "30px" }}>
            <Grid container spacing={2}>
              {/* Row 1 */}
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4b5563",
                    fontSize: "0.9rem",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Requested Date:
                </Typography>
                <Box display="flex" alignItems="end">
                  <Typography
                    variant="body1"
                    sx={{
                      paddingTop: "4px !important",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    {inspectionDetails?.inspectionRequestedDate}
                  </Typography>
                  {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4b5563",
                    fontSize: "0.9rem",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Inspection Date:
                </Typography>
                <Box display="flex" alignItems="end">
                  <Typography
                    variant="body1"
                    sx={{
                      paddingTop: "4px !important",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    {inspectionDetails?.inspectionDate}
                  </Typography>
                  {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4b5563",
                    fontSize: "0.9rem",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Mechanic Name:
                </Typography>
                <Box display="flex" alignItems="end">
                  <Typography
                    variant="body1"
                    sx={{
                      paddingTop: "4px !important",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    {inspectionDetails?.mechanicName}
                  </Typography>
                  {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                </Box>
              </Grid>

              {/* Row 2 */}

              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 500,
                    color: "#4b5563",
                    fontSize: "0.9rem",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  Location (city):
                </Typography>
                <Box display="flex" alignItems="end">
                  <Typography
                    variant="body1"
                    sx={{
                      paddingTop: "4px !important",
                      color: "#000",
                      fontSize: "15px",
                      fontWeight: 700,
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    {inspectionDetails?.location}
                  </Typography>
                  {/* <Check sx={{ color: "green", marginLeft: "8px" }} /> */}
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

const VehicleInformation = ({ vehicleInfo }) => {
  return (
    <div className="key-information-container" style={{ margin: "0px" }}>
      <h3 className="vehicle-information__title">Vehicle Information</h3>
      <div className="vehicle-information__grid">
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Make:</span>
          <span className="vehicle-info-value">{vehicleInfo?.make}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Owners:</span>
          <span className="vehicle-info-value highlighted">
            {vehicleInfo?.owners}
          </span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Model:</span>
          <span className="vehicle-info-value">{vehicleInfo?.model}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Transmission:</span>
          <span className="vehicle-info-value">
            {vehicleInfo?.transmission}
          </span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Year:</span>
          <span className="vehicle-info-value">{vehicleInfo?.mfgDate}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Reg No:</span>
          <span className="vehicle-info-value">{vehicleInfo?.regNo}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Mfg. Date:</span>
          <span className="vehicle-info-value">{vehicleInfo?.mfgDate}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Fuel:</span>
          <span className="vehicle-info-value">{vehicleInfo?.fuel}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Colour:</span>
          <span className="vehicle-info-value">{vehicleInfo?.colour}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Reg. Date:</span>
          <span className="vehicle-info-value">{vehicleInfo?.regDate}</span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Engine CC:</span>
          <span className="vehicle-info-value">
            {vehicleInfo?.engineCC ?? "-"}
          </span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Engine No:</span>
          <span className="vehicle-info-value">
            {vehicleInfo?.engineNumber ?? "-"}
          </span>
        </div>
        <div className="vehicle-info-item">
          <span className="vehicle-info-label">Chassis No:</span>
          <span className="vehicle-info-value">
            {vehicleInfo?.chassisNumber ?? "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

const VehicleInformationModal = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "12px", // Set border radius here
          overflow: "hidden", // Helps ensure the rounded corners are visible
        },
      }}
    >
      <DialogTitle
        className="vehicle-information__title"
        sx={{ fontFamily: "Manrope, sans-serif", color: "#003cc5" }}
      >
        Vehicle Information
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: 0, // Remove default padding
          marginBottom: "20px",
        }}
      >
        <VehicleInformation />
      </DialogContent>
    </Dialog>
  );
};

const InspectionSummary = ({ summary, vehicleInfo }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const text =
    "";
  const previewLimit = 200;
  const shouldShowViewMore = summary?.length > previewLimit;
  return (
    <div className="inspection-summary-container">
      {/* Inspection Summary Section */}
      <div className="inspection-summary">
        <h3 className="inspection-summary__title">
          <Commit color="disabled" /> Inspection Summary{" "}
          <Commit color="disabled" />
        </h3>
        <p className="inspection-summary__checkpoints">
          200+ checkpoints inspected
        </p>
      </div>
      <VehicleInformation vehicleInfo={vehicleInfo} />
      {/* Interpretations Section */}
      <div className="interpretations">
        <h4 className="interpretations__title">Assessment</h4>
        <p className="interpretations__text">
          {isExpanded || !shouldShowViewMore
            ? summary
            : `${text.slice(0, previewLimit)}...`}
          {shouldShowViewMore && (
            <span
              onClick={() => setIsExpanded(!isExpanded)}
              style={{ color: "#003cc5", cursor: "pointer" }}
            >
              &nbsp;{isExpanded ? "Show Less" : "View More"}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

const EstimateCard = ({ title, price, handleOpenModal }) => (
  <Card
    sx={{
      display: "flex",
      justifyContent: "space-around",
      alignItems: "flex-start",
      padding: "16px 8px",
      margin: "16px 8px",
      boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
      minWidth: "150px",
      borderRadius: "8px",
      position: "relative",
    }}
    onClick={handleOpenModal}
    className="estimate-card"
  >
    <CardContent
      sx={{
        display: "flex",
        padding: "2px 12px 24px",
      }}
      className="estimate-card-content"
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          fontFamily: "Manrope, sans-serif",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 800,
          color: parseInt(price.replace("Rs ", "")) >= 5000 ? "red" : "#000",
          fontFamily: "Manrope, sans-serif",
        }}
      >
        {price}
      </Typography>
    </CardContent>
    <Button
      variant="contained"
      onClick={handleOpenModal}
      sx={{
        textTransform: "none",
        backgroundColor: "#003cc5",
        alignSelf: "end",
        color: "#fff",
        fontWeight: 700,
        fontSize: "12px",
        padding: "4px 6px",
        borderRadius: "8px 0px",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        marginTop: "16px",
        fontFamily: "Manrope, sans-serif",
        "&:hover": {
          backgroundColor: "#003cc5",
        },
      }}
      className="view-estimate-button"
    >
      View Estimate
    </Button>
  </Card>
);

const EstimateModal = ({ open, handleClose, estimates, title }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: "16px", // Set border radius for consistency
          padding: "16px",
        },
      }}
    >
      <DialogTitle sx={{ color: "#003cc5" }}>
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="estimate_model_content" sx={{ padding: 0 }}>
        {estimates.map((estimate, index) => (
          <Box
            key={index}
            display="flex"
            alignItems="center"
            py={2}
            borderBottom={
              index === estimates.length - 1 ? "none" : "1px solid #e0e0e0"
            }
          >
            {/* Icon: Labour or Spare Parts */}
            <Box
              sx={{
                width: "52px", // Fixed width
                height: "52px", // Fixed height
                minWidth: "52px", // Ensures the width doesn't shrink
                backgroundColor: "#f5f5f5",
                borderRadius: "50%",
                fontFamily: "Manrope, sans-serif",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // marginRight: "16px",
                flexShrink: 0, // Prevent shrinking in flex layout
                "@media (max-width: 468px)": {
                  width: "52px !important", // Adjusted width for mobile
                  height: "52px", // Adjusted height for mobile
                  minWidth: "52px", // Ensure minimum width doesn't shrink
                },
              }}
              className="estimate_icon"
            >
              {estimate.category === "Labour" ? (
                <img
                  src="https://img.icons8.com/?size=100&id=BDXhhTXkmeSR&format=png&color=000000"
                  height={"30px"}
                />
              ) : (
                <img
                  src="https://img.icons8.com/?size=100&id=zrMKeqZpbojb&format=png&color=000000"
                  height={"30px"}
                />
              )}
            </Box>

            {/* Details: Description, Amount, and Type */}
            <Box>
              {/* Description */}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  color: "#333333",
                  fontFamily: "Manrope, sans-serif",
                  padding: 0,
                  fontSize: "14px",
                }}
              >
                {estimate.title}
              </Typography>

              {/* Cost and Charge Type */}
              <Box display="flex" alignItems="center">
                {/* Cost in Green */}
                <Typography
                  variant="body2"
                  sx={{
                    color: "#000",
                    fontWeight: 600,
                    marginRight: "8px",
                    paddingTop: "2px",
                    fontFamily: "Manrope, sans-serif",
                    fontSize: "16px",
                  }}
                >
                  {`${estimate.price}`}
                </Typography>
                {/* Labour or Spare Parts Label in Grey */}
                <Typography
                  variant="body2"
                  sx={{
                    color: "#757575",
                    fontFamily: "Manrope, sans-serif",
                    paddingTop: "2px",
                  }}
                >
                  {estimate.category === "Labour"
                    ? "Labour Charge"
                    : "Spare Parts"}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const InsuranceDetailsModal = ({ open, onClose, insuranceDetails }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xs"
    PaperProps={{
      sx: {
        borderRadius: "16px",
      },
    }}
  >
    <DialogTitle sx={{ color: "#003cc5" }}>
      Insurance Details
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent sx={{ marginBottom: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6} className="insurance-model">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontFamily: "Manrope, sans-serif",
              color: "#4b5563",
              fontSize: "0.9rem",
            }}
          >
            Insurer:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: "Manrope, sans-serif",
              color: "#000",
              fontSize: "15px",
            }}
          >
            {insuranceDetails.insurer}
          </Typography>
        </Grid>
        <Grid item xs={6} className="insurance-model">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontFamily: "Manrope, sans-serif",
              color: "#4b5563",
              fontSize: "0.9rem",
            }}
          >
            Policy Number:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: "Manrope, sans-serif",
              color: "#000",
              fontSize: "15px",
            }}
          >
            {insuranceDetails.policyNumber}
          </Typography>
        </Grid>
        <Grid item xs={6} className="insurance-model">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontFamily: "Manrope, sans-serif",
              color: "#4b5563",
              fontSize: "0.9rem",
            }}
          >
            Expiry Date:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: "Manrope, sans-serif",
              color: "#000",
              fontSize: "15px",
            }}
          >
            {insuranceDetails.expiryDate}
          </Typography>
        </Grid>
        <Grid item xs={6} className="insurance-model">
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontFamily: "Manrope, sans-serif",
              color: "#4b5563",
              fontSize: "0.9rem",
            }}
          >
            Expires In (days):
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: "Manrope, sans-serif",
              // color: "red",
              fontSize: "15px",
            }}
          >
            {insuranceDetails.expiresIn}
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);

const DentsModal = ({ open, onClose, images, dentsData }) => {
  const formatDetail = (detail, type) => {
    // Check if detail includes "Dents" or "Scratches"
    const hasDentsOrScratches = detail && /dents|scratches/i.test(detail);
    return detail ? (
      <span>
        <span style={{ fontWeight: "bold" }}>{detail}</span>
        {!hasDentsOrScratches && ` ${type}`}
      </span>
    ) : (
      `No ${type}`
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ color: "#003cc5" }}>
        Dent Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "30px" }}>
        <Grid container spacing={2}>
          {dentsData ? (
            Object.entries(dentsData).map(([panel, details]) => {
              if (panel !== "dents") {
                // Exclude the general 'dents' flag
                return (
                  <Grid item xs={6} key={panel}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        color: "#4b5563",
                        fontSize: "0.9rem",
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {panel.replace(/-/g, " ")}:
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#000",
                          fontSize: "15px",
                          fontWeight: 700,
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        {formatDetail(details.Dents, "dents")}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#000",
                          fontSize: "15px",
                          fontWeight: 700,
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        {formatDetail(details.Scratches, "scratches")}
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            })
          ) : (
            <Grid item xs={12}>
              <Typography>No dent details available.</Typography>
            </Grid>
          )}
        </Grid>
        {/* <Box sx={{ marginTop: "16px" }}>
          <ImageGallery
            items={images.filter((image) => image && image.original)}
            showThumbnails={false}
            showPlayButton={false}
            showFullscreenButton={false}
            thumbnailPosition="bottom"
            showNav={true}
            slideInterval={2000}
            renderItem={(item, index) => (
              <Box position="relative">
                <img
                  src={
                    item.original ||
                    "https://via.placeholder.com/600x300?text=No+Image+Available"
                  }
                  alt={`Tyre ${index}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                    objectFit: "cover",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "8px",
                    right: "16px",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    color: "#fff",
                    padding: "4px 8px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    fontFamily: "Manrope, sans-serif",
                  }}
                >
                  {item.description || `Tyre ${index + 1}`}
                </Typography>
              </Box>
            )}
          />
        </Box> */}
      </DialogContent>
    </Dialog>
  );
};

const KeyInformation = ({ keyInfo, insurance }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [isTyreLifeModalOpen, setIsTyreLifeModalOpen] = useState(false);
  const [isDentsModalOpen, setIsDentsModalOpen] = useState(false);
  const [openInsuranceModal, setOpenInsuranceModal] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const calculateInsuranceExpiry = (expiryDate) => {
    const today = new Date();
    const expirationDate = new Date(expiryDate);
    const diffTime = expirationDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays < 0 ? (
      <span style={{ color: "red" }}>
        Expired {Math.abs(diffDays)} days ago
      </span>
    ) : (
      <span style={{ color: "green" }}>Expires in {diffDays} days</span>
    );
  };

  const insuranceStatus = useMemo(() => {
    const today = new Date();
    const expiry = new Date(insurance?.expiryDate);
    return expiry >= today ? "Valid" : "Expired";
  }, [insurance?.expiryDate]);

  const insuranceDetails = {
    insurer: insurance?.insurer || "Not Available",
    policyNumber: insurance?.policyNumber || "Not Available",
    expiryDate: insurance?.expiryDate || "Not Available",
    expiresIn: calculateInsuranceExpiry(insurance?.expiryDate),
  };

  const handleOpenTyreLifeModal = () => {
    setIsTyreLifeModalOpen(true);
  };

  const handleCloseTyreLifeModal = () => {
    setIsTyreLifeModalOpen(false);
  };

  const handleOpenDentsModal = () => {
    setIsDentsModalOpen(true);
  };

  const handleCloseDentsModal = () => {
    setIsDentsModalOpen(false);
  };

  const handleOpenInsuranceModal = () => {
    setOpenInsuranceModal(true);
  };

  const handleCloseInsuranceModal = () => {
    setOpenInsuranceModal(false);
  };

  const majorRepairs = [
    {
      type: "Labour",
      description: "Engine Misfiring,Need to Check Head / Spark Plug",
      cost: "INR 2000",
    },
    {
      type: "Spare",
      description: "Brake Replacement,Change front and rear brake pads",
      cost: "INR 3000",
    },
  ];

  const minorRepairs = [
    {
      type: "Spare",
      description: "AC Filter Cleaning,Clean AC filter for better airflow",
      cost: "INR 500",
    },
    {
      type: "Labour",
      description: "Windshield Wiper Replacement",
      cost: "INR 300",
    },
  ];

  const handleOpenModal = (repairType) => {
    if (repairType === "Major Repairs") {
      setSelectedEstimate(keyInfo?.majorRepair);
      setModalTitle("Major Repairs Estimate");
    } else if (repairType === "Minor Repairs") {
      setSelectedEstimate(keyInfo?.minorRepair);
      setModalTitle("Minor Repairs Estimate");
    }
    setOpenModal(true);
  };
  const majorRepairAmount = 7000;

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const images = [
    {
      original: "https://via.placeholder.com/600x300",
      description: "Front-Left",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Front-Right",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Rear-Left",
    },
    {
      original: "https://via.placeholder.com/600x300",
      description: "Rear-Right",
    },
  ];

  const averageTyreLife = useMemo(() => {
    if (!keyInfo?.tyreLife) return "-"; // Return "-" if tyreLife data is missing
    const tyreLifeValues = Object.values(keyInfo.tyreLife)
      .map((value) => parseInt(value.replace("%", ""), 10))
      .filter((value) => !isNaN(value)); // Filter out invalid values

    if (tyreLifeValues.length === 0) return "-"; // Return "-" if no valid values are found

    const totalLife = tyreLifeValues.reduce((sum, value) => sum + value, 0);
    return `${Math.round(totalLife / tyreLifeValues.length)}%`; // Calculate and return the average
  }, [keyInfo?.tyreLife]);

  const calculateTotal = (repairs) =>
    repairs?.reduce(
      (acc, repair) =>
        acc + (parseFloat(repair?.price.replace(/[^\d.-]/g, "")) || 0),
      0
    );
  // const totalMajorRepairs = useMemo(
  //   () => calculateTotal(keyInfo?.majorRepair),
  //   [majorRepairs]
  // );
  // const totalMinorRepairs = useMemo(
  //   () => calculateTotal(keyInfo?.minorRepair),
  //   [minorRepairs]
  // );

  const totalMajorRepairs = useMemo(() => {
    const total = calculateTotal(keyInfo?.majorRepair);
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0, // Optional: to avoid decimals
    }).format(total);
  }, [majorRepairs]);
  
  const totalMinorRepairs = useMemo(() => {
    const total = calculateTotal(keyInfo?.minorRepair);
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      maximumFractionDigits: 0, // Optional: to avoid decimals
    }).format(total);
  }, [minorRepairs]);
  

  const hasDents = keyInfo?.dentsOnBody?.dents === "YES";
  const hasDentDetails = Object.entries(keyInfo?.dentsOnBody).some(
    ([_, details]) => details.Dents || details.Scratches
  );

  const handleInfoDialogOpen = () => {
    if (keyInfo?.majorAccident === "NO") {
      setInfoDialogOpen(true);
    }
  };
  const handleInfoDialogClose = () => setInfoDialogOpen(false);

  return (
    <>
      <div className="key-information-container">
        <h3 className="key-information__title">Key Information</h3>
        <div className="key-information__grid">
          <div className="vehicle-info-item">
            <span className="vehicle-info-label">Kms Driven</span>
            <span className="vehicle-info-value highlighted">
              {keyInfo?.kmsDriven}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Major Accident</span>

            <span
              className="key-info-value"
              style={{
                textDecoration:
                  keyInfo?.majorAccident === "NO" ? "underline" : "",
                display: "flex",
                alignItems: "center",
              }}
            >
              {keyInfo?.majorAccident}{" "}
              {keyInfo?.majorAccident === "NO" && (
                <Tooltip
                  title="Click for more information"
                  onClick={handleInfoDialogOpen}
                >
                  <span
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginLeft: "4px", // Adjust spacing for the star
                      display: "flex",
                    }}
                  >
                    <InfoOutlined style={{ fontSize: "20px" }} />
                  </span>
                </Tooltip>
              )}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Flood Damage</span>
            <span className="key-info-value">{keyInfo?.floodDamage}</span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Engine Condition</span>
            <span className="key-info-value highlighted-yellow">
              {keyInfo?.engineCondition}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Transmission</span>
            <span className="key-info-value highlighted-yellow">
              {keyInfo?.transmission}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Exterior Condition</span>
            <span className="key-info-value highlighted-red">
              {keyInfo?.exteriorCondition}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Interior Condition</span>
            <span className="key-info-value highlighted-red">
              {keyInfo?.interiorCondition}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Dents & Scratches</span>
            <span className="key-info-value highlighted-red">
              {hasDents || hasDentDetails ? "YES" : "-"}{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                  alignItems: "baseline",
                }}
                onClick={handleOpenDentsModal}
              >
                {/* <TireRepairOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Tyre Life</span>
            <span
              className="key-info-value"
              style={{ display: "flex", gap: "5px", alignItems: "baseline" }}
            >
              {averageTyreLife}{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                  alignItems: "baseline",
                }}
                onClick={handleOpenTyreLifeModal}
              >
                {/* <TireRepairOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Insurance</span>
            <span
              className="key-info-value highlighted-red"
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "baseline",
                color: insuranceStatus === "Valid" ? "#388e3c" : "#d32f2f",
                cursor: "pointer",
              }}
            >
              {insuranceStatus}
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                  alignItems: "baseline",
                }}
                onClick={handleOpenInsuranceModal}
              >
                {/* <TireRepairOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Major Repair</span>
            <span
              className="key-info-value"
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "baseline",
                color: "#000",
                fontWeight: "600",
              }}
            >
              {`INR ${totalMajorRepairs}`}{" "}
              {keyInfo?.majorRepair.length > 0 && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#003cc5",
                    textDecoration: "underline",
                  }}
                  onClick={() => handleOpenModal("Major Repairs")}
                >
                  {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                  Estimate
                </span>
              )}
            </span>
            {/* Implement Major and Minor Functionality here and add */}
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Minor Repair</span>
            <span
              className="key-info-value"
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "baseline",
                fontWeight: "600",
              }}
            >
              {`INR ${totalMinorRepairs}`}{" "}
              {keyInfo?.minorRepair.length > 0 && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "#003cc5",
                    textDecoration: "underline",
                  }}
                  onClick={() => handleOpenModal("Minor Repairs")}
                >
                  {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                  Estimate
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
      <EstimateModal
        open={openModal}
        handleClose={handleCloseModal}
        estimates={selectedEstimate}
        title={modalTitle}
      />
      <InsuranceDetailsModal
        open={openInsuranceModal}
        onClose={handleCloseInsuranceModal}
        insuranceDetails={insuranceDetails}
      />
      <DentsModal
        open={isDentsModalOpen}
        onClose={handleCloseDentsModal}
        images={images}
        dentsData={keyInfo?.dentsOnBody}
      />
      <Dialog
        open={isTyreLifeModalOpen}
        onClose={handleCloseTyreLifeModal}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle sx={{ color: "#003cc5" }}>
          Tyre Life Details
          <IconButton
            aria-label="close"
            onClick={handleCloseTyreLifeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ paddingBottom: "30px" }}>
          <Grid container spacing={2}>
            {keyInfo?.tyreLife ? (
              Object.entries(keyInfo?.tyreLife).map(
                ([tyre, lifePercentage]) => {
                  if (tyre !== "tyreLife") {
                    return (
                      <Grid item xs={6} key={tyre}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: 500,
                            color: "#4b5563",
                            fontSize: "0.9rem",
                            fontFamily: "Manrope, sans-serif",
                          }}
                        >
                          {tyre.replace(/-/g, " ")}:
                        </Typography>
                        <Box display="flex" alignItems="end">
                          <Typography
                            variant="body1"
                            sx={{
                              paddingTop: "4px !important",
                              color: "#000",
                              fontSize: "15px",
                              fontWeight: 700,
                              fontFamily: "Manrope, sans-serif",
                            }}
                          >
                            {lifePercentage}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  }
                }
              )
            ) : (
              <Grid item xs={12}>
                <Typography>No tyre life data available.</Typography>
              </Grid>
            )}
          </Grid>
          {/* <Box sx={{ marginTop: "16px" }}>
            <ImageGallery
              items={images.filter((image) => image && image.original)}
              showThumbnails={false}
              showPlayButton={false}
              showFullscreenButton={false}
              thumbnailPosition="bottom"
              showNav={true}
              slideInterval={2000}
              renderItem={(item, index) => (
                <Box position="relative">
                  <img
                    src={
                      item.original ||
                      "https://via.placeholder.com/600x300?text=No+Image+Available"
                    }
                    alt={`Tyre ${index}`}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "16px",
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "#fff",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    {item.description || `Tyre ${index + 1}`}
                  </Typography>
                </Box>
              )}
            />
          </Box> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={infoDialogOpen}
        onClose={handleInfoDialogClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Major Accident Information</DialogTitle>
        <DialogContent>
          Based on physical inspection, the mechanic didn’t find any evidence of
          an accident. For further information, please contact the authorized
          service center or insurance provider.
        </DialogContent>
      </Dialog>
    </>
  );
};

const AdditionalInformation = ({ rtoData, additionalChallans }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openHypothecationModal, setOpenHypothecationModal] = useState(false);
  const calculateTotalChallans = (challans) => {
    return challans?.reduce((total, challan) => {
      // Ensure each amount can be converted to a float
      const amount = parseFloat(
        challan?.amount.toString().replace(/Rs\. /, "")
      );
      return total + (isNaN(amount) ? 0 : amount);
    }, 0);
  };
  // const challans = [
  //   {
  //     icon: <LocalPoliceOutlined />,
  //     description: "Over Speeding",
  //     amount: "Rs. 500",
  //     date: "14th Nov 2024",
  //   },
  //   {
  //     icon: <LocalPoliceOutlined />,
  //     description: "Signal Jumping",
  //     amount: "Rs. 300",
  //     date: "15th Nov 2024",
  //   },
  // ];

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenhypothecationModal = () => {
    setOpenHypothecationModal(true);
  };

  const handlehypothecationCloseModal = () => {
    setOpenHypothecationModal(false);
  };
  return (
    <>
      <div className="key-information-container">
        <h3 className="key-information__title">RTO Verification</h3>
        <div className="key-information__grid">
          <div className="key-info-item">
            <span className="key-info-label">Traffic Challans</span>
            <span
              className="key-info-value"
              style={{ display: "flex", gap: "5px", alignItems: "baseline" }}
            >
              Rs.{calculateTotalChallans(additionalChallans) ?? 0}{" "}
              <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                }}
                onClick={handleOpenModal}
              >
                {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label"> Hypothecation</span>
            <span className="key-info-value" style={{ display: "flex", gap: "5px", alignItems: "baseline" }}>
              {rtoData?.hypothecation === "false" ? "No" : "Yes"}
              {rtoData?.hypothecation === 'true' && <span
                style={{
                  fontSize: "12px",
                  color: "#003cc5",
                  textDecoration: "underline",
                }}
                onClick={handleOpenhypothecationModal}
              >
                {/* <DescriptionOutlined
                  fontSize="small"
                  sx={{ marginTop: "4px" }}
                /> */}
                Details
              </span>}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Bank NOC</span>
            <span className="key-info-value highlighted-red">
              {rtoData?.bankNOC ?? "-"}
            </span>
          </div>
          <div className="key-info-item">
            <span className="key-info-label">Blacklist status</span>
            <span
              className="key-info-value highlighted-yellow"
              style={{
                color:
                  rtoData?.blacklistStatus === "false" ? "#1a1a1a" : "#d32f2f",
              }}
            >
              {rtoData?.blacklistStatus === "false" ? "No" : "Yes"}
            </span>
          </div>
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle sx={{ color: "#003cc5" }}>
          Traffic Challans
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {additionalChallans?.length > 0 ? (
            additionalChallans?.map((challan, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                py={2}
                borderBottom={
                  index === additionalChallans?.length - 1
                    ? "none"
                    : "1px solid #e0e0e0"
                }
              >
                {/* Police Icon */}
                <Box
                  sx={{
                    width: "52px",
                    height: "52px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // marginRight: "16px",
                  }}
                >
                  <LocalPoliceOutlined />
                </Box>

                {/* Details: Description, Amount, and Date */}
                <Box>
                  {/* Offense Description */}
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: "#333333",
                      fontFamily: "Manrope, sans-serif",
                      padding: 0,
                      fontSize: "14px",
                    }}
                  >
                    {challan.offenseDetails}
                  </Typography>

                  {/* Amount and Date */}
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#000",
                        fontWeight: 600,
                        marginRight: "8px",
                        fontFamily: "Manrope, sans-serif",
                        fontSize: "16px",
                        padding: 0,
                      }}
                    >
                      {challan.amount}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#757575",
                        fontFamily: "Manrope, sans-serif",
                        padding: 0,
                      }}
                    >
                      {challan.challanDate}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body2">No Challans Found</Typography>
          )}
        </DialogContent>
      </Dialog>
      {rtoData?.financier !== "" && <Dialog
        open={openHypothecationModal}
        onClose={handlehypothecationCloseModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: "16px",
          },
        }}
      >
        <DialogTitle sx={{ color: "#003cc5" }}>
          Financiar Details
        </DialogTitle>
        <DialogContent>
            <Typography variant="subtitle1">{rtoData?.financier !== "" ? rtoData?.financier : 'No Data Available'}</Typography>
        </DialogContent>
      </Dialog>}
    </>
  );
};

const cardsData = [
  { title: "Exterior", score: 6, expectedValue: 6, points: 26 },
  { title: "Interior", score: 8, expectedValue: 8, points: 40 },
  { title: "Engine", score: 1, expectedValue: 7, points: 10 },
  { title: "Suspension", score: 5, expectedValue: 5, points: 20 },
  { title: "Brakes", score: 9, expectedValue: 9, points: 8 },
];

const sampleSteps = [
  {
    text: "Step 1: This is a text-only step. This is a text-only step, This is a text-only step. This is a text-only step,This is a text-only step",
  },
  {
    text: "Step 2: This step contains text with images.",
    images: [
      "https://via.placeholder.com/600x300",
      "https://via.placeholder.com/400x200",
    ],
  },
  {
    text: "Step 3: This step contains a video.",
    video: "https://www.youtube.com/embed/lB0ZTQcws_4?si=OgremihHWhdjFGbB",
  },
];

const tyreImages = [
  "https://via.placeholder.com/600x300",
  "https://via.placeholder.com/400x200",
];

const CollapsibleCard = ({ title, score, expectedValue, points }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  // Generate steps dynamically based on the score
  const steps = Array.from(
    { length: score },
    (_, i) => sampleSteps[i % sampleSteps.length]
  );

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setActiveStep(0); // Reset to the first step when expanding
    }
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const images =
    sampleSteps.images?.map((image) => ({
      original: image,
      thumbnail: image,
    })) || [];

  return (
    <Box className="collapsible-card">
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        // onClick={toggleExpand}
        sx={{ cursor: "pointer" }}
        className="collapsible-card-header"
      >
        <Typography
          className="title"
          sx={{
            color: "#000",
            display: "flex",
            flexDirection: "column",
            fontWeight: 500,
            fontFamily: "Manrope, sans-serif",
          }}
        >
          {title}
          <span style={{ color: "#333", fontWeight: 400 }}>
            {points} Check Points
          </span>
        </Typography>
        <Typography className="score" style={{ lineHeight: "22px" }}>
          <span style={{ fontSize: "12px", color: "#000" }}>Rating</span> <br />
          <span
            style={{
              fontSize: "20px",
              fontWeight: 600,
              color: score <= 4 ? "red" : score <= 7 ? "orange" : "green",
            }}
          >
            {score}
          </span>{" "}
          / 10
        </Typography>
      </Box>

      {/* Collapsible Content */}
      {isExpanded && (
        <Box
          sx={{
            marginTop: "16px",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            borderRadius: "8px",
          }}
        >
          {/* <Stepper
            activeStep={activeStep}
            alternativeLabel
            className="stepper-container"
          >
            {steps.map((_, index) => (
              <Step key={index}>
                <StepLabel />
              </Step>
            ))}
          </Stepper> */}

          <Box className="step-content">
            <Typography variant="body1" className="step-description">
              {steps[activeStep].text}
            </Typography>

            {/* Images Carousel */}
            {images && (
              <ImageGallery
                items={images}
                showThumbnails={false}
                showPlayButton={false}
                showFullscreenButton={false}
                thumbnailPosition="bottom"
                showNav={true}
                slideInterval={2000}
              />
            )}

            {/* Video */}
            {steps[activeStep].video && (
              <Box className="video-container">
                <iframe
                  width="100%"
                  height="300px"
                  src={steps[activeStep].video}
                  title="Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            )}
          </Box>

          {/* Navigation */}
          <MobileStepper
            steps={score}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNextStep}
                disabled={activeStep === steps.length - 1}
              >
                Next
                <NavigateNext />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handlePreviousStep}
                disabled={activeStep === 0}
              >
                <NavigateBefore />
                Back
              </Button>
            }
          />
        </Box>
      )}

      {/* Show More / Show Less Button */}
      {/* <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          onClick={toggleExpand}
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "none",
            padding: "4px 12px",
            backgroundColor: "#398070",
            fontFamily: "Manrope, sans-serif",
            color: "#fff",
            borderRadius: "16px",
            "&:hover": {
              backgroundColor: "#2f6657",
            },
          }}
          className="showButton"
        >
          {isExpanded ? "Show Less" : "Show More"}
        </Button>
      </Box> */}
    </Box>
  );
};

// const HealthReport = () => {
//   const [showFullReport, setShowFullReport] = useState(false);

//   const handleViewFullReport = () => {
//     setShowFullReport(true);
//   };

//   return (
//     <section className="health-report">
//       <div className="report-header">
//         {cardsData.map((card, index) => (
//           <div className="parameter-card" key={card.title}>
//             <CollapsibleCard
//               title={card.title}
//               score={card.score}
//               expectedValue={card.expectedValue}
//               steps={card.steps}
//               points={card.points}
//             />
//           </div>
//         ))}
//         <Button
//           onClick={handleViewFullReport}
//           sx={{
//             fontSize: "14px",
//             fontWeight: 700,
//             textTransform: "none",
//             margin: "20px auto",
//             display: "inline-block",
//             padding: "8px 24px",
//             backgroundColor: "#003cc5",
//             fontFamily: "Manrope, sans-serif",
//             color: "#fff",
//             borderRadius: "12px",
//             "&:hover": {
//               backgroundColor: "#003cc5",
//             },
//           }}
//         >
//           View Full PDF Report
//         </Button>
//       </div>
//     </section>
//   );
// };

const HealthReport = ({ cardData, pdfData }) => {
  const [showFullReport, setShowFullReport] = useState(false);

  const handleViewFullReport = () => {
    // Create a Blob from the base64 PDF data
    const blob = new Blob(
      [Uint8Array.from(atob(pdfData), (c) => c.charCodeAt(0))],
      { type: "application/pdf" }
    );

    // Create a URL for the Blob and open it in a new tab
    const blobUrl = URL.createObjectURL(blob);
    const pdfFileName = "InspectionReport.pdf";
    const pdfUrl = `${blobUrl}#${encodeURIComponent(pdfFileName)}`;
    window.open(pdfUrl, "_blank");

    // Optionally revoke the object URL after some time to release memory
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl);
    }, 5000);
  };

  const cardsData = [
    {
      title: "Exterior Evaluation",
      score: cardData?.exterior_evaluation,
      expectedValue: 6,
      points: 34,
    },
    {
      title: "Headlights and Indicators",
      score: cardData?.head_lights_and_indicators,
      expectedValue: 8,
      points: 20,
    },
    {
      title: "Interior Evaluation",
      score: cardData?.interior_evaluation,
      expectedValue: 7,
      points: 18,
    },
    {
      title: "Infotainment, Electrical Systems, and Accessories",
      score: cardData?.infotainment_electrical_systems_accessories_analysis,
      expectedValue: 5,
      points: 19,
    },
    {
      title: "Engine Health and Diagnostics",
      score: cardData?.engine_health_and_diagnostics,
      expectedValue: 9,
      points: 37,
    },
    {
      title: "Transmission Assessment",
      score: cardData?.transmission_assessment,
      expectedValue: 9,
      points: 11,
    },
    {
      title: "Suspension, Brakes, and Battery",
      score: cardData?.suspension_brakes_and_battery,
      expectedValue: 9,
      points: 22,
    },
    {
      title: "Tires and Tools",
      score: cardData?.tires_and_tools,
      expectedValue: 9,
      points: 18,
    },
  ];

  return (
    <section className="health-report">
      <div className="report-header">
        {cardsData.map((card, index) => (
          <div className="parameter-card" key={card.title}>
            <CollapsibleCard
              title={card.title}
              score={card.score}
              expectedValue={card.expectedValue}
              steps={card.steps}
              points={card.points}
            />
          </div>
        ))}

        <Button
          onClick={handleViewFullReport}
          sx={{
            fontSize: "14px",
            fontWeight: 700,
            textTransform: "none",
            margin: "20px auto",
            display: "inline-block",
            padding: "8px 24px",
            backgroundColor: "#003cc5",
            fontFamily: "Manrope, sans-serif",
            color: "#fff",
            borderRadius: "12px",
            "&:hover": {
              backgroundColor: "#003cc5",
            },
          }}
        >
          View Full Report
        </Button>
      </div>
    </section>
  );
};

// const FaultCodeCard = ({ title, errorCodes }) => {
//   const [isExpanded, setIsExpanded] = useState(false);

//   const toggleExpand = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <Box className="collapsible-card-obg">
//       {/* Card Header */}
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         onClick={toggleExpand}
//         sx={{ cursor: "pointer" }}
//         className="collapsible-card-header_OBD"
//       >
//         <Typography
//           className="title"
//           sx={{
//             fontWeight: 500,
//             color: "#333",
//             fontFamily: "Manrope, sans-serif",
//           }}
//         >
//           {title}
//         </Typography>
//         <Box
//           display="flex"
//           alignItems="center"
//           className="collapsible-card-header_OBD_errors"
//         >
//           {errorCodes.length > 0 && (
//             <Typography
//               sx={{
//                 fontWeight: 500,
//                 color: "red",
//                 marginRight: "8px",
//                 fontFamily: "Manrope, sans-serif",
//               }}
//             >
//               {errorCodes.length} errors found
//             </Typography>
//           )}
//           {/* {errorCodes.length > 0 && (
//             <Button
//               onClick={toggleExpand}
//               sx={{
//                 fontSize: "12px",
//                 textTransform: "none",
//                 padding: "4px 8px",
//                 borderRadius: "16px",
//                 backgroundColor: "#d32f2f",
//                 color: "#fff",
//                 "&:hover": {
//                   backgroundColor: "#b71c1c",
//                 },
//               }}
//               className="view-fault-button"
//             >
//               {isExpanded ? "Hide Fault Codes" : "View Fault Codes"}
//             </Button>
//           )} */}
//         </Box>
//       </Box>

//       {/* Expandable Content */}
//       {isExpanded && (
//         <Box
//           sx={{
//             marginTop: "16px",
//             padding: "16px",
//             backgroundColor: "#f9f9f9",
//             borderRadius: "8px",
//             transition: "max-height 0.3s ease-in-out",
//           }}
//         >
//           {errorCodes.map((error, index) => (
//             <Box
//               key={index}
//               sx={{
//                 marginBottom: "8px",
//                 padding: "8px",
//                 backgroundColor: "#fff",
//                 border: "1px solid #ddd",
//                 borderRadius: "8px",
//               }}
//               className="fault-details"
//             >
//               <Typography
//                 sx={{
//                   fontWeight: 600,
//                   color: "#d32f2f",
//                   fontFamily: "Manrope, sans-serif",
//                 }}
//               >
//                 {error.code}
//               </Typography>
//               <Typography>{error.description}</Typography>
//             </Box>
//           ))}
//         </Box>
//       )}
//       {errorCodes.length > 0 && (
//         <Box display="flex" justifyContent="flex-end" mt={2}>
//           <Button
//             onClick={toggleExpand}
//             sx={{
//               fontSize: "12px",
//               fontWeight: 700,
//               textTransform: "none",
//               padding: "4px 12px",
//               backgroundColor: "#eb6d474f",
//               position: "absolute",
//               fontFamily: "Manrope, sans-serif",
//               color: "#ff0000",
//               borderRadius: "8px 0",
//               bottom: 0,
//               right: 0,
//               "&:hover": {
//                 backgroundColor: "#eb6d474f",
//               },
//             }}
//             className="showButton"
//           >
//             {isExpanded ? "Hide Fault Codes" : "View Fault Codes"}
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };

const FaultCodeCard = ({ title, initialErrorCodes, isPublished }) => {
  const { errorCodes, addErrorCode } = useContext(PortalContext);
  const [isAdding, setIsAdding] = useState(false);
  const [newPortNumber, setNewPortNumber] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    if (Array.isArray(initialErrorCodes)) {
      initialErrorCodes.forEach((code) => {
        if (
          !errorCodes.some(
            (ec) =>
              ec.description === code.description &&
              ec.portNumber === code.portNumber
          )
        ) {
          addErrorCode(code);
        }
      });
    }
  }, [initialErrorCodes, addErrorCode, errorCodes]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleOpenAdd = () => {
    setIsAdding(true);
  };

  const handleCloseAdd = () => {
    setIsAdding(false);
    setNewPortNumber("");
    setNewDescription("");
  };

  // const handleSaveNewCode = () => {
  //   if (newPortNumber.trim() && newDescription.trim()) {
  //     const newErrorCode = {
  //       portNumber: newPortNumber,
  //       description: newDescription,
  //       module: "Unknown", // Assign to "Unknown" module
  //       possibleCauses: [],
  //       symptoms: [],
  //       solutions: [],
  //       status: "",
  //     };

  //     // Check if the error already exists
  //     const flattenedErrorCodes = Object.values(initialErrorCodes).flat();
  //     const isDuplicate = flattenedErrorCodes.some(
  //       (code) =>
  //         code.portNumber === newErrorCode.portNumber &&
  //         code.description === newErrorCode.description
  //     );

  //     if (!isDuplicate) {
  //       // Add the new error to the "Unknown" module
  //       if (!initialErrorCodes["Unknown"]) {
  //         initialErrorCodes["Unknown"] = [];
  //       }
  //       initialErrorCodes["Unknown"].push(newErrorCode);

  //       setNewPortNumber("");
  //       setNewDescription("");
  //       setIsAdding(false);
  //     } else {
  //       alert("Error code already exists.");
  //     }
  //   }
  // };

  const hasCategoryData = (error) => {
    return (
      (error.possibleCauses && error.possibleCauses.length > 0) ||
      (error.symptoms && error.symptoms.length > 0) ||
      (error.solutions && error.solutions.length > 0)
    );
  };

  const totalErrors = Object.values(initialErrorCodes).flat().length;

  return (
    <Box className="collapsible-card-obg">
      {/* Card Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={toggleExpand}
        sx={{ cursor: "pointer" }}
        className="collapsible-card-header_OBD"
      >
        <Typography
          className="title"
          sx={{
            fontWeight: 500,
            color: "#333",
            fontFamily: "Manrope, sans-serif",
          }}
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          className="collapsible-card-header_OBD_errors"
        >
          <Typography
            sx={{
              fontWeight: 500,
              color: "red",
              marginRight: "8px",
              fontFamily: "Manrope, sans-serif",
            }}
          >
            {totalErrors} errors found
          </Typography>

          {/* {errorCodes.length > 0 && (
            <ButtonA
              onClick={toggleExpand}
              sx={{
                fontSize: "12px",
                textTransform: "none",
                padding: "4px 8px",
                borderRadius: "16px",
                backgroundColor: "#d32f2f",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#b71c1c",
                },
              }}
              className="view-fault-button"
            >
              {isExpanded ? "Hide Fault Codes" : "View Fault Codes"}
            </ButtonA>
          )} */}
        </Box>
      </Box>
      {/* <IconButton
          color="primary"
          sx={{ position: 'absolute', top: '65px', right: '30px', backgroundColor: '#fee2e2', borderRadius: '8px 2px 8px 8px', fontSize: '12px', fontWeight: 700, textTransform: 'none', padding: '4px 12px', fontFamily: 'Manrope, sans-serif', color: '#ff0000' }}
        >
          View Fault Codes
        </IconButton> */}
      {/* {!isPublished && (
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: '20px', right: '30px' }}
          onClick={handleOpenAdd}
        >
          <AddBoxOutlined color="action" fontSize="large" />
        </IconButton>
      )} */}
      {/* Expandable Content */}
      {isExpanded && (
        <Box
          sx={{
            marginTop: "16px",
            // padding: '16px',
            // backgroundColor: '#f9f9f9',
            borderRadius: "8px",
            transition: "max-height 0.3s ease-in-out",
          }}
        >
          {Object.entries(initialErrorCodes).map(([module, errors]) => (
            <Accordion
              key={module}
              sx={{
                mb: 1,
                border: "1px solid #ddd",
                borderRadius: "8px",
                boxShadow: "none",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "#000",
                      fontFamily: "Manrope, sans-serif",
                    }}
                  >
                    Faults in <span style={{ color: "#d32f2f" }}>{module}</span>
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ flexDirection: "column" }}>
                {/* {error.possibleCauses && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>
                      Possible Causes:
                    </Typography>
                    <ol>
                      {error.possibleCauses.map((cause, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}> <span className="mr-2 font-bold text-black">•</span> {`${cause}`}</li>
                      ))}
                    </ol>
                  </>
                )}
                {error.symptoms && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>Symptoms:</Typography>
                    <ol type="1">
                      {error.symptoms.map((symptom, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}><span className="mr-2 font-bold text-black">•</span> {symptom}</li>
                      ))}
                    </ol>
                  </>
                )}
                {error.solutions && (
                  <>
                    <Typography sx={{ fontWeight: 700 }}>Solutions:</Typography>
                    <ol type="1">
                      {error.solutions.map((solution, idx) => (
                        <li key={idx} style={{ paddingBottom: '4px', paddingInlineStart: '0' }}><span className="mr-2 font-bold text-black">•</span> {solution}</li>
                      ))}
                    </ol>
                  </>
                )} */}
                {errors.map((error, index) => (
                  <Accordion
                    key={index}
                    sx={{
                      mb: 2,
                      border: "none",
                      borderRadius: "8px",
                      boxShadow: "none",
                    }}
                  >
                    {/* Port Number Accordion Summary */}
                    <AccordionSummary
                      expandIcon={
                        hasCategoryData(error) ? <ExpandMore /> : null
                      }
                    >
                      <Box>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            color="primary"
                            gutterBottom
                          >
                            {error.portNumber}
                          </Typography>
                          {error.status && (
                            <Typography
                              variant="body2"
                              style={{ color: "#b71c1c", fontSize: "12px" }}
                              className="obdDescE"
                            >
                              {error.status}
                            </Typography>
                          )}
                        </div>

                        <Typography variant="body2" className="obdDescE">
                          {error.description || "No description available."}
                        </Typography>
                      </Box>
                    </AccordionSummary>

                    {/* Port Number Accordion Details */}
                    {hasCategoryData(error) && (
                      <AccordionDetails>
                        {error.possibleCauses?.length > 0 && (
                          <Box mt={1}>
                            <Typography variant="body2" fontWeight="bold">
                              Possible Causes:
                            </Typography>
                            <ul>
                              {error.possibleCauses.map((cause, idx) => (
                                <li
                                  key={idx}
                                  style={{
                                    paddingBottom: "4px",
                                    paddingInlineStart: "0",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span className="mr-2 font-bold text-black">
                                    •
                                  </span>{" "}
                                  {cause}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        )}
                        {error.symptoms?.length > 0 && (
                          <Box mt={1}>
                            <Typography variant="body2" fontWeight="bold">
                              Symptoms:
                            </Typography>
                            <ul>
                              {error.symptoms.map((symptom, idx) => (
                                <li
                                  key={idx}
                                  style={{
                                    paddingBottom: "4px",
                                    paddingInlineStart: "0",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span className="mr-2 font-bold text-black">
                                    •
                                  </span>{" "}
                                  {symptom}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        )}
                        {error.solutions?.length > 0 && (
                          <Box mt={1}>
                            <Typography variant="body2" fontWeight="bold">
                              Solutions:
                            </Typography>
                            <ul>
                              {error.solutions.map((solution, idx) => (
                                <li
                                  key={idx}
                                  style={{
                                    paddingBottom: "4px",
                                    paddingInlineStart: "0",
                                    fontSize: "14px",
                                  }}
                                >
                                  <span className="mr-2 font-bold text-black">
                                    •
                                  </span>{" "}
                                  {solution}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        )}
                      </AccordionDetails>
                    )}
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          onClick={toggleExpand}
          sx={{
            fontSize: "12px",
            fontWeight: 700,
            textTransform: "none",
            padding: "4px 12px",
            backgroundColor: "#eb6d474f",
            position: "absolute",
            fontFamily: "Manrope, sans-serif",
            color: "#ff0000",
            borderRadius: "8px 0",
            bottom: 0,
            right: 0,
            "&:hover": {
              backgroundColor: "#eb6d474f",
            },
          }}
          className="showButton"
        >
          {isExpanded ? "Hide Fault Codes" : "View Fault Codes"}
        </Button>
      </Box>

      {/* <Dialog
        open={isAdding}
        onClose={handleCloseAdd}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: '12px', // Add rounded corners
          },
        }}
      >
        <DialogTitle>Add New OBD-2 Error</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="16px" mt={2}>
            <TextField
              label="Port Number"
              value={newPortNumber}
              onChange={(e) => setNewPortNumber(e.target.value)}
              fullWidth
            />
            <TextField
              label="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              fullWidth
            />
            <TextField
              label="Possible Causes"
              value={newPossibleCauses}
              onChange={(e) => setNewPossibleCauses(e.target.value)}
              fullWidth
            />
            <TextField
              label="Symptoms"
              value={newSymptoms}
              onChange={(e) => setNewSymptoms(e.target.value)}
              fullWidth
            />
            <TextField
              label="Solutions"
              value={newSolutions}
              onChange={(e) => setNewSolutions(e.target.value)}
              fullWidth
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" gap="8px" mt={4}>
            <Button onClick={handleCloseAdd}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveNewCode}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog> */}
    </Box>
  );
};
const RepairsEstimate = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEstimate, setSelectedEstimate] = useState([]);
  const [modalTitle, setModalTitle] = useState("");

  const majorRepairs = [
    {
      type: "Labour",
      description: "Engine Misfiring,Need to Check Head / Spark Plug",
      cost: "INR 2000",
    },
    {
      type: "Spare",
      description: "Brake Replacement,Change front and rear brake pads",
      cost: "INR 3000",
    },
  ];

  const minorRepairs = [
    {
      type: "Spare",
      description: "AC Filter Cleaning,Clean AC filter for better airflow",
      cost: "INR 500",
    },
    {
      type: "Labour",
      description: "Windshield Wiper Replacement",
      cost: "INR 300",
    },
  ];

  const handleOpenModal = (repairType) => {
    if (repairType === "Major Repairs") {
      setSelectedEstimate(majorRepairs);
      setModalTitle("Major Repairs Estimate");
    } else if (repairType === "Minor Repairs") {
      setSelectedEstimate(minorRepairs);
      setModalTitle("Minor Repairs Estimate");
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <EstimateCard
          title="Major Repairs"
          price="Rs 15000"
          handleOpenModal={() => handleOpenModal("Major Repairs")}
        />
        <EstimateCard
          title="Minor Repairs"
          price="Rs 1000"
          handleOpenModal={() => handleOpenModal("Minor Repairs")}
        />
      </Box>

      <EstimateModal
        open={openModal}
        handleClose={handleCloseModal}
        estimates={selectedEstimate}
        title={modalTitle}
      />
    </>
  );
};

const NeoInfo = () => {
  return (
    <article className="neo-info">
      <section className="neo-grid">
        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="28"
              fill="none"
            >
              <path
                fill="url(#phone_svg__a)"
                d="M21.083 3.5c0-1.93-1.644-3.5-3.666-3.5H4.583C2.561 0 .917 1.57.917 3.5v21c0 1.93 1.644 3.5 3.666 3.5h12.834c2.022 0 3.666-1.57 3.666-3.5zm-7.333 21c0 .481-.413.875-.917.875H9.167c-.505 0-.917-.394-.917-.875s.412-.875.917-.875h3.666c.504 0 .917.394.917.875m-9.167-21h12.834V21H4.583z"
              />
              <defs>
                <linearGradient
                  id="phone_svg__a"
                  x1="3.208"
                  x2="22.987"
                  y1="-16.625"
                  y2="24.054"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Easy to Read
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            Made for easy understanding of every detail of your inspection
            report on mobile.
          </span>
        </div>

        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              fill="none"
            >
              <path
                fill="url(#track_svg__a)"
                d="M3 2C3 1.17 2.33.5 1.5.5S0 1.17 0 2v15.75a3.75 3.75 0 0 0 3.75 3.75H22.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5H3.75a.75.75 0 0 1-.75-.75zm19.06 4.06a1.502 1.502 0 0 0-2.124-2.124L15 8.876l-2.69-2.69a1.5 1.5 0 0 0-2.124 0l-5.25 5.25a1.502 1.502 0 0 0 2.123 2.123l4.191-4.186 2.69 2.691a1.5 1.5 0 0 0 2.124 0l6-6z"
              />
              <defs>
                <linearGradient
                  id="track_svg__a"
                  x1="21.273"
                  x2="10.707"
                  y1="-11.969"
                  y2="22.515"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Easy to Track
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            Get complete details of post purchase expenses before buying the
            used car.
          </span>
        </div>

        <div className="neo-grid-item">
          <span className="neo-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
            >
              <path
                fill="url(#search_svg__a)"
                fillRule="evenodd"
                d="M10.736 2.512c-4.436 0-8.03 3.657-8.03 8.166s3.594 8.166 8.03 8.166c4.435 0 8.029-3.657 8.029-8.166s-3.594-8.166-8.03-8.166M.75 10.678C.75 5.067 5.222.52 10.736.52s9.985 4.547 9.985 10.158-4.471 10.158-9.985 10.158S.75 16.29.75 10.678m17.682 7.44a.966.966 0 0 1 1.384 0l3.147 3.2a1.01 1.01 0 0 1 0 1.41.966.966 0 0 1-1.383 0l-3.147-3.201a1.01 1.01 0 0 1 0-1.409"
                clipRule="evenodd"
              />
              <defs>
                <linearGradient
                  id="search_svg__a"
                  x1="20.693"
                  x2="8.086"
                  y1="-12.84"
                  y2="23.161"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#B1B1B1" />
                  <stop offset="1" stopColor="#3C3C3C" />
                </linearGradient>
              </defs>
            </svg>
          </span>
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-800 oui-typography--decoration-none oui-text-grey-100">
            Comprehensive Report
          </span>
          <br />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-500 oui-typography--decoration-none oui-text-grey-100">
            This report is generated using Physical Inspection, AI, OBD2
            Scanning using the latest scanning tools.
          </span>
        </div>
      </section>
    </article>
  );
};

const Availability = () => {
  return (
    <section className="availability">
      <div
        className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-100"
        style={{ color: "#fff" }}
      >
        We are available in
      </div>

      <div className="cities">
        <div className="city">
          <img src={delhi} alt="delhi" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Delhi NCR
          </span>
        </div>

        <div className="city">
          <img src={mumbai} alt="mumbai" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Mumbai
          </span>
        </div>

        <div className="city">
          <img src={hyderabad} alt="hyderabad" loading="lazy" height={48} />
          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Hyderabad
          </span>
        </div>

        <div className="city">
          <img
            src={bangalore}
            alt="bangalore"
            loading="lazy"
            height={48}
            className="icon-white"
          />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Bangalore
          </span>
        </div>
        <div className="city">
          <img src={chennai} alt="chennai" loading="lazy" height={48} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Chennai
          </span>
        </div>
        <div className="city">
          <img
            src={pune}
            alt="pune"
            loading="lazy"
            height={48}
            className="icon-white"
          />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Pune
          </span>
        </div>
        <div className="city">
          <img src={gurguaon} alt="pune" loading="lazy" height={58} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Gurgaon
          </span>
        </div>
        <div className="city">
          <img src={noida} alt="noida" loading="lazy" height={58} />

          <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-12 oui-typography--weight-600 oui-typography--decoration-none oui-text-grey-05">
            Noida
          </span>
        </div>
      </div>
    </section>
  );
};

const BrandInfo = () => {
  return (
    <section className="brand-info">
      <div className="trust-markers">
        <div className="header__logo">
          <img
            src={WiseDriveLogoNew}
            alt="Orange Health Labs"
            className="header__logo-img"
            style={{ height: "80px", width: "230px" }}
          />
        </div>
      </div>

      <div className="download-app-buttons">
        <span className="oui-typography oui-typography--font-family-manrope oui-typography--transform-none oui-typography--size-14 oui-typography--weight-600 oui-typography--decoration-none oui-text-white">
          K No-661/3-1114/3,4,5, 3rd Floor, No.46/4, Novel Tech Park, G B Palya,
          Bangalore, Karnataka - 560068
        </span>
      </div>
    </section>
  );
};

const SampleReportNew = () => {
  const [openModal, setOpenModal] = useState(false);
  const { reportViewData } = useContext(PortalContext);
  const [reportData, setReportData] = useState(reportViewData); // State to store report data
  const [loading, setLoading] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const mergeAndFilterErrors = (additionalErrors, baseErrors) => {
    const allErrors = [...(additionalErrors || []), ...(baseErrors || [])];
    const uniqueErrors = allErrors.filter(
      (error, index, self) =>
        index ===
        self.findIndex(
          (e) =>
            e.portNumber === error.portNumber &&
            e.description === error.description
        )
    );
    return uniqueErrors;
  };

  const processedErrorCodes = mergeAndFilterErrors(
    reportData?.additionalOBDScanningErrors,
    reportData?.obdScanningErrors
  );

  const groupErrorsByModule = (errors) => {
    return errors.reduce((grouped, error) => {
      const moduleName = error.module || "Unknown";
      if (!grouped[moduleName]) {
        grouped[moduleName] = [];
      }
      grouped[moduleName].push(error);
      return grouped;
    }, {});
  };

  const groupedErrorCodes = groupErrorsByModule(processedErrorCodes);

  // useEffect(() => {
  //   const fetchReportData = async () => {
  //     try {
  //       const response = await fetch(
  //         "http://216.48.190.203:8080/apiv2/report/142"
  //       ); // Replace 'your-token' with the actual token
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
  //       const data = await response.json();
  //       setReportData(data); // Store fetched data in state
  //     } catch (error) {
  //       console.error("Error fetching report data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchReportData();
  // }, []);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  // const errorCodes = [...reportData?.obdScanningErrors, ...reportData?.additionalOBDScanningErrors];
  return (
    <>
      <div className="page-wrapper">
        <Header regNo={reportData?.carRegistrationNumber} />
        <SummaryCard
          handleOpenModal={handleOpenModal}
          summaryData={reportData}
          inspectionDetails={reportData?.inspectionDetails}
        />
        <InspectionSummary
          summary={reportData?.summary}
          vehicleInfo={
            reportData?.additionalData
              ? {
                  ...reportData?.vehicleInformation,
                  owners: reportData?.additionalData.ownerCount,
                }
              : reportData?.vehicleInformation
          }
        />
        <VehicleInformationModal
          open={openModal}
          handleClose={handleCloseModal}
        />
        <KeyInformation
          keyInfo={reportData?.keyInformation}
          insurance={reportData?.rtoInformation?.insurance}
        />
        <AdditionalInformation
          rtoData={reportData?.rtoInformation}
          additionalChallans={
            reportData?.additionalChallans?.length > 0
              ? reportData?.additionalChallans
              : reportData?.rtoInformation?.challans
          }
        />
        <FaultCodeCard
          title="OBD-2 Scanning Report"
          initialErrorCodes={groupedErrorCodes}
        />
        {/* <RepairsEstimate /> */}
        <HealthReport
          cardData={
            reportData?.additionalData?.categoryWiseRating ??
            reportData?.aiInsights?.categoryWiseRating
          }
          pdfData={reportData?.pdfReportAttachment}
        />
        <NeoInfo />
        <Availability />
        <BrandInfo />
      </div>
    </>
  );
};

export default SampleReportNew;
