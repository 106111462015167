import { Close } from "@mui/icons-material";
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";

export const DentsModal = ({ open, onClose, images, dentsData }) => {
  const formatDetail = (detail, type) => {
    // Check if detail includes "Dents" or "Scratches"
    const hasDentsOrScratches = detail && /dents|dent|scratches/i.test(detail);
    return detail ? (
      <span>
        <span style={{ fontWeight: 'bold' }}>{detail}</span>
        {!hasDentsOrScratches && ` ${type}`}
      </span>
    ) : (
      `No ${type}`
    );
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ color: "#003cc5" }}>
        Dent and Scratch Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: "30px" }}>
        <Grid container spacing={2}>
          {dentsData ? (
            Object.entries(dentsData).map(([panel, details]) => {
              if (panel !== "dents") {
                // Exclude the general 'dents' flag
                return (
                  <Grid item xs={6} key={panel}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        color: "#4b5563",
                        fontSize: "0.9rem",
                        fontFamily: "Manrope, sans-serif",
                      }}
                    >
                      {panel.replace(/-/g, " ")}:
                    </Typography>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#000",
                          fontSize: "15px",
                          fontWeight: 500,
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        {formatDetail(details.Dents, "Dents")}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#000",
                          fontSize: "15px",
                          fontWeight: 500,
                          fontFamily: "Manrope, sans-serif",
                        }}
                      >
                        {formatDetail(details.Scratches, "Scratches")}
                      </Typography>
                    </Box>
                  </Grid>
                );
              }
            })
          ) : (
            <Grid item xs={12}>
              <Typography>No dent details available.</Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};