import React from 'react';
import './price.css';
import roadsideAssist from '../../assets/numberIcons/roadsideAssist.png';
import cities from '../../assets/numberIcons/cities.png';
import customerSupport from '../../assets/numberIcons/customerSupport.png';
import kilometer from '../../assets/numberIcons/kmDriven.png';
import warranty from '../../assets/numberIcons/warranty.png';
import inspected from '../../assets/numberIcons/inspected.png';

const numberCardData = [
  {
    image: inspected,
    number: '200,000+',
    text: 'Customer across the India have trust us before buying used car',
  },
  {
    image: warranty,
    number: '75,000+',

    text: 'Customer buy our warranty post purchase for hassle free ownership',
  },
  {
    image: kilometer,
    number: '80,00,000+',
    text: ' Kilometeres trave led by customers with Wisedrive warranty ',
  },
  {
    image: cities,
    number: '10+ Cities',
    text: 'With 10+ cities coverage we have 7000+ pincodes covered in India',
  },
  {
    image: customerSupport,
    number: 'Customer Support',
    text: 'Our customer support is available 24/7 for Warranty related queries',
  },
  {
    image: roadsideAssist,
    number: 'Roadside Assistance',
    text: 'Got stuck somewhere, cal our helpline to get roadside assistance in 30min',
  },
];
function Price({ pageData }) {
  return (
    <section className="price-body">
      <div className="container ">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <h2 className="price-header">
            We offer quality,{' '}
            <span style={{ fontFamily: 'matterThin' }}>
              with the best technology and
              <br />
              automotive experts in car inspection
            </span>
          </h2>
          <p className="price-header-p">
            Trusted by 200,000+ customers for inspecting pre-owned cars before
            purchase
          </p>
        </div>
        <div className="numbers-card-wrap">
          {numberCardData.map((data, index) => (
            <div className="numbers-card" key={index}>
              <div className="numbers-card-header">
                <img width="60px" src={data?.image} alt="roadsideAssist" />
                <h5 style={{ color: 'black' }}>{data.number}</h5>
              </div>
              <p
                style={{
                  color: '#112c6d',
                  textAlign: 'left',
                }}
              >
                {data.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Price;
