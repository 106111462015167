import { useEffect, useState } from "react";
import { Button, TextField, Box, MenuItem } from "@mui/material";

const EditSummary = ({ onClose, data, onDataChange }) => {
  const [marketValue, setMarketValue] = useState(data.marketValue);
  const [recommended, setRecommended] = useState(data.recommended);
  const [overallRating, setOverallRating] = useState(data.overallRating);
  // const [marketValueError, setMarketValueError] = useState(false);
  const [overallRatingError, setOverallRatingError] = useState(false);

  useEffect(() => {
    console.log("Updating EditSummary state with new props:", data);
    setMarketValue(data.marketValue);
    setRecommended(data.recommended);
    setOverallRating(data.overallRating);
  }, [data]);

  const handleSave = () => {
    // Logic to save the changes
    // const isMarketValueValid = validateMarketValue(marketValue);
    const isOverallRatingValid =
      overallRating !== "" &&
      Number(overallRating) >= 1 &&
      Number(overallRating) <= 10;
      // setMarketValueError(!isMarketValueValid);
      setOverallRatingError(!isOverallRatingValid);
      if (!isOverallRatingValid) {
        console.error("Validation failed. Correct the errors before saving.");
        return;
      }
    onDataChange({
      marketValue,
      recommended,
      overallRating
    });
    onClose();
  };

  const handleRatingChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) >= 1 && Number(value) <= 10)) {
      setOverallRating(value === "" ? "" : Number(value));
    }
  };

  const formatIndianNumber = (num) => {
    return num.replace(/\D/g, "") // Remove non-numeric characters
      .replace(/(\d)(?=(\d\d)+\d$)/g, "$1,"); // Apply Indian comma formatting
  };

  const handleMarketValueChange = (e) => {
    let value = e.target.value.replace(/,/g, "").replace(/₹/g, "");// Remove existing commas first

    let formattedValue = value
    .split("-") // Split the input by '-'
    .map((part) => part.trim()) // Trim spaces around numbers
    .map((num) => (num ? `₹${formatIndianNumber(num)}` : num)) // Format each number and add ₹
    .join(" - "); // Rejoin with ' - ' '

    setMarketValue(formattedValue);
  };
  // const validateMarketValue = (value) => {
  //   const regex = /^\d{1,3}(,\d{3})*( - \d{1,3}(,\d{3})*)?\s?INR$/;
  //   if (!regex.test(value)) return false;

  //   const [start, end] = value
  //     .replace(/INR/g, "")
  //     .split(" - ")
  //     .map((v) => parseInt(v.replace(/,/g, ""), 10));

  //   return !isNaN(start) && !isNaN(end) && start <= end;
  // };

  return (
    <Box display="flex" flexDirection="column" gap="16px" sx={{paddingTop: "4px"}}>
      <TextField
        label="Market Value"
        value={marketValue}
        onChange={handleMarketValueChange}
        fullWidth
        // error={marketValueError}
        helperText={"Enter a valid range like 3,00,000 - 4,50,000 INR"}
      />
      <TextField
        select
        label="Recommended"
        value={recommended}
        onChange={(e) => setRecommended(e.target.value)}
        fullWidth
      >
        <MenuItem value="Yes">Yes</MenuItem>
        <MenuItem value="No">No</MenuItem>
      </TextField>
      <TextField
        label="Overall Rating"
        type="number"
        value={overallRating}
        error={overallRatingError}
        onChange={handleRatingChange}
        fullWidth
        inputProps={{ min: 1, max: 10 }}
      />
      <Box display="flex" justifyContent="flex-end" gap="8px">
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default EditSummary;
