import React from 'react';
import './flexipay.css';
import imagesHeader from './logosImage.png';
import bestChoice from '../../assets/images/flexiPay/bestChoice.png';
import recomended from '../../assets/images/flexiPay/recommend.png';
import trusted from '../../assets/images/flexiPay/trusted.png';
import { Button, Divider } from '@mui/material';

const FlexiPay = () => {
  return (
    <div className="flexipai-body">
      <section className="container">
        <div className="flexipay-header">
          <h2>
            Customise your Car Warranty with our
            <br /> <span style={{ color: '#ff7858' }}>‘Flexi-Pay’</span>{' '}
            solution ..
          </h2>
          <div className="flexipay-header-right">
            <img src={recomended}></img>
            <img src={bestChoice}></img>
            <img src={trusted}></img>
          </div>
        </div>
        <div className="flexipay-card-wrap">
          <div className="flexi-quarterly">
            <div className="flexi-card-header">
              <h6 style={{ color: 'black' }}>
                Quarterly <br />
                Payment Plan
              </h6>
              <div className="flexi-card-headright">
                <p>Starts from</p>

                <span style={{ fontFamily: 'matter' }}>
                  INR
                  <p
                    style={{
                      fontSize: '22px',
                      fontFamily: 'matterSemibold',
                    }}
                  >
                    1499
                  </p>
                </span>
              </div>
            </div>
            <div className="flexi-card-body">
              <div style={{ textAlign: 'center' }}>
                <p className="card-duration-p">
                  0-3 <br /> Months
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-label-p">Customer</p>
                <p className="card-percent-p">20%</p>
                <p className="card-label-p">Co-Pay</p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-label-p">Wisedrive</p>
                <p className="card-percent-p">80%</p>
                <p className="card-label-p">Payout</p>
              </div>
            </div>
            <Divider className="divider-css" />
            <div className="flexi-card-body">
              <div style={{ textAlign: 'center' }}>
                <p className="card-duration-p">
                  4-6 <br /> Months
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">20%</p>
                <p className="card-label-p">Co-Pay</p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">80%</p>
                <p className="card-label-p">Payout</p>
              </div>
            </div>
            <Divider className="divider-css" />
            <div className="flexi-card-body">
              <div style={{ textAlign: 'center' }}>
                <p className="card-duration-p">
                  7-9 <br /> Months
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">20%</p>
                <p className="card-label-p">Co-Pay</p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">80%</p>
                <p className="card-label-p">Payout</p>
              </div>
            </div>
            <Divider className="divider-css" />
            <div className="flexi-card-body">
              <div style={{ textAlign: 'center' }}>
                <p className="card-duration-p">
                  10-12 <br /> Months
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">20%</p>
                <p className="card-label-p">Co-Pay</p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p className="card-percent-p">80%</p>
                <p className="card-label-p">Payout</p>
              </div>
            </div>
            <Divider className="divider-css" />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  color: 'white',
                  fontSize: '18px',
                  fontFamily: 'matterMedium',
                  margin: '10px',
                  backgroundColor: '#e61f93',
                }}
              >
                Contact Sales
              </Button>
            </div>
          </div>
          <div>
            <div className="flexi-halfCard-wrap">
              <div className="flexi-halfyearly">
                <div className="flexi-card-header">
                  <h6 style={{ color: 'black' }}>
                    6 Months <br />
                    Payment Plan
                  </h6>
                  <div className="flexi-card-headright">
                    <p>Starts from</p>

                    <span style={{ fontFamily: 'matter' }}>
                      INR
                      <p
                        style={{
                          fontSize: '22px',
                          fontFamily: 'matterSemibold',
                        }}
                      >
                        2999
                      </p>
                    </span>
                  </div>
                </div>
                <div className="flexi-card-body">
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-duration-p">
                      0-6 <br /> Months
                    </p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-label-p">Customer</p>
                    <p className="card-percent-p">10%</p>
                    <p className="card-label-p">Co-Pay</p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-label-p">Wisedrive</p>
                    <p className="card-percent-p">90%</p>
                    <p className="card-label-p">Payout</p>
                  </div>
                </div>
                <Divider className="divider-css" />
                <div className="flexi-card-body">
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-duration-p">
                      7-12 <br /> Months
                    </p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-percent-p">00%</p>
                    <p className="card-label-p">Co-Pay</p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-percent-p">100%</p>
                    <p className="card-label-p">Payout</p>
                  </div>
                </div>
                <Divider className="divider-css" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: 'white',
                      fontSize: '18px',
                      fontFamily: 'matterMedium',
                      margin: '10px',
                      backgroundColor: '#950000',
                    }}
                  >
                    Contact Sales
                  </Button>
                </div>
              </div>
              <div className="flexi-yearly">
                <div className="flexi-card-header">
                  <h6 style={{ color: 'black' }}>
                    One Year <br />
                    Payment Plan
                  </h6>
                  <div className="flexi-card-headright">
                    <p>Starts from</p>
                    <span style={{ fontFamily: 'matter' }}>
                      INR
                      <p
                        style={{
                          fontSize: '22px',
                          fontFamily: 'matterSemibold',
                        }}
                      >
                        4999
                      </p>
                    </span>
                  </div>
                </div>
                <div className="flexi-card-body">
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-duration-p">
                      0-12 <br /> Months
                    </p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-label-p">Customer</p>
                    <p className="card-percent-p">00%</p>
                    <p className="card-label-p">Co-Pay</p>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <p className="card-label-p">Wisedrive</p>
                    <p className="card-percent-p">100%</p>
                    <p className="card-label-p">Payout</p>
                  </div>
                </div>
                <Divider className="divider-css" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      color: 'white',
                      fontSize: '18px',
                      fontFamily: 'matterMedium',
                      margin: '10px',
                      backgroundColor: '#202020',
                    }}
                  >
                    Contact Sales
                  </Button>
                </div>
              </div>
            </div>
            <div className="flexi-note">
              <p>
                * Co-Pay is the percentage of amount to be paid by customer for
                warranty parts during the said payment period. Please read the
                warranty conditions at https://wisedrive.co.in
              </p>
              <p>
                ** Towing and handling charges are to be paid separately billed
                based on the distance the car is towed.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FlexiPay;
