import React, { createContext } from 'react';
import { useState } from 'react';
import { countries } from '../utils/countries';
import { IndiaContent } from '../assets/content/indiaContent.js';
import { IndonesiaContent } from '../assets/content/indonesiaContent.js';

export const MyContext = createContext();

const Context = (props) => {
  const [count, setCount] = useState(0);
  const [index, setIndex] = useState(0);
  const [heightAct, setHeightAct] = useState(false);
  const [ownerPlanTabActive, setOwnerPlanTabActive] = useState(0);
  const [country, setCountry] = useState(countries?.[0]?.value);
  const [pageData, setPageData] = useState(IndiaContent);
  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
    if (event.target?.value == 'in') setPageData(IndiaContent);
    else if (event.target?.value == 'id') setPageData(IndonesiaContent);
    else setPageData(IndiaContent);
  };

  return (
    <MyContext.Provider
      value={{
        count,
        setCount,
        index,
        setIndex,
        heightAct,
        setHeightAct,
        ownerPlanTabActive,
        setOwnerPlanTabActive,
        country,
        countries,
        handleChangeCountry,
        pageData,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
export default Context;
