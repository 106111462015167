import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const EditOwnersModal = ({ open, onClose, initialValue, onSave }) => {
    const [editedValue, setEditedValue] = useState(initialValue);
  
    useEffect(() => {
      setEditedValue(initialValue);
    }, [initialValue]);
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          Edit Number of Owners
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            type="number"
            label="Number of Owners"
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            inputProps={{ min: 0, max: 10 }}
            sx={{ mt: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 3 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button 
              variant="contained" 
              onClick={() => onSave(editedValue)}
              sx={{ bgcolor: '#003cc5' }}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };

export default EditOwnersModal;