import React from 'react';
import './queryComponent.css';

import one from '../../assets/images/queryComponent/oneICon.png';
import two from '../../assets/images/queryComponent/twoIcon.png';
import three from '../../assets/images/queryComponent/threeIcon.png';
import headImg from '../../assets/images/queryComponent/headImg.png';
import advisor from '../../assets/images/queryComponent/advisor.png';
// import headImg from './howItWorks.png';
// import one from './one.png';
// import two from './two.png';
// import three from './three.png';
import { Divider } from '@mui/material';

function QueryComponent() {
  return (
    <section className="querySection">
      <div id="how-it-works" className="container">
        <div style={{ marginBottom: '20px' }} className="commonHeading">
          <div className="query-header">
            <div>
              <p className="query-sub-header">How it works ?</p>
              <h2 style={{ color: 'black' }}>
                Buying pre-owned car has never been this{' '}
                <span style={{ color: '#003cc4' }}>Confident</span>!
              </h2>
              <p style={{ color: 'black' }}>
                Send “HI” on whatsapp to 73380 73380 to buy inspection plan,
                book inspection,
                <br /> schedule inspection, reschedule inspection or to talk to
                experts about your car.
                <strong> We are just a “Hi” away to assist you.</strong>
              </p>
            </div>
            <img
              className="how-it-works-img"
              src={headImg}
              alt="how it works"
            ></img>
          </div>
        </div>
        <div className="how-it-works-card-wrap">
          <div className="how-it-works-card">
            <div className="how-it-works-card-head">
              <img className="query-card-image" src={one}></img>
              <h5>Buy Inspection Plan</h5>
            </div>
            <Divider
              style={{
                borderWidth: '2px',
                marginBottom: '1rem',
                marginTop: '1rem',
                borderColor: '#e5e8ee',
              }}
            />
            <div className="how-it-works-card-body">
              <p>Choose the best inspection package for your car.</p>
              <p>
                Buy inspection before you buy the car and guarantee oeace of
                mind post purchase.
              </p>
            </div>
          </div>
          <div className="how-it-works-card">
            <div className="how-it-works-card-head">
              <img className="query-card-image" src={two}></img>
              <h5>Schedule Inspection</h5>
            </div>
            <Divider
              style={{
                borderWidth: '2px',
                marginBottom: '1rem',
                marginTop: '1rem',
                borderColor: '#e5e8ee',
              }}
            />
            <div className="how-it-works-card-body">
              <p>
                Schedule inspection online / Whatsapp or call us on +91 73380
                73380 anytime.
              </p>
              <p>
                You can schedule inspection anywhere in the city hassle free.
              </p>
            </div>
          </div>
          <div className="how-it-works-card">
            <div className="how-it-works-card-head">
              <img className="query-card-image" src={three}></img>
              <h5>Get Report in 2 Hrs</h5>
            </div>
            <Divider
              style={{
                borderWidth: '2px',
                marginBottom: '1rem',
                marginTop: '1rem',
                borderColor: '#e5e8ee',
              }}
            />
            <div className="how-it-works-card-body">
              <p>
                Get your car inspection report in 2 hrs after the completion of
                inspection.
              </p>
              <p>
                We will share the report via a link on whatsapp which you can
                click to view the report.
              </p>
            </div>
          </div>
        </div>
        <div className="advisor-container">
          <img
            src={advisor}
            style={{ width: '90px', height: '90px' }}
            alt="how it works"
          ></img>
          <div>
            <p style={{ color: 'black', fontFamily: 'matter' }}>
              Our experts will advise you on all issues of the car and will
              provide assistance in choosing the right car.
            </p>
            <p
              style={{
                color: '#003cc4',
                paddingTop: '0px',
                fontFamily: 'matterMedium',
              }}
            >
              Buying car after Wisedrive Inspection Guarantees Peace of Mind.
            </p>
          </div>
        </div>
        {/* <div className="query-cards-container">
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img1}></img>
              <h5>Buy Inspection Plan</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>Choose the best inspection package for your car.</p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img2}></img>
              <h5>Schedule Inspection</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Send us "Hi" on{' '}
              <span style={{ fontFamily: 'matterBold' }}>
                Whatsapp 73380 73380{' '}
              </span>{' '}
              or <br />
              call us at{' '}
              <span style={{ fontFamily: 'matterBold' }}>7353 34 35 36 </span>
            </p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img3}></img>
              <h5> Get expert Recommendations</h5>
            </div>
            <Divider
              style={{
                marginBottom: '1rem',
                marginTop: '1.5rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Talk to our in-house experts on repair estimates, best price to
              buy and other options before finalising the car
            </p>
          </div>
          <div className="query-card">
            <div className="query-card-header">
              <img className="query-card-image" src={img4}></img>
              <h5> Buy your dream car</h5>
            </div>
            <Divider
              style={{
                marginTop: '1.5rem',
                marginBottom: '1rem',
                borderColor: '#0c286a',
              }}
            />
            <p>
              Activate Warranty for your dream car and guarantee peace of mind
              post purchase.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default QueryComponent;
