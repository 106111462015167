// countries.js
// export const countries = [
//   { value: 'in', name: 'India', code: 'IN', flag: '🇮🇳' },
//   { value: 'id', name: 'Indonesia', code: 'ID', flag: '🇮🇩' },
//   { value: 'my', name: 'Malaysia', code: 'MY', flag: '🇲🇾' },
//   { value: 'th', name: 'Thailand', code: 'TH', flag: '🇹🇭' },
//   { value: 'au', name: 'Australia', code: 'AU', flag: '🇦🇺' },
//   { value: 'jp', name: 'Japan', code: 'JP', flag: '🇯🇵' },
// ];

import indiaFlag from './flagImages/IN.png';
import indonesiaFlag from './flagImages/ID.png';
import malaysiaFlag from './flagImages/MY.png';
import thailandFlag from './flagImages/TH.png';
import australiaFlag from './flagImages/AU.png';
import japanFlag from './flagImages/JP.png';

// Define country data with local flag images
export const countries = [
  { value: 'in', name: 'India', code: 'IN', flag: '🇮🇳', flagImage: indiaFlag },
  {
    value: 'id',
    name: 'Indonesia',
    code: 'ID',
    flag: '🇮🇩',
    flagImage: indonesiaFlag,
  },
  {
    value: 'my',
    name: 'Malaysia',
    code: 'MY',
    flag: '🇲🇾',
    flagImage: malaysiaFlag,
  },
  {
    value: 'th',
    name: 'Thailand',
    code: 'TH',
    flag: '🇹🇭',
    flagImage: thailandFlag,
  },
  {
    value: 'au',
    name: 'Australia',
    code: 'AU',
    flag: '🇦🇺',
    flagImage: australiaFlag,
  },
  { value: 'jp', name: 'Japan', code: 'JP', flag: '🇯🇵', flagImage: japanFlag },
];

// export const countries = [
//   {
//     value: 'in',
//     name: 'India',
//     code: 'IN',
//     flag: '🇮🇳',
//     flagImage: 'https://flagcdn.com/w40/in.png',
//   },
//   {
//     value: 'id',
//     name: 'Indonesia',
//     code: 'ID',
//     flag: '🇮🇩',
//     flagImage: 'https://flagcdn.com/w40/id.png',
//   },
//   {
//     value: 'my',
//     name: 'Malaysia',
//     code: 'MY',
//     flag: '🇲🇾',
//     flagImage: 'https://flagcdn.com/w40/my.png',
//   },
//   {
//     value: 'th',
//     name: 'Thailand',
//     code: 'TH',
//     flag: '🇹🇭',
//     flagImage: 'https://flagcdn.com/w40/th.png',
//   },
//   {
//     value: 'au',
//     name: 'Australia',
//     code: 'AU',
//     flag: '🇦🇺',
//     flagImage: 'https://flagcdn.com/w40/au.png',
//   },
//   {
//     value: 'jp',
//     name: 'Japan',
//     code: 'JP',
//     flag: '🇯🇵',
//     flagImage: 'https://flagcdn.com/w40/jp.png',
//   },
// ];
