import React, { useEffect, useState } from 'react';
import './BuyNow.css';
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Paper,
  TextField,
  InputAdornment,
  Divider,
  Link,
  Modal,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stepper,
} from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CarInspection from '../../assets/images/payment/carInspection.png';
// import Hyderabad from '../../assets/images/cities/hyderabad.png';
import Hyderabad from '../../assets/icons/cityIcons/charminar.png';
import Bengaluru from '../../assets/icons/cityIcons/vidhana-soudha.png';
import Pune from '../../assets/icons/cityIcons/shaniwar-wada.png';
import Chandigarh from '../../assets/icons/cityIcons/open-hand-monument.png';
import Mumbai from '../../assets/icons/cityIcons/india-gate.png';
import Chennai from '../../assets/icons/cityIcons/chennai-central-railway-station.png';
import Delhi from '../../assets/icons/cityIcons/gateway-of-india.png';
import Lucknow from '../../assets/icons/cityIcons/bara-imambara.png';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import {
  AcceptOnlyAlphabate,
  AcceptOnlyNumber,
} from '../../utils/commonFunctions';
import OtpInput from 'react-otp-input';
import { styled } from '@mui/material/styles';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import axios from 'axios';
import { basicAuth } from '../../Api/crmPortal';
import PaymentForm from '../../components/AffordabilityPayUWidget/AffordabilityWidget';
import payuIcon from '../../assets/images/buyNowPopup/payu.png';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BuyNowModal from '../../components/popups/buyNowModal';
import { scheduleTimeArray } from '../../utils/constants';
import {
  createNewPendingInspection,
  getCarDetailsByCarNo,
} from '../../Api/Apis';
import CustomizedSteppers from '../../components/util-components/stepper';

const StyledTextField = styled(TextField)`
  & label {
    color: black;
  }
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: #003cc4; // - Set the Input border
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: #003cc4; // - Set the Input border when parent has :hover
      border-width: 2px;
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: #003cc4;
      border-width: 2px;
    }
  }
`;

const StyledTextFieldDisabled = styled(TextField)`
  & label {
    color: black;
  }
  & label.Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      // - The <fieldset> inside the Input-root
      border-color: #edebe7 !important; // - Set the Input border
      border-width: 2px;
    }
    &:hover fieldset {
      border-color: #edebe7 !important; // - Set the Input border when parent has :hover
      border-width: 2px;
    }
    &.Mui-focused fieldset {
      // - Set the Input border when parent is focused
      border-color: #edebe7 !important;
      border-width: 2px;
    }
  }
`;

const BuyNowComponent = ({
  selectedValue,
  packageData,
  setStepperVisible,
  inspectionCount,
  setPageDisable,
}) => {
  const location = useLocation();
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState(null);
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [disabelOtpBtn, setDisableotpBtn] = useState(false);
  const [validateName, setValidateName] = useState(false);
  const [validateNumber, setValidateNumber] = useState(false);
  const [numberError, setNumberError] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState();
  const [couponCode, setCouponCode] = useState('');
  const [couponLabel, setCouponLabel] = useState();
  const [isDiscountApplied, setIsDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState();
  const [nameError, setNameError] = useState(false);
  const isButtondisabled =
    name?.trim().length > 0 &&
    phone?.trim()?.length > 0 &&
    nameError &&
    numberError &&
    phone?.trim()?.length == 10;

  const queryParams = new URLSearchParams(location.search);

  const cityIconArray = [
    {
      name: 'Bengaluru',
      icon: Bengaluru,
    },
    {
      name: 'Hyderabad',
      icon: Hyderabad,
    },
    {
      name: 'Pune',
      icon: Pune,
    },
    {
      name: 'Mumbai',
      icon: Mumbai,
    },
    {
      name: 'Delhi',
      icon: Delhi,
    },
    {
      name: 'Chandigarh',
      icon: Chandigarh,
    },
    {
      name: 'Chennai',
      icon: Chennai,
    },
    {
      name: 'Gurgaon',
      icon: Lucknow,
    },
  ];

  const transactionMessage = queryParams.get('TransactionMessage');
  const transactionStatus = queryParams.get('TransactionStatus');
  const transactionID = queryParams.get('TransactionID');
  const bankReferenceNumber = queryParams.get('BankReferenceNumber');
  const inspectionCountRes = queryParams.get('InspectionCount');
  const amountReceived = queryParams.get('AmoutReceived');
  const planName = queryParams.get('InspectionType')
    ? queryParams.get('InspectionType')
    : 'Standard';
  const [selectedCity, setSelectedCity] = useState();
  const [stepperData, setStepperData] = useState();
  const [btnDisable, setbtnDisable] = useState(false);

  const handleSendOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, name: name };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/sendotptolead`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.status == 200) {
        setLoaderOpen(false);
        setDisableotpBtn(false);
        setOtpSent(true);
      }
    } catch (error) {
      setLoaderOpen(false);
      setDisableotpBtn(false);
      console.log('error', error);
    }
  };
  const handleRetryPayment = () => {
    const queryParams = new URLSearchParams({
      userName: name,
      mobileNo: phone,
    });
    navigate(`/plan-details-buy-now?${queryParams.toString()}`);
  };

  const applyDiscount = async (event) => {
    if (isDiscountApplied) resetCouponCode();
    else {
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/couponcode/isvalid?couponCode=${couponCode}`;
      try {
        setIsDiscountApplied(true);
        const couponResponse = await axios.get(apiUrl, basicAuth);
        let discountAmountCalc = 0;
        if (couponResponse?.data?.discountAmount) {
          discountAmountCalc =
            packageData?.costPrice - couponResponse?.data?.discountAmount;
          discountAmountCalc = Math.round(
            discountAmountCalc + (discountAmountCalc * 18) / 100
          );
          setCouponLabel('Coupon Applied Successfully');
          setDiscountAmount(couponResponse?.data?.discountAmount);
          setTotalAmount(discountAmountCalc);
        } else if (couponResponse?.data?.message) {
          setCouponLabel(couponResponse?.data?.message);
          setDiscountAmount(discountAmountCalc);
          setTotalAmount(packageData?.totalPrice);
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const resetCouponCode = () => {
    setIsDiscountApplied(false);
    setDiscountAmount(0);
    setCouponLabel('');
    setCouponCode('');
    setTotalAmount(packageData?.totalPrice);
  };

  const handleNext = () => {
    setLoaderOpen(false);
    setDisableotpBtn(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleSkip = () => {
    setLoaderOpen(false);
    setDisableotpBtn(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 2);
  };

  const handleBack = () => {
    // setPageDisable();
    if (activeStep == 0) {
      setStepperVisible();
      setActiveStep(0);
    } else setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // step 1
  const handleName = (e) => {
    setValidateName(false);
    const value = e.target.value;
    const isValid = AcceptOnlyAlphabate(value);
    if (isValid) {
      setStepperData((prevData) => ({
        ...prevData,
        name: value,
      }));
      setName(value);
    }
  };

  const handleNumber = (e) => {
    setValidateNumber(false);
    setNumberError(false);
    const value = e.target.value;
    const isValid = AcceptOnlyNumber(value);
    if (isValid) {
      setStepperData((prevData) => ({
        ...prevData,
        phone: value,
      }));
      setPhone(value);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      setLoaderOpen(true);
      setDisableotpBtn(true);
      // // http://localhost:8080/apiv1/leads/sendotptolead?phoneNumber=9243025736&name=prasad.ganti
      const paramsData = { phoneNumber: phone, otp: otp };
      const apiUrl = `${process.env.REACT_APP_CRM_URL}/leads/validateotp`;
      const queryParams = new URLSearchParams(paramsData).toString();
      const LeadApiapiUrlWithParams = `${apiUrl}?${queryParams}`;
      const response = await axios.post(LeadApiapiUrlWithParams, {}, basicAuth);
      if (response?.data?.message == 'Invalid otp.') {
        setLoaderOpen(false);
        setInvalidOtp(true);
        setDisableotpBtn(false);
      } else {
        handleNext();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleChangeTime = (timeValue) => {
    setStepperData((prevData) => ({
      ...prevData,
      selectedTime: timeValue.target.value,
    }));
    setSelectedTime(timeValue.target.value);
  };

  const handleChange = (e) => {
    // debugger;
    const { name, value } = e.target;
    if (name == 'address') setAddress(value);
    setStepperData((prevData) => ({
      ...prevData,
      [name]: value.trim(),
    }));
  };

  const handleGetCarDetails = async () => {
    let carData = await getCarDetailsByCarNo(stepperData?.carRegNo, phone);
    if (carData?.data) {
      setStepperData((prevData) => ({
        ...prevData,
        carmake: carData?.data?.vehicleManufacturerName,
        carModel: carData?.data?.model,
        carType: carData?.data?.type,
        carColor: carData?.data?.vehicleColour,
        carManufacturingYear:
          carData?.data?.vehicleManufacturingMonthYear ?? '-',
        // carColor: response?.data?.result?.data?.vehicleColour,
      }));
    }
  };

  const handleCitySelect = (cityName) => {
    setStepperData((prevData) => ({
      ...prevData,
      city: cityName,
    }));
    setSelectedCity(cityName);
  };

  const handleScheduleInspection = async () => {
    console.log('stepper Data', stepperData);
    try {
      const body = {
        make: stepperData?.carmake,
        model: stepperData?.carModel,
        address: stepperData?.address, //
        ownermob: 'string',
        date: new Date(stepperData?.selectedDate).getTime(), //
        time: stepperData?.selectedTime, //
        customermobileno: stepperData?.phone,
        city: stepperData?.city, //
        regno: stepperData?.carno,
      };
      console.log('body body', body);
      const schedule = await createNewPendingInspection(body);
      if (schedule?.data?.message == 'created a pending inspection') {
        setbtnDisable(false);
        handleNext();
      }
    } catch (error) {}
  };

  useEffect(() => {
    setTotalAmount(packageData?.totalPrice);
  }, [packageData]);

  return (
    <>
      {loaderOpen && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            background: 'rgba(255, 255, 255, 0.8)' /* Light overlay */,
            zIndex: '999',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {/* <div className="buynow-container"> */}
      <div className="stepper-container-buyNow">
        <CustomizedSteppers activeStep={activeStep} />
      </div>
      <div
        style={{
          paddingTop: '20px',
          display: 'flex',
          width: '50%',
          gap: '10px',
        }}
      >
        <Divider orientation="vertical" />
        <div>
          <ArrowCircleLeftIcon onClick={() => handleBack()} />
        </div>
        <div className="buyNow-content">
          <Paper
            sx={{
              width: '100%',
              backgroundColor: '#white',
              boxShadow: 'none !important',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {transactionStatus ? (
              <div
                className={
                  transactionStatus?.toLocaleLowerCase() === 'success'
                    ? 'buynow-payment-container-success'
                    : transactionStatus === 'failure'
                    ? 'buynow-payment-container-failure'
                    : 'buynow-payment-container'
                }
              >
                {transactionStatus?.toLocaleLowerCase() === 'success' ? (
                  <div className="buy-now-payment-table-content">
                    <div className="transaction-success-header-div">
                      <CheckCircleIcon
                        sx={{ color: 'green', width: '50px', height: '50px' }}
                      />
                      <p>paid</p>
                      <h5>Rs {amountReceived}</h5>
                      <p>To Wisedrive Technologies Pvt Ltd</p>
                    </div>
                    <Divider
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        borderColor: 'green',
                      }}
                    />
                    <div>
                      <p style={{ textAlign: 'center' }}>Transaction Details</p>
                      <p>Transaction No: {transactionID}</p>
                      <p>Ref No: {bankReferenceNumber}</p>
                      <p>Inspection Type: {planName}</p>
                      <p>No. of Inspections: {inspectionCountRes}</p>
                    </div>
                    <div className="transaction-communication">
                      <EmailIcon sx={{ color: '#ae1144' }} />
                      <p
                        style={{
                          paddingTop: '0px',
                          color: '#ae1144',
                          lineHeight: '16px',
                        }}
                      >
                        Payment Receipt has been sent to the{' '}
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterBold',
                            color: '#ae1144',
                          }}
                        >
                          customer@email.com
                        </span>
                      </p>
                    </div>
                    <Divider
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        borderColor: 'green',
                      }}
                    />
                    <p style={{ textAlign: 'center' }}>
                      Book Inspection / Contact us
                    </p>
                    <div className="transaction-communication">
                      <WhatsAppIcon
                        sx={{ color: 'green', height: '30px', width: '30px' }}
                      />
                      <p style={{ lineHeight: '16px' }}>
                        Say hi on{' '}
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterBold',
                          }}
                        >
                          Whatsapp at 73380 73380{' '}
                        </span>{' '}
                        for any queries from 9am - 7pm Mon - Sat.
                      </p>
                    </div>
                    <div className="transaction-communication">
                      <SupportAgentRoundedIcon
                        sx={{ height: '30px', width: '30px' }}
                      />
                      <p style={{ lineHeight: '16px' }}>
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterBold',
                          }}
                        >
                          Call us at 7353 34 35 36{' '}
                        </span>{' '}
                        to Book inspection with our agent
                      </p>
                    </div>
                  </div>
                ) : transactionStatus?.toLocaleLowerCase() === 'failure' ? (
                  <div className="buy-now-payment-table-content">
                    <div className="transaction-success-header-div">
                      <CancelRoundedIcon
                        sx={{
                          color: '#aa1945',
                          width: '50px',
                          height: '50px',
                        }}
                      />
                      <p>Payment Failed</p>
                      <h5>Rs {amountReceived}</h5>
                      <p>To Wisedrive Technologies Pvt Ltd</p>
                    </div>
                    <Divider
                      style={{
                        marginTop: '0.5rem',
                        marginBottom: '0.5rem',
                        borderColor: '#aa1945',
                      }}
                    />
                    <div>
                      <p style={{ textAlign: 'center' }}>Transaction Details</p>
                      <p>Txn No:{transactionID}</p>
                    </div>
                    <Button
                      variant="outlined"
                      sx={{
                        height: '50px',
                        width: '100%',
                        backgroundColor: '#DCEEFF',
                        fontFamily: 'matterMedium',
                        fontSize: '18px',
                        boxShadow: 'none',
                        color: '#0C286A',
                        '&:hover': {
                          backgroundColor: '#DCEEFF',
                          boxShadow: 'none',
                        },
                      }}
                      onClick={() => handleRetryPayment()}
                    >
                      Retry Payment
                    </Button>
                    <Divider
                      style={{
                        marginTop: '1.5rem',
                        marginBottom: '0.5rem',
                        borderColor: '#aa1945',
                      }}
                    />
                    <p style={{ textAlign: 'center' }}>
                      Book Inspection / Contact us
                    </p>
                    <div className="transaction-communication">
                      <WhatsAppIcon
                        sx={{ color: 'green', height: '30px', width: '30px' }}
                      />
                      <p style={{ lineHeight: '16px' }}>
                        Say hi on{' '}
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterBold',
                          }}
                        >
                          Whatsapp at 73380 73380{' '}
                        </span>{' '}
                        for any queries from 9am - 7pm Mon - Sat.
                      </p>
                    </div>
                    <div className="transaction-communication">
                      <SupportAgentRoundedIcon
                        sx={{ height: '30px', width: '30px' }}
                      />
                      <p style={{ lineHeight: '16px' }}>
                        <span
                          style={{
                            fontSize: '16px',
                            fontFamily: 'matterBold',
                          }}
                        >
                          Call us at 7353 34 35 36{' '}
                        </span>{' '}
                        to Book inspection with our agent
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <>
                {activeStep === 0 && (
                  <>
                    <div className="buyNow-header">
                      <img
                        src={CarInspection}
                        width="50px"
                        alt="inspection"
                      ></img>
                      <h6>
                        Unlocking Standard
                        <br /> Car Inspection
                      </h6>
                    </div>
                    <div className="buyNow-paper">
                      <div>
                        <div
                          style={{
                            marginTop: '20px',
                            marginBottom: '10px',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                          }}
                        >
                          {/* <Typography>Name</Typography> */}
                          <StyledTextField
                            id="standard-basic"
                            variant="outlined"
                            value={name}
                            onChange={handleName}
                            label="Name"
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                              '&:hover': {
                                borderColor: 'black !important',
                              },
                            }}
                            inputProps={{
                              sx: {
                                color: 'black',
                                // height: '10px !important',
                                fontFamily: 'matter !important',
                              },
                            }}
                          />
                          {validateName && (
                            <p style={{ color: 'red' }}>Please enter name</p>
                          )}
                        </div>
                        <div
                          style={{
                            width: '100%',
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                          }}
                        >
                          <StyledTextField
                            id="standard-basic"
                            variant="outlined"
                            label="Mobile Number"
                            value={phone}
                            onChange={handleNumber}
                            endAdornment={<button>Test</button>}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                            InputProps={{
                              sx: {
                                color: 'black',
                                // height: '60px !important',
                                fontFamily: 'matter !important',
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    sx={{
                                      // marginTop: '15px',
                                      width: '100%',
                                      backgroundColor: '#ff7858',
                                      borderRadius: 1,
                                      color: 'white',
                                      height: '30px',
                                      '&:hover': {
                                        backgroundColor: '#ff7858 !important',
                                        color: 'white',
                                      },
                                    }}
                                    disabled={!name || !phone || disabelOtpBtn}
                                    onClick={() => handleSendOtp()}
                                  >
                                    {otpSent ? 'Resend Otp' : 'Send Otp'}
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {validateNumber && (
                            <p style={{ color: 'red' }}>Please enter number</p>
                          )}
                          {numberError && (
                            <p style={{ color: 'red' }}>
                              Number is less than 10 digit
                            </p>
                          )}
                          {otpSent && (
                            <p
                              style={{
                                fontFamily: 'matterThin',
                                fontSize: '14px',
                              }}
                            >
                              OTP sent via SMS to your mobile number
                            </p>
                          )}
                        </div>
                        <div className="buyNow-otp">
                          <div style={{ marginTop: '2.5rem' }}>
                            <p>Enter OTP</p>
                          </div>
                          <div
                            className="otp-input-container"
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <OtpInput
                              className="otp-input"
                              value={otp}
                              onChange={(e) => {
                                setOtp(e);
                              }}
                              numInputs={6}
                              // renderSeparator={<span>-</span>}
                              renderInput={(props) => <input {...props} />}
                            />
                          </div>
                          {invalidOtp && (
                            <p
                              style={{
                                fontFamily: 'matterThin',
                                fontSize: '14px',
                              }}
                            >
                              Please Enter Valid OTP
                            </p>
                          )}
                        </div>
                        <Button
                          style={{ marginTop: '1rem' }}
                          onClick={() => handleVerifyOtp()}
                          sx={{
                            width: '100%',
                            height: '55px',
                            fontSize: '20px',
                            backgroundColor: '#003cc4',
                            color: 'white',
                            borderRadius: 1,
                            fontFamily: 'matterSemiBold',
                            '&:hover': {
                              backgroundColor: '#003cc4 !important',
                              color: 'white',
                            },
                          }}
                          disabled={otp?.length !== 6}
                        >
                          Verify OTP
                        </Button>
                        <p
                          style={{
                            paddingTop: '5px',
                            fontFamily: 'matterThin',
                            fontSize: '12px',
                            textAlign: 'center',
                          }}
                        >
                          By contacting us, you agree to our{' '}
                          <strong>
                            Terms <br />
                            of service
                          </strong>{' '}
                          and <strong>Privacy Policy</strong>
                        </p>
                      </div>
                    </div>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <div className="buyNow-header">
                      <h6>Enter Car Registration Number</h6>
                    </div>
                    <div className="buyNow-paper">
                      <div>
                        <div
                          style={{
                            width: '100%',
                            marginTop: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            flexDirection: 'column',
                          }}
                        >
                          <StyledTextField
                            id="standard-basic"
                            variant="outlined"
                            // label="Mobile Number"
                            placeholder="KAXXNXXXX"
                            name="carRegNo"
                            value={stepperData?.carRegNo}
                            onChange={handleChange}
                            endAdornment={<button>Test</button>}
                            style={{ textTransform: 'uppercase !important' }}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                            InputProps={{
                              sx: {
                                color: 'black',
                                // height: '60px !important',
                                fontFamily: 'matter !important',
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Button
                                    sx={{
                                      // marginTop: '15px',
                                      width: '100%',
                                      backgroundColor: '#ff7858',
                                      borderRadius: 1,
                                      color: 'white',
                                      height: '30px',
                                      '&:hover': {
                                        backgroundColor: '#ff7858 !important',
                                        color: 'white',
                                      },
                                    }}
                                    disabled={!stepperData?.carRegNo}
                                    onClick={() => handleGetCarDetails()}
                                  >
                                    SEARCH
                                  </Button>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '30px',
                            display: 'flex',
                            gap: '20px',
                          }}
                        >
                          <StyledTextFieldDisabled
                            id="standard-basic"
                            variant="outlined"
                            label="Make"
                            defaultValue=" "
                            name="carmake"
                            value={stepperData?.carmake}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                          />
                          <StyledTextFieldDisabled
                            id="standard-basic"
                            variant="outlined"
                            label="Model"
                            defaultValue=" "
                            name="carModel"
                            value={stepperData?.carModel}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            gap: '20px',
                          }}
                        >
                          <StyledTextFieldDisabled
                            id="standard-basic"
                            variant="outlined"
                            label="Manufacturing Year"
                            defaultValue=" "
                            name="carManufacturingYear"
                            value={stepperData?.carManufacturingYear}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                          />
                          <StyledTextFieldDisabled
                            id="standard-basic"
                            variant="outlined"
                            label="Type"
                            defaultValue=" "
                            name="carType"
                            value={stepperData?.carType}
                            sx={{
                              width: '100% !important',
                              borderRadius: '10px',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: '20px',
                            display: 'flex',
                            gap: '20px',
                          }}
                        >
                          {' '}
                          <StyledTextFieldDisabled
                            id="standard-basic"
                            variant="outlined"
                            label="Color"
                            defaultValue=" "
                            name="carColor"
                            value={stepperData?.carColor}
                            sx={{
                              width: '50% !important',
                              borderRadius: '10px',
                            }}
                          />
                        </div>
                        <Button
                          style={{ marginTop: '1rem' }}
                          onClick={() => handleNext()}
                          sx={{
                            width: '100%',
                            height: '55px',
                            fontSize: '20px',
                            backgroundColor: '#003cc4',
                            color: 'white',
                            borderRadius: 1,
                            fontFamily: 'matterSemiBold',
                            '&:hover': {
                              backgroundColor: '#003cc4 !important',
                              color: 'white',
                            },
                          }}
                          disabled={!stepperData?.carmake}
                        >
                          Yes, Confirm Car
                        </Button>
                        <Button
                          style={{ marginTop: '1rem' }}
                          onClick={() => handleSkip()}
                          sx={{
                            width: '100%',
                            height: '55px',
                            fontSize: '20px',
                            backgroundColor: '#003cc4',
                            color: 'white',
                            borderRadius: 1,
                            fontFamily: 'matterSemiBold',
                            '&:hover': {
                              backgroundColor: '#003cc4 !important',
                              color: 'white',
                            },
                          }}
                        >
                          Skip To Payment
                        </Button>
                      </div>
                    </div>
                  </>
                )}
                {activeStep == 2 && (
                  <>
                    <div className="city-container">
                      {cityIconArray?.map((item, index) => (
                        <div
                          key={index}
                          name={'city'}
                          onClick={() => handleCitySelect(item?.name)}
                          style={{
                            border:
                              selectedCity == item?.name && '2px solid #003cc5',
                            padding: '5px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: '100px',
                          }}
                        >
                          <img src={item?.icon} />
                          <p
                            style={{
                              fontFamily: 'matterMedium',
                              fontSize: '16px',
                            }}
                          >
                            {item?.name}
                          </p>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        gap: '20px',
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Select Date"
                          value={selectedDate}
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                            setStepperData((prevData) => ({
                              ...prevData,
                              selectedDate: newValue, // Use dynamic field names like 'name' and 'phone'
                            }));
                          }}
                          disablePast
                          inputFormat="dd-M-yyyy" // Updated input format
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={params?.inputProps?.placeholder}
                            />
                          )}
                        />
                        <FormControl sx={{ minWidth: 150 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            Select Time
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedTime}
                            label="Select Time"
                            onChange={handleChangeTime}
                          >
                            <MenuItem value="">-- select Time --</MenuItem>
                            {scheduleTimeArray?.map((time) => (
                              <MenuItem value={time?.id}>
                                {time?.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <StyledTextField
                          id="standard-basic"
                          variant="outlined"
                          label="City"
                          defaultValue=" "
                          name="city"
                          value={stepperData?.city}
                          sx={{
                            width: '50% !important',
                            borderRadius: '10px',
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '30px',
                        }}
                      >
                        {/* <label>Enter Inspection Address</label> */}
                        <TextField
                          id="outlined-multiline-static"
                          multiline
                          rows={2}
                          name="address"
                          value={address}
                          label="Enter Inspection Address"
                          defaultValue=" "
                          autoComplete="off"
                          placeholder="Enter Inspection Address"
                          onChange={handleChange}
                        />
                      </div>
                      <Button
                        style={{ marginTop: '1rem' }}
                        onClick={() => handleScheduleInspection()}
                        sx={{
                          width: '100%',
                          height: '55px',
                          fontSize: '20px',
                          backgroundColor: '#003cc4',
                          color: 'white',
                          borderRadius: 1,
                          fontFamily: 'matterSemiBold',
                          '&:hover': {
                            backgroundColor: '#003cc4 !important',
                            color: 'white',
                          },
                        }}
                        disabled={
                          !(
                            selectedCity &&
                            selectedTime &&
                            stepperData?.address
                          ) || btnDisable
                        }
                      >
                        Confirm Inspection
                      </Button>
                    </div>
                  </>
                )}
                {activeStep === 3 && (
                  <div>
                    <div
                      className="buy-now-payment-table-content"
                      style={{ width: '90%' }}
                    >
                      <div className="div-invoice" style={{ marginTop: '0px' }}>
                        <p style={{ fontSize: '22px' }}>Summary</p>
                        <p style={{ fontFamily: 'matter', color: 'grey' }}>
                          {selectedValue == 'Standard'
                            ? 'Standard Plan'
                            : 'Luxury Plan'}
                        </p>
                      </div>
                      <div
                        className="div-invoice"
                        style={{ marginTop: '0.5rem' }}
                      >
                        <p>
                          Package Amount <br />
                          {`(${
                            packageData?.inspectionCount
                          } Inspection x ₹ ${Math.round(
                            packageData?.costPrice /
                              packageData?.inspectionCount
                          )})`}
                        </p>
                        <p style={{ fontSize: '18px' }}>
                          {packageData?.costPrice}
                        </p>
                      </div>
                      <div className="div-promocode">
                        <TextField
                          id="standard-basic"
                          variant="outlined"
                          label="Coupon Code"
                          fullWidth
                          onChange={(event) => {
                            setCouponCode(event.target.value);
                          }}
                          value={couponCode}
                          style={{ height: '40px !important' }}
                          sx={{
                            height: '40px !important',
                            alignItems: 'baseline',
                            borderBottomColor: 'white',
                            textTransform: 'capitalize !important',
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'white',
                            },
                            '& .MuiInput-underline': {
                              borderBottomColor: 'white',
                            },
                            '&.MuiInputBase-root-MuiOutlinedInput-root': {
                              fontFamily: 'matter',
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link
                                  onClick={() => applyDiscount(couponCode)}
                                  style={{
                                    // marginBottom: "20px",
                                    color: '#eb6d47',
                                    textDecoration: 'none',
                                    fontFamily: 'matterBold',
                                  }}
                                >
                                  {isDiscountApplied ? 'Change' : 'Apply'}
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          className="txt-promoCoe"
                        />
                      </div>
                      <div>
                        {couponLabel && (
                          <p style={{ color: 'red' }}>{couponLabel}</p>
                        )}
                      </div>

                      <div
                        className="div-invoice"
                        style={{ paddingTop: '10px' }}
                      >
                        <p>Discount Applied </p>
                        <p style={{ color: discountAmount > 0 && 'red' }}>
                          {discountAmount > 0 && '-'}₹ {discountAmount}
                        </p>
                      </div>
                      <div className="div-invoice">
                        <p>Tax ({packageData?.taxPercent}% GST) </p>
                        <p>
                          ₹
                          {/* {Math.round(
                        ((packageData?.costPrice - discountAmount) *
                          packageData?.taxPercent) /
                          100
                      )} */}
                          {((packageData?.costPrice - discountAmount) *
                            packageData?.taxPercent) /
                            100}
                        </p>
                      </div>
                      <div className="div-invoice seperator desktop">
                        <>&nbsp;</>
                      </div>
                      <div
                        className="div-invoice"
                        style={{ paddingTop: '10px' }}
                      >
                        <h4>Total Amount (INR)</h4>
                        <p
                          style={{
                            fontSize: '25px',
                            fontFamily: 'matterSemiBold',
                          }}
                        >
                          ₹ {totalAmount}
                        </p>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <PaymentForm
                          userData={{
                            userName: name,
                            nameError: nameError,
                            couponCode: couponCode,
                            Mobile: phone,
                            numberError: numberError,
                          }}
                          isButtondisabled={isButtondisabled}
                          setPageDisable={setPageDisable}
                          packageDetails={{
                            amount: totalAmount,
                            productinfo: 'Inspection',
                            phone: phone,
                            firstName: name,
                            packageInstanceId: packageData?.packageInstanceId,
                            couponCode: couponCode,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: '1.5rem',
                          justifyContent: 'space-around',
                        }}
                      >
                        <p style={{ textAlign: 'center', lineHeight: '20px' }}>
                          By Proceeding, I understand and agree with the{' '}
                          <span style={{ fontFamily: 'matterSemiBold' }}>
                            Terms of Service
                          </span>{' '}
                          and{' '}
                          <span style={{ fontFamily: 'matterSemiBold' }}>
                            Privacy Policy{' '}
                          </span>
                          of Wisedrive Technologies Pvt Ltd
                        </p>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            marginTop: '1.5rem',
                            marginBottom: '1.5rem',
                          }}
                        >
                          <p>Payments secured by</p>
                          <img
                            src={payuIcon}
                            width="80px"
                            height="40px"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Paper>
        </div>
        <BuyNowModal
          open={invalidOtp}
          handleClose={() => setInvalidOtp(false)}
          handleApprove={() => setInvalidOtp(false)}
          message={'Please enter Valid OTP'}
        />
      </div>
      {/* </div> */}
    </>
  );
};

export default BuyNowComponent;
