// import { useEffect, useState } from "react";
// import { Button, Box, TextField, MenuItem, Grid, Typography } from "@mui/material";
// import { isEqual } from "lodash";

// const EditKeyAndAdditional = ({ onClose, keyInfo, updateParentState }) => {
//   // Initial data for both Key Information and Additional Information
//   const [fields, setFields] = useState([]);

//   useEffect(() => {
//     // Load initial fields based on keyInfo prop
//     console.log("keyInfo received in EditKeyAndAdditional:", keyInfo);
//     const newFields = [
//       { label: "Kms Run", value: keyInfo.kmsDriven || "", type: "text" },
//         { label: "Major Accident", value: keyInfo.majorAccident || "NO", type: "boolean" },
//         { label: "Flood Damage", value: keyInfo.floodDamage || "NO", type: "boolean" },
//         { label: "Engine Condition", value: keyInfo.engineCondition || "AVERAGE", type: "average" },
//         { label: "Transmission", value: keyInfo.transmission || "AVERAGE", type: "average" },
//         { label: "Exterior Condition", value: keyInfo.exteriorCondition || "AVERAGE", type: "average" },
//         { label: "Interior Condition", value: keyInfo.interiorCondition || "AVERAGE", type: "average" },
//     ]
//     if (!isEqual(newFields, fields)) {
//       console.log("Updating fields with new keyInfo");
//       setFields(newFields);
//     } else {
//       console.log("No change in fields");
//     }
//   }, [keyInfo]);

//   // Update field value
//   const handleFieldChange = (index, newValue) => {
//     const updatedFields = fields.map((field, idx) => {
//       if (idx === index) {
//         return { ...field, value: newValue };
//       }
//       return field;
//     });
//     setFields(updatedFields);
//   };

//   const handleSave = async() => {
//     // Construct updated key info object
//     const updatedKeyInfo = fields.reduce((acc, field) => {
//       acc[field.label.replace(/ /g, "").toLowerCase()] = field.value;
//       return acc;
//     }, {});
// console.log(updatedKeyInfo, 'uododod')
//     // Update parent state using the provided function
//     await updateParentState(updatedKeyInfo);
//     onClose();
//   };
  

//   return (
//     <Box display="flex" flexDirection="column" gap="16px">
//       <Grid container spacing={2}>
//         {fields.map((field, index) => (
//           <Grid item xs={12} sm={6} key={index}>
//             <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "8px" }}>
//               {field.label}
//             </Typography>
//             {field.type === "boolean" ? (
//               <TextField
//                 select
//                 value={field.value}
//                 onChange={(e) => handleFieldChange(index, e.target.value)}
//                 fullWidth
//               >
//                 <MenuItem value="YES">Yes</MenuItem>
//                 <MenuItem value="NO">No</MenuItem>
//               </TextField>
//             ) : field.type === "average" ? (
//               <TextField
//                 select
//                 value={field.value}
//                 onChange={(e) => handleFieldChange(index, e.target.value)}
//                 fullWidth
//               >
//                 <MenuItem value="BAD">Bad</MenuItem>
//                 <MenuItem value="AVERAGE">Average</MenuItem>
//                 <MenuItem value="GOOD">Good</MenuItem>
//                 <MenuItem value="EXCELLENT">Excellent</MenuItem>
//               </TextField>
//             ) : (
//               <TextField
//                 value={field.value}
//                 onChange={(e) => handleFieldChange(index, e.target.value)}
//                 fullWidth
//               />
//             )}
//           </Grid>
//         ))}
//       </Grid>

//       {/* Actions */}
//       <Box display="flex" justifyContent="flex-end" gap="8px" marginTop="16px">
//         <Button onClick={onClose}>Cancel</Button>
//         <Button variant="contained" color="primary" onClick={handleSave}>
//           Save
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default EditKeyAndAdditional;

import { useEffect, useState } from "react";
import { Button, Box, TextField, MenuItem, Grid, Typography, Dialog, DialogTitle, DialogContent, Tooltip, IconButton } from "@mui/material";
import { Info } from "@mui/icons-material";

const EditKeyAndAdditional = ({ onClose, keyInfo, updateParentState }) => {
  const [kmsRun, setKmsRun] = useState("");
  const [majorAccident, setMajorAccident] = useState("NO");
  const [floodDamage, setFloodDamage] = useState("NO");
  const [engineCondition, setEngineCondition] = useState("AVERAGE");
  const [transmission, setTransmission] = useState("AVERAGE");
  const [exteriorCondition, setExteriorCondition] = useState("AVERAGE");
  const [interiorCondition, setInteriorCondition] = useState("AVERAGE");
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  useEffect(() => {
    if (initialLoad) {
      setKmsRun(keyInfo?.kmsDriven || "");
      setMajorAccident(keyInfo?.majorAccident || "NO");
      setFloodDamage(keyInfo?.floodDamage || "NO");
      setEngineCondition(keyInfo?.engineCondition || "AVERAGE");
      setTransmission(keyInfo?.transmission || "AVERAGE");
      setExteriorCondition(keyInfo?.exteriorCondition || "AVERAGE");
      setInteriorCondition(keyInfo?.interiorCondition || "AVERAGE");
      setInitialLoad(false);
    }
  }, [keyInfo, initialLoad]);

  const handleKmsRunChange = (value) => {
    if (value === "" || (Number(value) >= 0 && Number(value) <= 999999)) {
      setKmsRun(value);
      setError(false); // Clear error if valid
    } else {
      setError(true); // Set error if invalid
    }
  };

  const handleSave = async () => {
    if (error || kmsRun === "" || kmsRun < 0 || kmsRun > 999999) {
      alert("Please ensure 'Kms Run' is within the range 0-999999.");
      return;
    }

    // Construct updated key info object
    const updatedKeyInfo = {
      kmsDriven: kmsRun,
      majorAccident: majorAccident,
      floodDamage: floodDamage,
      engineCondition: engineCondition,
      transmission: transmission,
      exteriorCondition: exteriorCondition,
      interiorCondition: interiorCondition
    };
    await updateParentState(updatedKeyInfo);
    onClose();
  };

  const handleInfoDialogOpen = () => {
    if (majorAccident === "NO") {
      setInfoDialogOpen(true);
    }
  };
  const handleInfoDialogClose = () => setInfoDialogOpen(false);

  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "8px" }}>
            Kms Run
          </Typography>
          <TextField
            type="number"
            inputProps={{ min: 0, max: 999999 }}
            value={kmsRun}
            onChange={(e) => handleKmsRunChange(e.target.value)}
            fullWidth
            error={error}
            helperText={error ? "Value must be between 0 and 999999." : ""}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "8px", display: "flex", alignItems: "center" }}>
            Major Accident{"  "}
            {majorAccident === 'NO' && <Tooltip title="Click for more information" onClick={handleInfoDialogOpen}>
                <span style={{
        color: 'red',
        cursor: 'pointer',
        marginLeft: '4px' // Adjust spacing if necessary
      }}>{"  "}*</span>
            </Tooltip>}
          </Typography>
          <TextField
            select
            value={majorAccident}
            onChange={(e) => setMajorAccident(e.target.value)}
            fullWidth
          >
            <MenuItem value="YES">Yes</MenuItem>
            <MenuItem value="NO">No</MenuItem>
          </TextField>
        </Grid>
        {[
          { label: "Flood Damage", value: floodDamage, setValue: setFloodDamage, type: "boolean" },
          { label: "Engine Condition", value: engineCondition, setValue: setEngineCondition, type: "select" },
          { label: "Transmission", value: transmission, setValue: setTransmission, type: "select" },
          { label: "Exterior Condition", value: exteriorCondition, setValue: setExteriorCondition, type: "select" },
          { label: "Interior Condition", value: interiorCondition, setValue: setInteriorCondition, type: "select" }
        ].map((field, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: "8px" }}>{field.label}</Typography>
            {field.type === "boolean" ? (
              <TextField
                select
                value={field.value}
                onChange={(e) => field.setValue(e.target.value)}
                fullWidth
              >
                <MenuItem value="YES">Yes</MenuItem>
                <MenuItem value="NO">No</MenuItem>
              </TextField>
            ) : field.type === "select" ? (
              <TextField
                select
                value={field.value}
                onChange={(e) => field.setValue(e.target.value)}
                fullWidth
              >
                <MenuItem value="BAD">Bad</MenuItem>
                <MenuItem value="AVERAGE">Average</MenuItem>
                <MenuItem value="GOOD">Good</MenuItem>
                <MenuItem value="EXCELLENT">Excellent</MenuItem>
              </TextField>
            ) : (
              <TextField
                value={field.value}
                onChange={(e) => field.setValue(e.target.value)}
                fullWidth
              />
            )}
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="flex-end" gap="8px" marginTop="16px">
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
      </Box>
      <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose} fullWidth maxWidth="xs">
        <DialogTitle>Major Accident Information</DialogTitle>
        <DialogContent>
          Based on physical inspection, the mechanic didn’t find any evidence of an accident. For further information, please contact the authorized service center or insurance provider.
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default EditKeyAndAdditional;
