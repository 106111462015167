export const IndiaContent = {
  heroPage: {
    header: 'Our Car Inspection Gaurantees Good used Car at a Fair Price',
    subPoints: [
      'Used Car Inspection',
      'Pre Delivery Service',
      'Extended Warranty upto 2 Years',
    ],
    btnText: 'Book Inspection',
  },
};
